<template>
  <div>
    <top-header v-bind:title="$t('souvenirPhotos')" />
    <v-container>
      <v-row v-if="filteredPhotos.length === 0">
        <v-col>
          <p class="text-center grey--text text--darken-1">Leider hatten wir keine Gelegenheit Erinnerungsfotos von Ihrem Messeauftritt zu erstellen. Vielleicht beim nächsten Mal!</p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-card>
            <v-img v-bind:aspect-ratio="4/3" v-bind:src="`/images/temp/${selectedPhoto.imageName}.jpg`" v-bind:lazy-src="`/images/temp/small/${selectedPhoto.imageName}.jpg`" />
          </v-card>
          <v-btn color="accent" class="mt-2"
                   depressed v-bind:href="`/images/temp/${selectedPhoto.imageName}.jpg`" download>
                <v-icon left>
                  mdi-download
                </v-icon>
                {{ $t('download') }}
              </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="2" v-for="(photo, index) in filteredPhotos" v-bind:key="`thumbnail-${index}`">
          <v-card v-on:click="selectedPhotoIndex = index">
            <v-img v-bind:aspect-ratio="4/3" v-bind:src="`/images/temp/small/${photo.imageName}.jpg`" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import imageMapping from '@/temp-image-mapping.json'

  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    computed: {
      filteredPhotos() {
        return imageMapping.filter(photo => photo.supplierId == this.supplierId)
      },
      selectedPhoto() {
        return this.filteredPhotos[this.selectedPhotoIndex]
      }
    },
    created() {
      this.supplierId = this.$route.params.supplierId
    },
    data: () => ({
      supplierId: null,
      selectedPhotoIndex: 0
    }),
    name: 'SouvenirPhotos'
  }
</script>

<i18n>
  {
    "de": {
      "download": "Herunterladen",
      "souvenirPhotos": "Erinnerungsfotos"
    },
    "en": {
      "download": "Download",
      "souvenirPhotos": "Souvenir photos"
    }
  }
</i18n>