<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('customers') }}</v-toolbar-title>
        <v-text-field class="mx-4"
                      hide-details
                      flat
                      light
                      prepend-inner-icon="mdi-magnify"
                      rounded
                      solo
                      v-model="search" />
      </v-toolbar>
      <v-data-table v-bind:custom-filter="filter"
                    v-bind:headers="headers"
                    v-bind:items="customers"
                    v-bind:items-per-page="15"
                    v-bind:no-data-text="$t('thereAreCurrentlyNoOrders')"
                    v-bind:no-results-text="$t('noOrdersWereFound')"
                    v-bind:search="search"
                    v-bind:sort-by="['companyName']">
        <template v-slot:item.actions="{ item }">
          <v-btn color="accent"
                 icon
                 small
                 v-bind:to="{ name: 'administration-customer', params: { id: item.id } }">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.orderCount="{ item }">
          {{ item.orderCount }}/{{ item.maxOrder }} <strong>({{ percent(item.orderCount, item.maxOrder) }})</strong>
        </template>
        <template v-slot:item.orderCount2="{ item }">
          {{ item.orderCount2 }}/{{ item.maxOrder }} <strong>({{ percent(item.orderCount2, item.maxOrder) }})</strong>
        </template>
        <template v-slot:item.newOrderCount="{ item }">
          {{ item.newOrderCount }}/{{ item.newMaxOrder }} <strong>({{ percent(item.newOrderCount, item.newMaxOrder) }})</strong>
        </template>
        <template v-slot:item.newOrderCount2="{ item }">
          {{ item.newOrderCount2 }}/{{ item.newMaxOrder }} <strong>({{ percent(item.newOrderCount2, item.newMaxOrder) }})</strong>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      search: null
    }),
    computed: {
      headers() {
        return [
          {
            text: this.$t('customerNumber'),
            value: 'id'
          },
          {
            text: this.$t('companyName'),
            value: 'companyName'
          },
          {
            text: this.$t('all'),
            value: 'orderCount'
          },
          {
            text: this.$t('all2'),
            value: 'orderCount2'
          },
          {
            text: this.$t('new'),
            value: 'newOrderCount'
          },
          {
            text: this.$t('new2'),
            value: 'newOrderCount2'
          },
          {
            sortable: false,
            text: '',
            value: 'actions'
          }
        ]
      }
    },
    methods: {
      filter(value, search, item) {
        if (value == null) {
          return null
        }

        if (search == null) {
          return null
        }

        return value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
      },
      percent(total, count) {
        if (total == null || total === 0) {
          return '-'
        }

        return Math.floor(total / count * 100) + ' %'
      }
    },
    name: 'CustomerAnalyticsCard',
    props: {
      customers: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "all": "Alle Z1",
      "all2": "Alle Z2",
      "companyName": "Firmenname",
      "customerNumber": "Kundennr.",
      "customers": "Kunden",
      "new": "Neu Z1",
      "new2": "Neu Z2",
      "noOrdersWereFound": "Es wurden keine Bestellungen gefunden",
      "thereAreCurrentlyNoOrders": "Es sind noch keine Bestellungen vorhanden"
    },
    "en": {
      "all": "All T1",
      "all2": "All T2",
      "companyName": "Company name",
      "customerNumber": "Customer number",
      "customers": "Customers",
      "new": "New T1",
      "new2": "New T2",
      "noOrdersWereFound": "No orders were found",
      "thereAreCurrentlyNoOrders": "There are currently no orders"
    }
  }
</i18n>