<template>
  <div>
    <top-header v-bind:title="$t('information')">
      <v-btn dark
             exact
             slot="left-button"
             text
             v-bind:to="{ name: 'administration-dashboard' }">
        <v-icon left>mdi-chevron-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn dark
             exact
             slot="right-button"
             text
             v-on:click="edit = true"
             v-if="!edit">
        {{ $t('edit') }}
        <v-icon right>mdi-pencil</v-icon>
      </v-btn>
      <v-btn dark
             exact
             slot="right-button"
             text
             v-on:click="update"
             v-else>
        {{ $t('save') }}
        <v-icon right>mdi-check</v-icon>
      </v-btn>
    </top-header>
    <v-container>
      <p v-if="!edit && textSnippet != null" v-html="text"></p>
      <div v-else-if="textSnippet != null">
        <v-card rounded>
          <v-textarea flat
                    hide-details="auto"
                    rows="10"
                    solo
                    v-bind:label="$t('text')"
                    v-model="textSnippet.text" />
        </v-card>

      </div>
    </v-container>
  </div>
</template>

<script>
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    computed: {
      text() {
        if (this.textSnippet == null) {
          return ''
        }

        if (this.textSnippet.text == null) {
          return ''
        }

        return this.textSnippet.text.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }
    },
    async created() {
      await this.$store.dispatch('loadInfoTextSnippet')

      this.textSnippet = Object.assign({}, this.$store.getters.infoTextSnippet)
    },
    data: () => ({
      textSnippet: null,
      edit: false
    }),
    name: 'Info',
    methods: {
      async update() {
        await this.$store.dispatch('updateInfoTextSnippet', { textSnippet: this.textSnippet})

        this.edit = false
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "information": "Informationen",
      "edit": "Bearbeiten",
      "save": "Speichern",
      "text": "Bitte geben Sie einen Text ein…",
      "back": "Zurück"
    },
    "en": {
      "information": "Information",
      "edit": "Edit",
      "save": "Save",
      "text": "Please enter some text",
      "back": "Back"
    }
  }
</i18n>