<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('additionalGuests') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <h1 class="mb-2 text-subtitle-1">{{ $t('firstGuest') }}</h1>
          <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-model="customer.firstGuestGivenName" />
          <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-model="customer.firstGuestFamilyName" />
          <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-model="customer.firstGuestEmail" />
          <h1 class="mb-2 text-subtitle-1">{{ $t('secondGuest') }}</h1>
          <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-model="customer.secondGuestGivenName" />
          <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-model="customer.secondGuestFamilyName" />
          <v-text-field
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-model="customer.secondGuestEmail" />

          <div v-for="i in 8" v-bind:key="i">
            <h1 class="mb-2 mt-4 text-subtitle-1">{{ $t('guest') }} {{ i + 2 }}</h1>
            <v-text-field class="mb-4"

                        hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-model="customer['guestGivenName' + (i + 2)]" />
            <v-text-field class="mb-4"

                          hide-details="auto"
                          outlined
                          v-bind:label="$t('familyName')"
                          v-model="customer['guestFamilyName' + (i + 2)]" />
            <v-text-field
                          hide-details="auto"
                          outlined
                          v-bind:label="$t('email')"
                          v-model="customer['guestEmail' + (i + 2)]" />
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CustomerAdditionalGuestsEditCard',
    props: {
      customer: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "additionalGuests": "Weitere Gäste",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "firstGuest": "Erster Gast",
      "givenName": "Vorname",
      "secondGuest": "Zweiter Gast",
      "guest": "Gast"
    },
    "en": {
      "additionalGuests": "Additional guests",
      "email": "Email",
      "familyName": "Family name",
      "firstGuest": "First guest",
      "givenName": "Given name",
      "secondGuest": "Second guest",
      "guest": "Guest"
    }
  }
</i18n>