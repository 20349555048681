var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('orders')))]),_c('v-text-field',{staticClass:"mx-4",attrs:{"hide-details":"","flat":"","light":"","prepend-inner-icon":"mdi-magnify","rounded":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":"","active":_vm.loading}}),_c('v-data-table',{attrs:{"group-by":_vm.showCustomers ? 'customerId' : 'supplierId',"custom-filter":_vm.filter,"headers":_vm.hideConditions ? _vm.headersWithoutConditions : _vm.headers,"items":_vm.orders,"items-per-page":_vm.itemsPerPage,"no-data-text":_vm.$t('thereAreCurrentlyNoOrders'),"no-results-text":_vm.$t('noOrdersWereFound'),"search":_vm.search,"sort-by":['supplierId', 'productId', 'readableDeliveryPeriod']},scopedSlots:_vm._u([(!_vm.hideEdit)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"accent","icon":"","small":""},on:{"click":function($event){return _vm.$emit('go-to-supplier', item.supplierId )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.isLive)?_c('v-btn',{attrs:{"color":"accent","icon":"","small":"","to":{ name: 'supplier', params: { id: item.supplierId } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}:null,{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+" ")]}},{key:"group.header",fn:function(ref){
var headers = ref.headers;
var isOpen = ref.isOpen;
var items = ref.items;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-icon',{attrs:{"left":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]),(_vm.showCustomers)?_c('strong',[_vm._v(_vm._s(items[0].customerDescription))]):_c('strong',[_vm._v(_vm._s(items[0].supplierFriendlyName))])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }