export default class SupplierService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(supplier, options) {
    const response = await fetch(`${this.baseUrl}/supplier`, {
      body: JSON.stringify(supplier),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUrl}/supplier/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/supplier/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async findByInvitationCode(invitationCode, options) {
    try {
      const response = await fetch(`${this.baseUrl}/supplier/invitation/${invitationCode}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/supplier`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForMe(options) {
    const response = await fetch(`${this.baseUrl}/supplier/me`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async update(id, supplier, options) {
    const response = await fetch(`${this.baseUrl}/supplier/${id}`, {
      body: JSON.stringify(supplier),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async updateByInvitationCode(invitationCode, supplier, options) {
    const response = await fetch(`${this.baseUrl}/supplier/invitation/${invitationCode}`, {
      body: JSON.stringify(supplier),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }


  async invite(id, options) {
    const response = await fetch(`${this.baseUrl}/supplier/${id}/invite`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return response.ok
  }

  async sendEmailTemplate(id, templateName, options) {
    const response = await fetch(`${this.baseUrl}/supplier/${id}/send-email-template/${templateName}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return response.ok
  }
}