<template>
  <div>
    <top-header v-bind:title="$t('orders')" />
    <v-container>
      <v-card outlined>
        <v-toolbar color="secondary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('openCustomer') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>{{ $t('infoOpenCustomer') }}</p>
          <p><strong>{{ $t('example') }}</strong>: 1234-1234-<span class="accent--text font-weight-bold text-decoration-underline">1234-1234</span></p>
          <v-row align="center">
            <v-col>
              <v-text-field v-model="shortCode" outlined flat
              v-mask="'NNNN-NNNN'"
                        solo
                        prepend-inner-icon="mdi-account"
                        v-bind:label="$t('shortCode')"
                        hide-details
                        v-on:keyup="uppercase" />
            </v-col>
            <v-col cols="auto">
              <v-btn v-on:click="openCustomer" color="accent" depressed large>
                {{ $t('open') }}
              </v-btn>
            </v-col>
          </v-row>


          <v-alert v-if="error"
                  type="error">
            {{ $t('error') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container class="pa-1">
      <v-row align="center">
        <v-col cols="auto">
          <v-btn class="noprint"
             color="accent"
             depressed
             v-on:click="print">
            <v-icon left>
              mdi-printer
            </v-icon>
            {{ $t('print') }}
          </v-btn>
          <v-btn class="ml-2 noprint"
                color="accent"
                depressed
                v-bind:loading="exportLoading"
                v-on:click="exportCsv">
            <v-icon left>
              mdi-file-table
            </v-icon>
            {{ $t('export') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-checkbox v-if="allowCustomer"
                      color="accent"
                      class="my-0"
                      hide-details="auto"
                      v-bind:label="$t('showCustomers')"
                      v-model="showCustomers" />
        </v-col>
      </v-row>


      <order-summary-card hide-conditions
                          hide-edit
                          v-bind:show-customers="showCustomers"
                          v-bind:items-per-page="itemsPerPage"
                          v-bind:orders="showCustomers ? orderListCustomer : orderList"
                          v-bind:loading="loading"
                          v-bind:is-live="isLive" />
      <p class="mt-4">* {{ $t('allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted') }}</p>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import OrderSummaryCard from '@/components/OrderSummaryCard'

  export default {
    components: {
      OrderSummaryCard,
      TopHeader
    },
    computed: {
      allowCustomer() {
        if (this.orderListCustomer == null || this.orderListCustomer.length === 0) {
          return false
        }

        return this.orderListCustomer[0].customerId !== null && this.orderListCustomer[0].customerId.length > 0
      },
      orderList() {
        return this.orderListCustomer.reduce((result, current) => {
          const index = result.findIndex(p => p.productId === current.productId && p.readableDeliveryPeriod === current.readableDeliveryPeriod)

          if (index !== -1) {
            result[index].quantity += current.quantity
          } else {
            result.push({ ...current })
          }

          return result
        }, [])
      },
      orderListCustomer() {
        return [...this.orders].map(o => ({
          readableDeliveryPeriod: o.readableDeliveryPeriod,
          productTitle: o.productTitle,
          supplierFriendlyName: o.supplierFriendlyName,
          productId: o.productId,
          quantity: o.quantity,
          packaging: o.packaging,
          customerId: o.customerId,
          customerDescription: o.customerDescription
        }))
      },
      ...mapGetters([
        'orders',
        'priceForProduct',
        'isLive'
      ])
    },
    async created() {
      const invitationCode = this.$route.params.invitationCode

      if (invitationCode == null || invitationCode.length == 0) {
        return
      }

      this.invitationCode = invitationCode

      this.loading = true
      await this.$store.dispatch('loadOrdersForSupplierInvitation', { invitationCode })
      this.loading = false
    },
    data: () => ({
      error: false,
      shortCode: null,
      itemsPerPage: 15,
      loading: false,
      exportLoading: false,
      invitationCode: null,
      showCustomers: false
    }),
    methods: {
      uppercase() {
        if (this.shortCode == null) {
          return
        }

        this.shortCode = this.shortCode.toUpperCase()
      },
      async openCustomer() {
        this.error = false

        this.loading = true
        const customer = await this.$store.dispatch('loadCustomerByShortCode', { shortCode: this.shortCode })
        this.loading = false


        if (customer == null || customer.status === 404) {
          this.error = true
          return
        }

        this.$router.push({ name: 'internal-supplier', params: { invitationCode: this.invitationCode, shortCode: this.shortCode } })
      },

      async exportCsv() {
        this.exportLoading = true

        const csv = await this.$store.dispatch('exportOrdersForSupplierInvitation', { invitationCode: this.invitationCode })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.exportLoading = false
      },
      print() {
        this.itemsPerPage = -1

        setTimeout(() => {
          window.print()
        }, 1000)

      }
    },
    name: 'SupplierOrders'
  }
</script>

<i18n>
  {
    "de": {
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "Alle Preise entsprechen der kundenindividuellen Preisliste ohne Konditionen. Irrtümer und Änderungen vorbehalten.",
      "export": "Exportieren",
      "orders": "Bestellungen",
      "print": "Drucken",
      "shortCode": "Letzte 8 Zeichen des Kunden-Einladungscodes",
      "open": "Öffnen",
      "error": "Der eingegebene Code ist ungültig oder der Kunde hat sich noch nicht vorort angemeldet.",
      "openCustomer": "Kundenordersatz öffnen",
      "infoOpenCustomer": "Bitte geben Sie die letzten 8 Zeichen des Kunden-Einladungscodes. Diese finden Sie in der Einladungs-E-Mail des Kunden.",
      "example": "Beispiel",
      "showCustomers": "Kunden anzeigen"
    },
    "en": {
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "All prices correspond to the customer-specific price list. Errors and omissions excepted.",
      "export": "Export",
      "orders": "Orders",
      "print": "Print",
      "shortCode": "Short code",
      "open": "Open",
      "error": "The entered code is invalid or the customer has not yet registered on site.",
      "openCustomer": "Open customer order",
      "infoOpenCustomer": "Please enter the last 8 characters of the customer invitation code. You will find this in the customer's invitation email.",
      "example": "Example",
      "showCustomers": "Show customers"
    }
  }
</i18n>