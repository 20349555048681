<template>
  <div>
    <top-header v-bind:title="$t('customers')">
      <v-menu slot="right-button">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <!--<v-list-item v-on:click="inviteAllCustomers">
            <v-list-item-title>{{ $t('inviteAll') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="remindAllCustomers">
            <v-list-item-title>{{ $t('remindAll') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendStartInfo">
            <v-list-item-title>{{ $t('sendStartInfo') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendEndReminder">
            <v-list-item-title>{{ $t('sendEndReminder') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendEndMessage">
            <v-list-item-title>{{ $t('sendEndMessage') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="sendEvaluation">
            <v-list-item-title>{{ $t('sendEvaluation') }}</v-list-item-title>
          </v-list-item>-->
          <v-list-item disabled
                       v-on:click="">
            <v-list-item-title>{{ $t('import') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-on:click="exportCustomers">
            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col>
          <customer-list v-bind:customers="customers"
                         v-on:invite-customer="inviteCustomer"
                         v-on:remove-customer="deleteCustomer" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CustomerList from '@/components/administration/CustomerList'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      CustomerList,
      TopHeader
    },
    computed: {
      ...mapGetters([ 'customers' ])
    },
    async created() {
      this.loading = true
      await this.$store.dispatch('loadCustomers')
      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions([
        'deleteCustomer',
        'inviteAllCustomers',
        'inviteCustomer',
        'sendEmailTemplateToAllCustomers'
      ]),
      exportCustomers() {
        if (this.customers.length === 0) {
          return
        }

        console.log(Object.keys(this.customers[0]))

        let csv = '';

        for (let key of Object.keys(this.customers[0])) {
          csv += `"${key}",`
        }

        csv += '\n'


        for (const customer of this.customers) {
          for (let key of Object.keys(this.customers[0])) {
            csv += `"${customer[key]}",`
          }

          csv += '\n'
        }

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)
      },
      ...mapActions([
        'deleteSupplier',
        'inviteAllSuppliers',
        'inviteSupplier',
        'sendEmailTemplateToAllSuppliers'
      ]),
      remindAllCustomers() {
        this.sendEmailTemplateToAllCustomers({ filter: s => s.state === 'invited', templateName: 'CustomerReminder' })
      },
      sendStartInfo() {
        this.sendEmailTemplateToAllCustomers({ filter: s => s.state === 'accepted', templateName: 'CustomerStart' })
      },
      sendEndReminder() {
        this.sendEmailTemplateToAllCustomers({ filter: s => s.state === 'accepted', templateName: 'CustomerReminder2' })
      },
      sendEndMessage() {
        this.sendEmailTemplateToAllCustomers({ filter: c => c.state === 'accepted' && c.orderCount === 0 && c.orderCount2 === 0, templateName: 'EndMessage' })
        this.sendEmailTemplateToAllCustomers({ filter: c => c.state === 'accepted' && (c.orderCount > 0 || c.orderCount2 > 0), templateName: 'EndMessageWithOrders' })
      },
      sendEvaluation() {
        this.sendEmailTemplateToAllCustomers({ filter: c => c.state === 'accepted' && c.wasAttending, templateName: 'Evaluation' })
      }
    },
    name: 'Customers'
  }
</script>

<i18n>
  {
    "de": {
      "export": "Exportieren…",
      "customers": "Kunden",
      "import": "Importieren…",
      "inviteAll": "Alle einladen",
      "remindAll": "Alle erinnern",
      "sendStartInfo": "Startinformation an Alle senden",
      "sendEndReminder": "Enderinnerung an Alle senden",
      "sendEndMessage": "Endbenachrichtigung an Alle senden",
      "sendEvaluation": "Zufriedenheits-Umfrage senden"
    },
    "en": {
      "export": "Export…",
      "customers": "Customers",
      "import": "Import…",
      "inviteAll": "Invite all",
      "remindAll": "Remind all",
      "sendStartInfo": "Send start info",
      "sendEndReminder": "Send end reminder",
      "sendEndMessage": "Send end message",
      "sendEvaluation": "Send evaluation"
    }
  }
</i18n>