<template>
  <div>
    <list-card avatar
               extended-toolbar
               item-add-route-name="administration-customer-add"
               item-edit-route-name="administration-customer"
               show-add-button
               show-remove-button
               two-line
               v-bind:filter="filter"
               v-bind:items="items"
               v-bind:title="$t('customers')"
               v-on:remove-item="removeCustomer">
      <template v-slot:item-append="{ item }">
        <v-list-item-action v-if="item.state === 'accepted'">
          <v-btn icon
                 v-bind:to="{ name: 'home', query: { invitationCode: item.invitationCode }}">
            <v-icon>mdi-login</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action v-if="item.state === 'open'">
          <v-btn icon
                 v-on:click="inviteCustomer(item.id)">
            <v-icon>mdi-email-send-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </list-card>
  </div>
</template>

<script>
  import ListCard from '@/components/common/ListCard'

  export default {
    components: {
      ListCard
    },
    computed: {
      filter() {
        return {
          property: 'state',
          values: [
            {
              name: 'all',
              showAll: true,
              title: this.$t('all')
            },
            {
              icon: 'mdi-clock-outline',
              name: 'open',
              title: this.$t('open')
            },
            {
              icon: 'mdi-email-check-outline',
              name: 'invited',
              title: this.$t('invited')
            },
            {
              icon: 'mdi-check',
              name: 'accepted',
              title: this.$t('accepted')
            },
            {
              icon: 'mdi-close',
              name: 'declined',
              title: this.$t('declined')
            },
            {
              icon: 'mdi-close',
              name: 'on-site',
              title: this.$t('onSite')
            }
          ]
        }
      },
      items() {
        return [...this.customers].map(s => ({
          ...s,
          code: this.getCode(s.companyName),
          title: `${s.companyName} (${s.id})`,
          subtitle: s.streetAddress == null ? '' : `${s.streetAddress}, ${s.postalCode} ${s.city}`
        }))
      }
    },
    methods: {
      getCode(companyName) {
        if (companyName == null) {
          return null
        }

        return companyName.match(/\b(\w)/g).join('').substr(0, 2)
      },
      inviteCustomer(id) {
        this.$emit('invite-customer', { id })
      },
      removeCustomer(id) {
        this.$emit('remove-customer', { id })
      }
    },
    name: 'CustomerList',
    props: {
      customers: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "accepted": "Angenommen",
      "all": "Alle",
      "customers": "Kunden",
      "declined": "Abgelehnt",
      "invited": "Eingeladen",
      "open": "Offen",
      "onSite": "Vorort"
    },
    "en": {
      "accepted": "Accepted",
      "all": "All",
      "customers": "Customers",
      "declined": "Declined",
      "invited": "Invited",
      "open": "Open",
      "onSite": "On site"
    }
  }
</i18n>