<template>
  <div>
    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ participantName }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('participant') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template v-if="registrationCode != null">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-barcode
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('willBeSentViaEmailPriorToStart') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('registrationCode') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </template>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-cart
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ event.startDate | formatDate('LLL') }}–{{ event.endDate | formatDate('LLL') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('orderPeriod') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-calendar
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ event.siteStartDate | formatDate('LLL') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('date') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  export default {
    computed: {
      participantName() {
        if (this.participant.companyName != null && this.participant.companyName.length !== 0 && this.participant.givenName != null && this.participant.givenName.length !== 0 && this.participant.familyName != null && this.participant.familyName.length !== 0) {
          return `${this.participant.companyName} (${this.participant.givenName} ${this.participant.familyName})`
        }

        if (this.participant.companyName != null && this.participant.companyName.length !== 0) {
          return this.participant.companyName
        }

        return this.$t('unknown')
      }
    },
    name: 'EventDetails',
    props: {
      event: Object,
      participant: Object,
      registrationCode: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "orderPeriod": "Bestellzeitraum",
      "date": "Datum",
      "participant": "Teilnehmer",
      "registrationCode": "Registrierungscode",
      "startingTime": "Startzeit",
      "unknown": "Unbekannt",
      "willBeSentViaEmailPriorToStart": "Wird vor dem Start per E-Mail versendet"
    },
    "en": {
      "orderPeriod": "Order period",
      "date": "Date",
      "participant": "Participant",
      "registrationCode": "Registration code",
      "startingTime": "Starting time",
      "unknown": "Unknown",
      "willBeSentViaEmailPriorToStart": "Will be sent via email prior to start"
    }
  }
</i18n>