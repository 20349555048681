<template>
  <div>
    <v-stepper class="elevation-0"
               v-model="step">
      <v-stepper-header class="elevation-0">
        <v-stepper-step step="1">
          {{ $t('eventDetails') }}
        </v-stepper-step>
        <v-divider />
        <template v-if="form.attendance">
          <v-stepper-step step="2">
            {{ $t('personalDetails') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            {{ $t('additionalGuests') }}
          </v-stepper-step>
          <v-divider />
        </template>
        <v-stepper-step step="4">
          {{ $t('completion') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content class="pa-1"
                           step="1">
          <event-details-card ref="eventDetailsCard"
                              v-bind:event="event"
                              v-bind:form="form"
                              v-bind:next-step-button="form.attendance ? $t('next') : $t('send')"
                              v-bind:participant="customer"
                              v-on:next-step="validateEventDetailsAndContinue" />
        </v-stepper-content>
        <v-stepper-content class="pa-1"
                           step="2">
          <customer-personal-details-card ref="customerPersonalDetailsCard"
                                          v-bind:customer="customer"
                                          v-bind:next-step-button="$t('next')"
                                          v-bind:previous-step-button="$t('back')"
                                          v-bind:validation-alert="customerPersonalDetailsCardValidationAlert"
                                          v-on:next-step="validateCustomerPersonalDetailsAndContinue"
                                          v-on:previous-step="step -= 1" />
        </v-stepper-content>
        <v-stepper-content class="pa-1"
                           step="3">
          <additional-guests-card ref="additionalGuestsCard"
                                  v-bind:form="form"
                                  v-bind:next-step-button="$t('send')"
                                  v-bind:previous-step-button="$t('back')"
                                  v-on:next-step="validateAdditionalGuestsAndContinue()"
                                  v-on:previous-step="step -= 1" />
        </v-stepper-content>
        <v-stepper-content class="pa-1"
                           step="4">
          <completion-card v-bind:attendance="form.attendance"
                           v-bind:event="event"
                           v-bind:participant="customer" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
  import AdditionalGuestsCard from '@/components/registration/AdditionalGuestsCard'
  import CompletionCard from '@/components/registration/CompletionCard'
  import CustomerPersonalDetailsCard from '@/components/registration/CustomerPersonalDetailsCard'
  import EventDetailsCard from '@/components/registration/EventDetailsCard'

  export default {
    components: {
      AdditionalGuestsCard,
      CompletionCard,
      CustomerPersonalDetailsCard,
      EventDetailsCard
    },
    data: () => ({
      customerPersonalDetailsCardValidationAlert: false,
      form: {
        additionalGuests: [
          {}
        ],
        attendance: true,
        hasAdditionalGuests: false
      },
      step: 1
    }),
    methods: {
      sendAndSowCompletion() {
        if (this.form.additionalGuests.length >= 1) {
          this.customer.firstGuestGivenName = this.form.additionalGuests[0].givenName
          this.customer.firstGuestFamilyName = this.form.additionalGuests[0].familyName
          this.customer.firstGuestEmail = this.form.additionalGuests[0].email
        }

        if (this.form.additionalGuests.length >= 2) {
          this.customer.secondGuestGivenName = this.form.additionalGuests[1].givenName
          this.customer.secondGuestFamilyName = this.form.additionalGuests[1].familyName
          this.customer.secondGuestEmail = this.form.additionalGuests[1].email
        }

        if (this.form.additionalGuests.length >= 3) {
          this.customer.guestGivenName3 = this.form.additionalGuests[2].givenName
          this.customer.guestFamilyName3 = this.form.additionalGuests[2].familyName
          this.customer.guestEmail3 = this.form.additionalGuests[2].email
        }

        if (this.form.additionalGuests.length >= 4) {
          this.customer.guestGivenName4 = this.form.additionalGuests[3].givenName
          this.customer.guestFamilyName4 = this.form.additionalGuests[3].familyName
          this.customer.guestEmail4 = this.form.additionalGuests[3].email
        }

        if (this.form.additionalGuests.length >= 5) {
          this.customer.guestGivenName5 = this.form.additionalGuests[4].givenName
          this.customer.guestFamilyName5 = this.form.additionalGuests[4].familyName
          this.customer.guestEmail5 = this.form.additionalGuests[4].email
        }

        if (this.form.additionalGuests.length >= 6) {
          this.customer.guestGivenName6 = this.form.additionalGuests[5].givenName
          this.customer.guestFamilyName6 = this.form.additionalGuests[5].familyName
          this.customer.guestEmail6 = this.form.additionalGuests[5].email
        }

        if (this.form.additionalGuests.length >= 7) {
          this.customer.guestGivenName7 = this.form.additionalGuests[6].givenName
          this.customer.guestFamilyName7 = this.form.additionalGuests[6].familyName
          this.customer.guestEmail7 = this.form.additionalGuests[6].email
        }

        if (this.form.additionalGuests.length >= 8) {
          this.customer.guestGivenName8 = this.form.additionalGuests[7].givenName
          this.customer.guestFamilyName8 = this.form.additionalGuests[7].familyName
          this.customer.guestEmail8 = this.form.additionalGuests[7].email
        }

        if (this.form.additionalGuests.length >= 9) {
          this.customer.guestGivenName9 = this.form.additionalGuests[8].givenName
          this.customer.guestFamilyName9 = this.form.additionalGuests[8].familyName
          this.customer.guestEmail9 = this.form.additionalGuests[8].email
        }

        if (this.form.additionalGuests.length >= 10) {
          this.customer.guestGivenName10 = this.form.additionalGuests[9].givenName
          this.customer.guestFamilyName10 = this.form.additionalGuests[9].familyName
          this.customer.guestEmail10 = this.form.additionalGuests[9].email
        }

        this.$emit('send', {
          customer: this.customer,
          form: this.form
        })

        this.step = 4
      },
      validateAdditionalGuestsAndContinue() {
        if (!this.$refs.additionalGuestsCard.validate()) {
          return
        }

        this.sendAndSowCompletion()
      },
      validateEventDetailsAndContinue() {
        if (!this.$refs.eventDetailsCard.validate()) {
          return
        }

        if (this.form.attendance) {
          this.step += 1
        } else {
          this.sendAndSowCompletion()
        }
      },
      validateCustomerPersonalDetailsAndContinue() {
        if (!this.$refs.customerPersonalDetailsCard.validate()) {
          this.customerPersonalDetailsCardValidationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        this.step = 3
      }
    },
    name: 'CustomerRegistrationStepper',
    props: {
      event: Object,
      customer: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "additionalGuests": "Weitere Gäste",
      "addToCalendar": "Zum Kalender hinzufügen",
      "back": "Zurück",
      "completion": "Abschluss",
      "eventDetails": "Veranstaltungsdetails",
      "next": "Weiter",
      "personalDetails": "Personalien",
      "send": "Absenden"
    },
    "en": {
      "additionalGuests": "Additional guests",
      "addToCalendar": "Add to calendar",
      "back": "Back",
      "completion": "Completion",
      "eventDetails": "Event details",
      "next": "Next",
      "personalDetails": "Personal details",
      "send": "Send"
    }
  }
</i18n>