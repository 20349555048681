<template>
  <div>
    <v-progress-linear color="accent"
                          indeterminate
                          v-bind:active="loading" />
    <div v-if="customer != null">
      <v-snackbar dark
                  right
                  top
                  v-model="confirmationSnackbar">
        {{ $t('saved') }}
      </v-snackbar>
      <top-header v-bind:title="customer.companyName | defaultValue($t('untitledCustomer'))">
        <v-btn dark
              exact
              slot="left-button"
              text
              v-bind:to="{ name: 'administration-customers' }">
          <v-icon left>mdi-chevron-left</v-icon>
          {{ $t('back') }}
        </v-btn>
        <v-btn dark
              exact
              slot="right-button"
              text
              v-bind:disabled="!hasChanges"
              v-on:click="updateCustomer">
          {{ $t('save') }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </top-header>
      <v-tabs align-with-title
              background-color="primary"
              dark
              v-model="tabs">
        <v-tab href="#common">{{ $t('common') }}</v-tab>
        <v-tab href="#orders">{{ $t('orders') }}</v-tab>
      </v-tabs>
      <v-alert tile
              type="error"
              v-model="validationAlert">
        {{ $t('pleaseCompleteTheForm') }}
      </v-alert>
      <v-container>
        <v-btn class="mb-4 ml-2"
              color="accent"
              depressed
              v-bind:loading="exportLoading"
              v-on:click="exportCsv">
          <v-icon left>
            mdi-file-table
          </v-icon>
          {{ $t('exportOrders') }}
        </v-btn>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="common">
            <v-row>
              <v-col cols="12"
                    lg="6">
                <customer-edit-card ref="customerEditCard"
                                    v-bind:customer="customer"
                                    v-bind:edit-mode="true" />
              </v-col>
              <v-col cols="12"
                    lg="6">
                <customer-invitation-edit-card class="mb-8"
                                              ref="customerInvitationEditCard"
                                              v-bind:customer="customer" />
                <customer-additional-guests-edit-card ref="customerAdditionalGuestsEditCard"
                                                      v-bind:customer="customer" />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="orders">
            <order-summary-card v-bind:items-per-page="20"
                                v-bind:orders="orderList"
                                v-bind:is-admin="true"
                                v-on:go-to-supplier="goToSupplier" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CustomerAdditionalGuestsEditCard from '@/components/administration/CustomerAdditionalGuestsEditCard'
  import CustomerEditCard from '@/components/administration/CustomerEditCard'
  import CustomerInvitationEditCard from '@/components/administration/CustomerInvitationEditCard'
  import OrderSummaryCard from '@/components/OrderSummaryCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = to.params.id

      await this.get(id)

      next()
    },
    components: {
      CustomerAdditionalGuestsEditCard,
      CustomerEditCard,
      CustomerInvitationEditCard,
      OrderSummaryCard,
      TopHeader
    },
    computed: {
      orderList() {
        return [...this.orders].filter(o => o.quantity > 0)
      },
      ...mapGetters([
        'orders'
      ])
    },
    async created() {
      const id = this.$route.params.id

      await this.get(id)
    },
    data: () => ({
      confirmationSnackbar: false,
      hasChanges: false,
      id: null,
      customer: null,
      tabs: null,
      loading: false,
      validationAlert: false,
      exportLoading: false
    }),
    methods: {
      async goToSupplier(supplierId) {
        await this.$store.dispatch('setCurrentCustomer', { customer: this.customer })
        this.$router.push({ name: 'supplier', params: { id: supplierId } })
      },
      async get(id) {
        this.id = id

        this.loading = true

        await this.$store.dispatch('loadCustomers')
        await this.$store.dispatch('loadPricesForCustomer', { id })
        await this.$store.dispatch('loadOrdersForCustomer', { id })

        this.loading = false

        this.customer = Object.assign({}, this.$store.getters.customer(id))
      },
      async exportCsv() {
        this.exportLoading = true

        const csv = await this.$store.dispatch('exportOrdersForCustomer', { customerId: this.id })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.exportLoading = false
      },
      async updateCustomer() {
        if (!this.$refs.customerEditCard.validate() || !this.$refs.customerInvitationEditCard.validate() || !this.$refs.customerAdditionalGuestsEditCard.validate()) {
          this.validationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        await this.$store.dispatch('updateCustomer', { customer: this.customer })

        this.confirmationSnackbar = true
        this.hasChanges = false
      }
    },
    name: 'Customer',
    watch: {
      customer: {
        deep: true,
        handler(newCustomer, oldCustomer) {
          if (newCustomer == null || oldCustomer == null) {
            this.hasChanges = false
          } else if (newCustomer.id !== oldCustomer.id) {
            this.hasChanges = false
          } else {
            this.hasChanges = true
          }
        }
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "common": "Allgemein",
      "back": "Zurück",
      "exportOrders": "Bestellungen exportieren",
      "orders": "Bestellungen",
      "pleaseCompleteTheForm": "Bitte vervollständigen Sie Ihre Angaben.",
      "save": "Speichern",
      "saved": "Gespeichert.",
      "untitledCustomer": "Unbenannter Kunde"
    },
    "en": {
      "common": "Allgemein",
      "back": "Back",
      "exportOrders": "Export orders",
      "orders": "Bestellungen",
      "pleaseCompleteTheForm": "Please complete the form.",
      "save": "Save",
      "saved": "Saved.",
      "untitledCustomer": "Unitled customer"
    }
  }
</i18n>