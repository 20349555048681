<template>
  <div>
    <top-header v-bind:title="$t('attendance')" />
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col>
          <attendance-card v-bind:customers="customers"
                           v-on:confirm="confirmCustomer" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import AttendanceCard from '@/components/administration/AttendanceCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      AttendanceCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        customers: 'customers'
      })
    },
    async created() {
      this.loading = true
      await this.loadCustomers()
      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      async confirmCustomer(customer) {

        this.loading = true

        await this.setAttendingCustomer({ customer })

        this.loading = false
      },
      ...mapActions({
        loadCustomers: 'loadCustomers',
        setAttendingCustomer: 'setAttendingCustomer'
      })
    },
    name: 'Attendance'
  }
</script>

<i18n>
{
  "de": {
    "attendance": "Einlass"
  },
  "en": {
    "attendance": "Attendance"
  }
}
</i18n>