<template>
  <div>
    <v-img class="align-end white--text" v-bind:gradient="gradient" aspect-ratio="4" v-bind:src="imageSrc">
      <v-container class="fill-height">
        <v-row>
          <v-col cols="auto"
                 v-if="!!this.$slots['left-button']">
            <slot name="left-button" />
          </v-col>
          <v-spacer />
          <v-col cols="auto"
                 v-if="!!this.$slots['right-button']">
            <slot name="right-button" />
          </v-col>
          <v-col cols="auto"
                 v-if="!!this.$slots['menu']">
            <slot name="menu" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <slot>
              <h1 class="display-1 mx-4 white--text">{{ title }}</h1>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
  export default {
    name: 'TopImageHeader',
    props: {
      imageSrc: String,
      title: String,
      gradient: {
        default: 'to bottom, rgba(17, 62, 124, .4), rgba(17, 62, 124, 1)',
        type: String
      }
    }
  }
</script>

<style scoped>
.header {
  height: 168px;
}
</style>