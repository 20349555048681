var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('products')))]),_c('v-spacer'),(_vm.isMobile)?_c('v-btn-toggle',{attrs:{"mandatory":"","active-class":"accent"},model:{value:(_vm.selectedDeliveryPeriod),callback:function ($$v) {_vm.selectedDeliveryPeriod=$$v},expression:"selectedDeliveryPeriod"}},[_c('v-btn',[_vm._v(" "+_vm._s(_vm._f("defaultValue")(_vm.supplier.deliveryPeriod0Tag,_vm.$t('period1')))+" ")]),_c('v-btn',[_vm._v(" "+_vm._s(_vm._f("defaultValue")(_vm.supplier.deliveryPeriod1Tag,_vm.$t('period2')))+" ")])],1):_vm._e()],1),_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":"","active":_vm.loading}}),_c('v-form',{ref:"form"},[_vm._l((_vm.products),function(product,index){return [_c('v-card-text',{key:((product.id) + "-card_text"),staticClass:"py-0",class:_vm.isHighlighted(product) ? 'blue-grey lighten-4' : null},[_c('v-row',{attrs:{"align":"center"}},[(!_vm.isMobile || _vm.selectedDeliveryPeriod === 0)?_c('v-col',{attrs:{"cols":"4","sm":"auto"}},[_c('v-tooltip',{attrs:{"right":"","color":"accent","disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"caption mb-1"},[_vm._v(" "+_vm._s(_vm._f("defaultValue")(_vm.supplier.deliveryPeriod0Tag,_vm.$t('period1')))+" ")]),_c('v-text-field',_vm._g(_vm._b({staticClass:"quantity-field",attrs:{"background-color":_vm.highlightQuantityColor(product, 0),"dark":_vm.highlightQuantityDark(product, 0),"rules":[ _vm.rules.validQuantity(product, 0) ],"flat":"","hide-details":"auto","solo":"","suffix":"x","type":"number"},model:{value:(product.orderQuantity[0]),callback:function ($$v) {_vm.$set(product.orderQuantity, 0, $$v)},expression:"product.orderQuantity[0]"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('theQuantityhasToBeAtLeast', { min: product.quantity[0] })))])])],1):_vm._e(),(!_vm.isMobile || _vm.selectedDeliveryPeriod === 1)?_c('v-col',{attrs:{"cols":"4","sm":"auto"}},[_c('v-tooltip',{attrs:{"right":"","color":"accent","disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"caption mb-1"},[_vm._v(" "+_vm._s(_vm._f("defaultValue")(_vm.supplier.deliveryPeriod1Tag,_vm.$t('period2')))+" ")]),_c('v-text-field',_vm._g(_vm._b({staticClass:"quantity-field",attrs:{"background-color":_vm.highlightQuantityColor(product, 1),"dark":_vm.highlightQuantityDark(product, 1),"rules":[ _vm.rules.validQuantity(product, 1) ],"flat":"","hide-details":"auto","solo":"","suffix":"x","type":"number"},model:{value:(product.orderQuantity[1]),callback:function ($$v) {_vm.$set(product.orderQuantity, 1, $$v)},expression:"product.orderQuantity[1]"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('theQuantityhasToBeAtLeast', { min: product.quantity[1] })))])])],1):_vm._e(),(product.imageCount > 0)?_c('v-col',{attrs:{"cols":"4","sm":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"max-height":"64","max-width":"64","src":("/images/products/" + (product.id) + "_1.jpg")}},'v-img',attrs,false),on))]}}],null,true)},[_c('v-carousel',{attrs:{"cycle":"","hide-delimiters":"","interval":"1500","continuous":true,"show-arrows":false}},_vm._l((product.imageCount),function(index){return _c('v-carousel-item',{key:index},[_c('v-img',{attrs:{"eager":"","max-height":"512","max-width":"512","src":("/images/products/" + (product.id) + "_" + index + ".jpg")}})],1)}),1)],1)],1):_vm._e(),_c('v-col',{staticClass:"text-wrap"},[_c('div',[_c('strong',[_vm._v(_vm._s(product.orderUnit != null && product.orderUnit.length !== 0 ? product.orderUnit : ''))]),_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(" "+_vm._s(product.packagingText != null && product.packagingText.length !== 0 ? ("(" + (product.packagingText) + ")") : ''))])]),_c('div',[(product.label != null)?_c('strong',{class:{ 'accent--text': product.label === 'NEU' }},[_vm._v(_vm._s(product.label)+":")]):_vm._e(),_vm._v(" "+_vm._s(product.title)+" ("+_vm._s(product.id)+") "),(product.newText2 != null && product.newText2.length !== 0)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(product.newText2))])]):_vm._e()],1),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(product.newText1)+" ")])]),_c('v-col',{staticClass:"subtitle-1 text-right",attrs:{"cols":"12","sm":"auto"}},[_c('strong',[_vm._v(_vm._s(_vm.formatPrice(product.price))+"*")]),_c('br'),(product.discount != null)?_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(product.discount))]):_vm._e()])],1)],1),(index !== _vm.products.length - 1)?_c('v-divider',{key:((product.id) + "-divider")}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }