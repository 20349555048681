import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import i18n from './i18n'

import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      light: {
        accent: '#e3000a',
        error: '#e3000a',
        primary: '#113e7c',
        secondary: '#212121'
      }
    }
  }
})