<template>
  <div>
    <top-header v-bind:title="$t('orderAnalytics')" />
    <v-tabs align-with-title
            background-color="primary"
            dark
            v-model="tabs">
      <v-tab href="#branches">{{ $t('branches') }}</v-tab>
      <v-tab href="#other">{{ $t('other') }}</v-tab>
    </v-tabs>
    <v-container>
      <v-btn class="mb-4 ml-2"
             color="accent"
             depressed
             v-bind:loading="exportLoading"
             v-on:click="exportCsv">
        <v-icon left>
          mdi-file-table
        </v-icon>
        {{ $t('exportAllOrders') }}
      </v-btn>
      <customer-analytics-card v-bind:customers="filteredCustomers" />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

  import CustomerAnalyticsCard from '@/components/administration/CustomerAnalyticsCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      CustomerAnalyticsCard,
      TopHeader
    },
    computed: {
      filteredCustomers() {
        if (this.tabs == 'branches') {
          return this.customers.filter(c => c.branch)
        } else if (this.tabs === 'other') {
          return this.customers.filter(c => !c.branch)
        } else {
          return this.customers
        }


      },
      ...mapGetters([ 'customers' ])
    },
    async created() {
      await this.$store.dispatch('loadCustomers')
    },
    data: () => ({
      tabs: null,
      exportLoading: false
    }),
    methods: {
      async exportCsv() {
        this.exportLoading = true

        const csv = await this.$store.dispatch('exportOrders')

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.exportLoading = false
      }
    },
    name: 'OrderAnalytics'
  }
</script>

<i18n>
  {
    "de": {
      "branches": "Filialen",
      "exportAllOrders": "Alle Bestellungen exportieren",
      "orderAnalytics": "Bestellanalyse",
      "other": "Andere"
    },
    "en": {
      "branches": "Branches",
      "exportAllOrders": "Export all orders",
      "orderAnalytics": "Order analytics",
      "other": "Others"
    }
  }
</i18n>