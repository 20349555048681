export default class PriceService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async createOrUpdateForMe(evaluation, options) {
    const response = await fetch(`${this.baseUrl}/evaluation/me`, {
      body: JSON.stringify(evaluation),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json',
        'Invitation-Code': options.invitationCode
      },
      method: 'PUT'
    })

    return response.ok
  }

  async findForMe(options) {
    try {
      const response = await fetch(`${this.baseUrl}/evaluation/me`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Invitation-Code': options.invitationCode
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }
}