<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('invitation') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <p class="caption mb-1">{{ $t('invitationCode') }}</p>
          <p class="body-1">{{ customer.invitationCode }}</p>
          <!--<v-text-field class="mb-4"
                        disabled
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('invitationCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.invitationCode" />-->
          <v-select class="mb-4"
                    hide-details="auto"
                    outlined
                    v-bind:items="states"
                    v-bind:label="$t('state')"
                    v-bind:rules="[ rules.required ]"
                    v-model="customer.state" />
          <!--<v-checkbox color="accent"
                          class="my-4"
                          hide-details="auto"
                          v-bind:label="$t('sendSamples')"
                          v-model="customer.sendSamples" />
          <v-checkbox color="accent"
                      class="my-0"
                      hide-details="auto"
                      v-bind:label="$t('onSiteFair')"
                      v-model="customer.onSiteFair" />-->
          <v-checkbox color="accent"
                      class="my-0"
                      hide-details="auto"
                      v-bind:label="$t('wasAttending')"
                      v-model="customer.wasAttending" />
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      },
      states() {
        return [
          {
            text: this.$t('open'),
            value: 'open'
          },
          {
            text: this.$t('invited'),
            value: 'invited'
          },
          {
            text: this.$t('accepted'),
            value: 'accepted'
          },
          {
            text: this.$t('declined'),
            value: 'declined'
          }
        ]
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CustomerInvitationEditCard',
    props: {
      customer: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "accepted": "Angenommen",
      "declined": "Abgelehnt",
      "invitation": "Einladung",
      "invitationCode": "Einladungscode",
      "invited": "Eingeladen",
      "onSiteFair": "Zur Präsenzmesse einladen",
      "open": "Offen",
      "sendSamples": "Kostprobenpaket versenden",
      "state": "Status",
      "wasAttending": "Hat teilnehmen"
    },
    "en": {
      "accepted": "Accepted",
      "declined": "Declined",
      "invitation": "Invitation",
      "invitationCode": "Invitation code",
      "invited": "Invited",
      "onSiteFair": "Invite to on site fair",
      "open": "Open",
      "sendSamples": "Send samples",
      "state": "State",
      "wasAttending": "Was attending"
    }
  }
</i18n>