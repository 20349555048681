<template>
  <div>
    <top-header v-bind:title="$t('suppliers')">
      <h1 class="display-1 mx-4 white--text">{{$t('suppliers') }}</h1>
      <v-text-field class="mt-4 mx-4"
                        hide-details
                        flat
                        light
                        prepend-inner-icon="mdi-magnify"
                        rounded
                        solo
                        v-model="search" />
    </top-header>
    <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
    <v-container class="pa-1">
      <supplier-presentation-list v-bind:suppliers="filteredSuppliers"
                                  v-bind:lastSupplierId="lastSupplierId" />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import SupplierPresentationList from '@/components/SupplierPresentationList'

  export default {
    components: {
      SupplierPresentationList,
      TopHeader
    },
    async created() {
      this.loading = true
      await this.$store.dispatch('loadSuppliersForMe')
      this.$store.dispatch('loadProductsForMe') // Load in background, since it is only used for searching
      this.loading = false

      if (this.$route.query.lastSupplierId != null) {
        this.lastSupplierId = this.$route.query.lastSupplierId
      }
    },
    computed: {
      filteredSuppliers() {
        let suppliers = this.suppliers

        if (this.search != null && this.search.count !== 0) {
          const search = this.search.toLowerCase()

          let productSuppliers = this.products.filter(p => `${p.id} ${p.title}`.toLowerCase().includes(search)).map(p => p.supplierId)

          suppliers = suppliers.filter(s => s.companyName.toLowerCase().includes(search) || productSuppliers.includes(s.id))

        }

        return suppliers
      },
      suppliers() {
        return this.suppliersForOrderType(null).filter(s => s.productCount > 0)
      },
      ...mapGetters([
        'products',
        'suppliersForOrderType'
      ])
    },
    data: () => ({
      loading: false,
      lastSupplierId: null,
      search: null
    }),
    name: 'SuppliersList',
    watch: {
      lastSupplierId() {
        let element = document.getElementById(this.lastSupplierId)
        if (element != null) {
          this.$vuetify.goTo(element, { duration: 0 })
        }
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "suppliers": "Lieferanten"
    },
    "en": {
      "suppliers": "Suppliers"
    }
  }
</i18n>