import Vue from 'vue'
import VueMask from 'v-mask'

import moment from 'moment'

import App from './App.vue'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'


Vue.filter('truncate', (value, length) => {
  if (value == null) {
    return null
  }
  return value.length > length ? `${value.substring(0, length)}…` : value;
})

Vue.filter('defaultValue', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue
  }

  return value
})

Vue.filter('formatDate', (value, format) => {
  if (value == null || value.length === 0) {
    return ''
  }

  return moment(value).locale(i18n.locale).format(format);
})

Vue.filter('fromNow', value => {
  return moment(value).locale(i18n.locale).fromNow()
})

Vue.use(VueMask)

new Vue({
  i18n,
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')