export default class QuantityService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async clear(options) {
    const response = await fetch(`${this.baseUrl}/quantity/all`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async import(options) {
    const response = await fetch(`${this.baseUrl}/quantity/import/sql/`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}