<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('customer') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:disabled="editMode"
                        v-bind:label="$t('customerNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.id" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('companyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.companyName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('salutation')"
                        v-model="customer.salutation" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-model="customer.givenName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-model="customer.familyName" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('streetAddress')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.streetAddress" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('postalCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.postalCode" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('city')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.city" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.email" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('group')"
                        v-model="customer.group" />
          <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('color')"
                        v-model="customer.color" />
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('orderType')"
                        v-model="customer.orderType" />
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CustomerEditCard',
    props: {
      customer: Object,
      editMode: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "city": "Stadt",
      "color": "Farbe",
      "companyName": "Firmenname",
      "customer": "Kunde",
      "customerNumber": "Kundennummer",
      "email": "E-Mail-Adresse",
      "givenName": "Vorname",
      "group": "Gruppe",
      "familyName": "Nachname",
      "postalCode": "Postleitzahl",
      "salutation": "Anrede",
      "streetAddress": "Adresse",
      "orderType": "Ordersatz"
    },
    "en": {
      "city": "City",
      "color": "Color",
      "companyName": "Company name",
      "customer": "Customer",
      "customerNumber": "Customer number",
      "email": "Email",
      "givenName": "Given name",
      "group": "Group",
      "familyName": "Family name",
      "postalCode": "Postal code",
      "salutation": "Salutation",
      "streetAddress": "Street address",
      "orderType": "Order type"
    }
  }
</i18n>