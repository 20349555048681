<template>
  <div>
    <top-header v-bind:title="$t('orders')" class="no-print" />
    <v-container class="pa-1">
      <v-btn class="mb-1 noprint"
             color="accent"
             depressed
             v-on:click="print">
        <v-icon left>
          mdi-printer
        </v-icon>
        {{ $t('print') }}
      </v-btn>
      <v-btn class="mb-1 ml-2 noprint"
             color="accent"
             depressed
             v-bind:loading="exportLoading"
             v-on:click="exportCsv">
        <v-icon left>
          mdi-file-table
        </v-icon>
        {{ $t('export') }}
      </v-btn>
      <order-summary-card v-bind:items-per-page="itemsPerPage"
                          v-bind:orders="orderList"
                          v-bind:loading="loading"
                          v-bind:is-live="isLive" />
      <p class="mt-4">* {{ $t('allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted') }}</p>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import OrderSummaryCard from '@/components/OrderSummaryCard'

  export default {
    components: {
      OrderSummaryCard,
      TopHeader
    },
    computed: {
      orderList() {
        return [...this.orders].map(o => ({
          ...o,
          price: this.priceForProduct(o.productId)
        })).filter(o => o.quantity > 0)
      },
      ...mapGetters([
        'orders',
        'priceForProduct',
        'isLive'
      ])
    },
    async created() {
      this.loading = true
      await this.$store.dispatch('loadPricesForMe')
      await this.$store.dispatch('loadOrdersForMe')
      this.loading = false
    },
    data: () => ({
      itemsPerPage: 10,
      loading: false,
      exportLoading: false
    }),
    methods: {
      async exportCsv() {
        this.exportLoading = true

        const csv = await this.$store.dispatch('exportOrdersForMe')

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.exportLoading = false
      },
      print() {
        this.itemsPerPage = -1

        setTimeout(() => {
          window.print()
        }, 1000)

      }
    },
    name: 'Orders'
  }
</script>

<style>
  @media print {
    html {
      font-size: 10pt !important;
    }

    .no-print {
      display: none;
    }
  }
</style>

<i18n>
  {
    "de": {
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "Alle Preise entsprechen der kundenindividuellen Preisliste ohne Konditionen. Irrtümer und Änderungen vorbehalten.",
      "export": "Exportieren",
      "orders": "Bestellungen",
      "print": "Drucken"
    },
    "en": {
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "All prices correspond to the customer-specific price list. Errors and omissions excepted.",
      "export": "Export",
      "orders": "Orders",
      "print": "Print"
    }
  }
</i18n>