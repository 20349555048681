<template>
  <div v-if="customer != null">
    <top-header>
      <img class="logo" src="/images/logo.png">
    </top-header>
    <v-container class="container pa-0">
      <customer-registration-stepper v-bind:customer="customer"
                                     v-bind:event="event"
                                     v-on:send="updateCustomer" />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import CustomerRegistrationStepper from '@/components/registration/CustomerRegistrationStepper'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationCode = to.params.invitationCode

      await this.get(invitationCode)

      next()
    },
    components: {
      CustomerRegistrationStepper,
      TopHeader
    },
    computed: {
      ...mapGetters({
        event: 'event'
      })
    },
    async created() {
      const invitationCode = this.$route.params.invitationCode

      await this.get(invitationCode)
    },
    data: () => ({
      customer: null,
      invitationCode: null
    }),
    methods: {
      async get(invitationCode) {
        this.invitationCode = invitationCode

        await this.$store.dispatch('loadCustomerByInvitationCode', { invitationCode })

        const customer = this.$store.getters.customerByInvitationCode(invitationCode)

        if (customer == null || customer.state === 'accepted' || customer.state === 'declined') {
          this.$router.push({ name: 'home',  query: { invitationCode }  })
        }

        this.customer = Object.assign({}, customer)
      },
      async updateCustomer({ customer, form }) {
        customer.state = form.attendance ? 'accepted' : 'declined'

        this.$store.dispatch('updateCustomer', { customer })
        /*await this.$store.dispatch('setCustomerState', {
          id: customer.id,
          state: form.attendance ? 'accepted' : 'declined'
        })*/
      }
    },
    name: 'CustomerRegistration'
  }
</script>

<style scoped>
  .container {
    max-width: 720px;
  }

  .logo {
    display: block;
    margin: 0 0 0 auto;
    max-height: 80px;
  }
</style>