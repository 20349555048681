<template>
  <div>
    <v-card outlined>
      <v-card-title class="primary--text">{{ event.title }}</v-card-title>
      <slot>
        <v-card-text>
          <p>Wir freuen uns über Ihr Interesse an unserer Hausmesse. Im Folgenden haben Sie die Möglichkeit, sich für die Teilnahme sowie Nutzung unserer Bestell-Web-App zu registrieren. Mit dieser können Sie vor und während der Messe sowohl vom PC, vom Tablet als auch von Ihrem Smartphone aus Ihre Bestellmengen komfortabel erfassen, korrigieren und später ausdrucken.</p>
          <p>Sollten Sie nicht teilnehmen können, so lassen Sie uns dies bitte ebenfalls durch Auswahl der entsprechenden Option wissen.</p>
        </v-card-text>
      </slot>
      <event-details v-bind:event="event"
                     v-bind:participant="participant" />
      <v-form ref="form">
        <v-card-text>
          <v-radio-group class="my-0"
                         v-model="form.attendance">
            <v-radio color="accent"
                     v-bind:value="true"
                     v-bind:label="$t('iWouldLikeToParticipate')" />
            <v-radio color="accent"
                     v-bind:value="false"
                     v-bind:label="$t('iDoNotWantToParticipate')" />
          </v-radio-group>
        </v-card-text>
        <v-card-text>
          <v-checkbox class="my-0"
                      hide-details="auto"
                      v-bind:label="$t('iAgreeWithTheProcessingOfMyDataAccordingToThePrivacyPolicy')"
                      v-bind:rules="[ rules.required ]"
                      v-model="form.acceptPrivacyPolicy" />
        </v-card-text>
      </v-form>
      <v-card-actions class="pa-4"
                      v-if="nextStepButton != null || previousStepButton != null">
        <v-btn color="accent"
               outlined
               v-if="previousStepButton != null"
               v-on:click="previousStep">
          {{ previousStepButton }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               depressed
               v-if="nextStepButton != null"
               v-on:click="nextStep">
          {{ nextStepButton }}
        </v-btn>
      </v-card-actions>
      <!--<v-card-text>
        <p>Im kommenden Jahr freuen wir uns ganz besonders darauf, Sie zu unserer 25. Jubiläumsmesse hier in Coburg wieder persönlich begrüßen zu dürfen! Spätestens bis dahin verbleiben wir</p>
      </v-card-text>-->
      <!--<v-img aspect-ratio="2"
             position="top center"
             src="/images/header2.jpg">
      </v-img>-->
      <!--<v-card-text>
        <p>mit herzlichen Grüßen aus Coburg</p>
        <p>Ihre</p>
        <v-container class="pa-0">
          <v-row>
            <v-col cols="6" md="3" class="text-center">
              <img class="portrait" src="/images/portrait-albert.jpg">
              <img class="signature" src="/images/signature-albert.jpg">
              Knut<br>Albert
            </v-col>
            <v-col cols="6" md="3" class="text-center">
              <img class="portrait" src="/images/portrait-sagasser.jpg">
              <img class="signature" src="/images/signature-sagasser.jpg">
              Cornelius<br>Sagasser
            </v-col>
            <v-col cols="6" md="3" class="text-center">
              <img class="portrait" src="/images/portrait-bischoff.jpg">
              <img class="signature" src="/images/signature-bischoff.jpg">
              Uwe<br>Bischoff
            </v-col>
            <v-col cols="6" md="3" class="text-center">
              <img class="portrait" src="/images/portrait-krietemeyer.jpg">
              <img class="signature" src="/images/signature-krietemeyer.jpg">
              Olaf<br>Krietemeyer
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>-->
    </v-card>
  </div>
</template>

<script>
  import EventDetails from '@/components/registration/EventDetails'

  export default {
    components: {
      EventDetails
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      nextStep() {
        this.$emit('next-step')
      },
      previousStep() {
        this.$emit('previous-step')
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'EventDetailsCard',
    props: {
      event: Object,
      form: Object,
      nextStepButton: String,
      participant: Object,
      previousStepButton: String
    }
  }
</script>

<style scoped>
  .signature {
    display: block;
    height: 48px;
  }
  .portrait {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
  }
</style>

<i18n>
  {
    "de": {
      "iAgreeWithTheProcessingOfMyDataAccordingToThePrivacyPolicy": "Ich bin mit der Verarbeitung meiner Daten gemäß der Datenschutzerklärung einverstanden",
      "iDoNotWantToParticipate": "Ich möchte nicht teilnehmen",
      "iWouldLikeToParticipate": "Ich möchte teilnehmen"
    },
    "en": {
      "iAgreeWithTheProcessingOfMyDataAccordingToThePrivacyPolicy": "I agree with the processing of my data according to the privacy policy",
      "iDoNotWantToParticipate": "I do not want to participate",
      "iWouldLikeToParticipate": "I would like to participate"
    }
  }
</i18n>