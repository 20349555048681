export default class TextSnippetService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/textSnippet/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async update(id, textSnippet, options) {
    const response = await fetch(`${this.baseUrl}/textSnippet/${id}`, {
      body: JSON.stringify(textSnippet),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}