import { render, staticRenderFns } from "./CustomerInvitationEditCard.vue?vue&type=template&id=4f267cb6&"
import script from "./CustomerInvitationEditCard.vue?vue&type=script&lang=js&"
export * from "./CustomerInvitationEditCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CustomerInvitationEditCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VCheckbox,VForm,VSelect,VToolbar,VToolbarTitle})
