export default class CustomerService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(customer, options) {
    const response = await fetch(`${this.baseUrl}/customer`, {
      body: JSON.stringify(customer),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUrl}/customer/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/customer/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async findByInvitationCode(invitationCode, options) {
    try {
      const response = await fetch(`${this.baseUrl}/customer/invitation/${invitationCode}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async findByShortCode(shortCode, options) {
    try {
      const response = await fetch(`${this.baseUrl}/customer/shortCode/${shortCode}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/customer`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, customer, options) {
    const response = await fetch(`${this.baseUrl}/customer/${id}`, {
      body: JSON.stringify(customer),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async setAttending(invitationCode, options) {
    const response = await fetch(`${this.baseUrl}/customer/invitation/${invitationCode}/attending`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async updateByInvitationCode(invitationCode, customer, options) {
    const response = await fetch(`${this.baseUrl}/customer/invitation/${invitationCode}`, {
      body: JSON.stringify(customer),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }


  async invite(id, options) {
    const response = await fetch(`${this.baseUrl}/customer/${id}/invite`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return response.ok
  }

  async resendInvitationCode(email, options) {
    const response = await fetch(`${this.baseUrl}/customer/forgot/${email}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return response.ok
  }

  async sendEmailTemplate(id, templateName, options) {
    const response = await fetch(`${this.baseUrl}/customer/${id}/send-email-template/${templateName}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return response.ok
  }
}