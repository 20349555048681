<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('products') }}</v-toolbar-title>
        <v-spacer />
        <v-btn-toggle mandatory v-model="selectedDeliveryPeriod" v-if="isMobile" active-class="accent">
          <v-btn>
            {{ supplier.deliveryPeriod0Tag | defaultValue($t('period1')) }}
          </v-btn>
          <v-btn>
            {{ supplier.deliveryPeriod1Tag | defaultValue($t('period2')) }}
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
      <v-form ref="form">
        <template v-for="(product, index) of products">
          <v-card-text class="py-0" v-bind:key="`${product.id}-card_text`"  v-bind:class="isHighlighted(product) ? 'blue-grey lighten-4' : null">
            <v-row align="center">
              <v-col cols="4" sm="auto" v-if="!isMobile || selectedDeliveryPeriod === 0">
                <v-tooltip right color="accent" v-bind:disabled="true">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="caption mb-1">
                      {{ supplier.deliveryPeriod0Tag | defaultValue($t('period1')) }}
                    </div>
                    <v-text-field v-bind:background-color="highlightQuantityColor(product, 0)"
                                  v-bind:dark="highlightQuantityDark(product, 0)"
                                  v-bind:rules="[ rules.validQuantity(product, 0) ]"
                                  class="quantity-field"
                                  flat
                                  hide-details="auto"
                                  solo
                                  suffix="x"
                                  type="number"
                                  v-model="product.orderQuantity[0]"
                                  v-on="on"
                                  v-bind="attrs" />
                  </template>
                  <span>{{ $t('theQuantityhasToBeAtLeast', { min: product.quantity[0] })}}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="auto" v-if="!isMobile || selectedDeliveryPeriod === 1">
                <v-tooltip right color="accent" v-bind:disabled="true">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="caption mb-1">
                      {{ supplier.deliveryPeriod1Tag | defaultValue($t('period2')) }}
                    </div>
                    <v-text-field v-bind:background-color="highlightQuantityColor(product, 1)"
                                  v-bind:dark="highlightQuantityDark(product, 1)"
                                  v-bind:rules="[ rules.validQuantity(product, 1) ]"
                                  class="quantity-field"
                                  flat
                                  hide-details="auto"
                                  solo
                                  suffix="x"
                                  type="number"
                                  v-model="product.orderQuantity[1]"
                                  v-on="on"
                                  v-bind="attrs" />
                  </template>
                  <span>{{ $t('theQuantityhasToBeAtLeast', { min: product.quantity[1] })}}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="auto" v-if="product.imageCount > 0">
                <v-menu offset-y open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img max-height="64"
                           max-width="64"
                           v-bind:src="`/images/products/${product.id}_1.jpg`"
                           v-bind="attrs"
                           v-on="on" />
                  </template>
                  <v-carousel cycle
                              hide-delimiters
                              interval="1500"
                              v-bind:continuous="true"
                              v-bind:show-arrows="false">
                    <v-carousel-item v-for="index in product.imageCount"
                                     v-bind:key="index">
                      <v-img eager max-height="512"
                             max-width="512"
                             v-bind:src="`/images/products/${product.id}_${index}.jpg`" />
                    </v-carousel-item>
                  </v-carousel>
                </v-menu>
              </v-col>
              <v-col class="text-wrap">
                <div>
                  <strong>{{ product.orderUnit != null && product.orderUnit.length !== 0 ? product.orderUnit : '' }}</strong>
                  <span class="grey--text text--darken-1"> {{ product.packagingText != null && product.packagingText.length !== 0 ? `(${product.packagingText})` : '' }}</span>
                </div>

                <div>
                  <strong v-if="product.label != null" v-bind:class="{ 'accent--text': product.label === 'NEU' }">{{ product.label }}:</strong>
                  {{ product.title }} ({{ product.id }})
                  <v-tooltip bottom
                            max-width="320"
                            v-if="product.newText2 != null && product.newText2.length !== 0">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary"
                              v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>{{ product.newText2 }}</span>
                  </v-tooltip>
                </div>

                <div class="grey--text text--darken-2">
                  {{ product.newText1 }}
                </div>
              </v-col>
              <v-col class="subtitle-1 text-right"
                     cols="12" sm="auto">
                <strong>{{ formatPrice(product.price) }}*</strong><br>
                <!--<span v-if="getDiscount(product, 0) != null" class="accent--text">{{ getDiscount(product, 0) }}</span>-->
                <span v-if="product.discount != null" class="accent--text">{{ product.discount }}</span>
              </v-col>



            </v-row>
          </v-card-text>

          <v-divider v-bind:key="`${product.id}-divider`"
                    v-if="index !== products.length - 1" />
        </template>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown
      },
      rules() {
        return {
          validQuantity: (p, d) => v => {
            if (v == null || v.length === 0 || v % 1 != 0) {
              return false
            }

            /*if (v < p.quantity[d]) {
              return false
            }*/

            if (v < 0) {
              return false
            }

            return true
          }
        }
      },
      ...mapGetters([ 'currentCustomer' ])
    },
    data: () => ({
      selectedDeliveryPeriod: 0
    }),
    methods: {
      isHighlighted(product) {
        if (this.currentCustomer == null) {
          return false
        }

        if (!this.currentCustomer.branch) {
          return false
        }

        return product.commodityGroup === '300' || product.commodityGroup === '301' || product.commodityGroup === '302'
      },
      getDiscount(product, deliveryPeriod) {
        const discount = product.discounts.find(d => d.deliveryPeriod === deliveryPeriod)

        if (discount == null) {
          return null
        }

        return discount.value
      },
      highlightQuantityColor(product, deliveryPeriod) {
        const order = product.orders.find(o => o.deliveryPeriod === deliveryPeriod)

        if (product.orderQuantity[deliveryPeriod] == null || product.orderQuantity[deliveryPeriod].length === 0 || product.orderQuantity[deliveryPeriod] % 1 != 0 || product.orderQuantity[deliveryPeriod] < 0) {
          return 'accent'
        }

        if (order != null && order.quantity !== 0) {
          if (order.quantity !== product.orderQuantity[deliveryPeriod]) {
            return 'orange'
          }

          return 'light-green'
        }

        if (product.quantity[deliveryPeriod] !== product.orderQuantity[deliveryPeriod]) {
          return 'orange'
        }

        if (order != null && order.quantity !== product.orderQuantity[deliveryPeriod]) {
          return 'orange'
        }

        return 'grey lighten-3'
      },
      highlightQuantityDark(product, deliveryPeriod) {
        const order = product.orders.find(o => o.deliveryPeriod === deliveryPeriod)

        if (product.orderQuantity[deliveryPeriod] == null || product.orderQuantity[deliveryPeriod].length === 0 || product.orderQuantity[deliveryPeriod] % 1 != 0 || product.orderQuantity[deliveryPeriod] < 0) {
          return true
        }

        if (product.quantity[deliveryPeriod] !== product.orderQuantity[deliveryPeriod]) {
          return true
        }

        if (order == null) {
          return false
        }

        return order.quantity != 0 || order.quantity !== product.orderQuantity[deliveryPeriod]
      },
      formatPrice(price) {
        if (price == null || price <= 0) {
          return 'n. v.'
        }

        return price.toLocaleString(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR'
        })
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'ProductSheetCard',
    props: {
      //deliveryPeriod: Number,
      products: Array,
      loading: {
        default: false,
        type: Boolean
      },
      supplier: Object
    }
  }
</script>

<style scoped>
  .quantity-field {
    width: 128px;
  }
</style>

<i18n>
  {
    "de": {
      "products": "Artikel",
      "theQuantityhasToBeAtLeast": "Die Menge muss mindestens {min} betragen.",
      "period1": "Zeitraum 1",
      "period2": "Zeitraum 2"
    },
    "en": {
      "products": "Products",
      "theQuantityhasToBeAtLeast": "The quanitty has to be at least {min}.",
      "period1": "Period 1",
      "period2": "Period 2"
    }
  }
</i18n>