<template>
  <div>
    <v-slide-group show-arrows="desktop">
      <v-slide-item class="pa-1"
                    v-bind:key="product.id"
                    v-for="product in products">
        <v-card outlined
                class="mr-2"
                max-width="360"
                v-bind:to="{ name: 'supplier', params: { id: product.supplierId } }">
          <v-card-text>

            <div>
              <v-chip class="mb-2"
                      color="accent"
                      small
                      dark>
                {{ product.label }}
              </v-chip>
            </div>
            <p class="text-h6 text--primary">
              {{ product.title }}
            </p>
            <div v-if="product.imageCount > 0">
              <v-img class="mx-auto"
                     max-height="200"
                     max-width="200"
                     v-bind:src="`/images/products/${product.id}_1.jpg`" />
            </div>

            <div class="accent--text" v-if="product.newText1 != null && product.newText1.length > 0">
              <strong>{{ product.newText1 }}</strong>
            </div>
            <div class="text--primary" v-if="product.newText2 != null && product.newText2.length > 0">
              {{ product.newText2 }}
            </div>
          </v-card-text>

        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
  export default {
    name: 'ProductSlider',
    props: {
      products: Array
    }
  }
</script>

<i18n>
  {
    "de": {
    },
    "en": {
    }
  }
</i18n>