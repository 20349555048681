<template>
  <div>
    <v-card outlined>
      <v-img aspect-ratio="2"
             class="align-end white--text"
             gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5)"
             position="bottom center"
             src="/images/header3.jpg">
        <v-card-title>
          <v-icon color="white"
                  left
                  x-large>
            mdi-check-circle
          </v-icon>
          <span v-if="attendance">{{ $t('yourRegistrationWasSuccessful') }}</span>
          <span v-else>{{$t('yourRegistrationWasSuccessfullyCanceled') }}</span>
        </v-card-title>
      </v-img>
      <v-card-text class="pb-2">
        <span v-if="attendance">{{ $t('attendanceText') }}</span>
        <span v-else>{{ $t('nonAttendanceText') }}</span>
      </v-card-text>
      <event-details v-bind:registration-code="participant.invitationCode"
                     v-bind:event="event"
                     v-bind:participant="participant"
                     v-if="attendance" />
      <v-card-actions class="pa-4"
                      v-if="nextStepButton != null || previousStepButton != null">
        <v-btn color="accent"
               outlined
               v-if="previousStepButton != null"
               v-on:click="previousStep">
          {{ previousStepButton }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               depressed
               v-if="nextStepButton != null"
               v-on:click="nextStep">
          {{ nextStepButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import EventDetails from '@/components/registration/EventDetails'

  export default {
    components: {
      EventDetails
    },
    methods: {
      nextStep() {
        this.$emit('next-step')
      },
      previousStep() {
        this.$emit('previous-step')
      }
    },
    name: 'CompletionCard',
    props: {
      attendance: Boolean,
      event: Object,
      nextStepButton: String,
      participant: Object,
      previousStepButton: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "attendanceText": "Wir freuen uns, Sie zu unserer Hausmesse begrüßen zu dürfen. Ihre Registrierung wurde erfolgreich gespeichert. Nachfolgend finden Sie Ihren persönlichen Registrierungscode. Mit diesem können Sie sich während der Hausmesse anmelden und Bestellungen vornehmen. Kurz vor der Messe senden wir Ihnen diesen erneut zu!",
      "nonAttendanceText": "Wir bedauern, dass Sie nicht teilnehmen können. Ihre Abmeldung haben wir erhalten. Wir würden uns jedoch ganz besonders freuen, wenn wir Sie im nächsten Jahr wieder begrüßen dürfen!",
      "yourRegistrationWasSuccessful": "Ihre Registrierung war erfolgreich",
      "yourRegistrationWasSuccessfullyCanceled": "Leider haben wir Ihre Abmeldung erhalten"
    },
    "en": {
      "attendanceText": "Wir freuen uns, Sie zu unserer Hausmesse begrüßen zu dürfen. Ihre Registrierung wurde erfolgreich gespeichert. Nachfolgend finden Sie Ihren persönlichen Registrierungscode. Mit diesem können Sie sich während der Hausmesse anmelden und Bestellungen vornehmen. Kurz vor der Messe senden wir Ihnen diesen erneut zu!",
      "nonAttendanceText": "Wir bedauern, dass Sie nicht teilnehmen können. Ihre Abmeldung haben wir erhalten. Wir würden uns jedoch ganz besonders freuen, wenn wir Sie im nächsten Jahr wieder begrüßen dürfen!",
      "yourRegistrationWasSuccessful": "Your registration was successful",
      "yourRegistrationWasSuccessfullyCanceled": "Leider haben wir Ihre Abmeldung erhalten"
    }
  }
</i18n>