<template>
  <div>
    <v-stepper class="elevation-0"
               v-model="step">
      <v-stepper-header class="elevation-0">
        <v-stepper-step step="1">
          {{ $t('eventDetails') }}
        </v-stepper-step>
        <v-divider />
        <template v-if="form.attendance">
          <v-stepper-step step="2">
            {{ $t('personalDetails') }}
          </v-stepper-step>
          <v-divider />
        </template>
        <v-stepper-step step="3">
          {{ $t('completion') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content class="pa-1"
                           step="1">
          <event-details-card ref="eventDetailsCard"
                              v-bind:event="event"
                              v-bind:form="form"
                              v-bind:next-step-button="form.attendance ? $t('next') : $t('send')"
                              v-bind:participant="supplier"
                              v-on:next-step="validateEventDetailsAndContinue">
            <v-card-text>
              <p>Wir freuen uns über Ihr Interesse an unserer Hausmesse. Im Folgenden haben Sie die Möglichkeit, sich für die Teilnahme an der Hausmesse 2025 und der Web-App zu registrieren. Mit dieser können Sie während der Messe sowohl vom PC, vom Tablet als auch von Ihrem Smartphone die erfassten Bestellmengen komfortabel einsehen.</p>
              <p>Sollten Sie nicht teilnehmen können, so lassen Sie uns dies bitte ebenfalls durch Auswahl der entsprechenden Option wissen.</p>
            </v-card-text>
          </event-details-card>
        </v-stepper-content>
        <v-stepper-content class="pa-1"
                           step="2">
          <supplier-personal-details-card ref="supplierPersonalDetailsCard"
                                          v-bind:next-step-button="$t('send')"
                                          v-bind:previous-step-button="$t('back')"
                                          v-bind:supplier="supplier"
                                          v-bind:validation-alert="supplierPersonalDetailsCardValidationAlert"
                                          v-on:next-step="validateSupplierPersonalDetailsAndComplete"
                                          v-on:previous-step="step -= 1" />
        </v-stepper-content>
        <v-stepper-content class="pa-1"
                           step="3">
          <completion-card v-bind:attendance="form.attendance"
                           v-bind:event="event"
                           v-bind:participant="supplier" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
  import CompletionCard from '@/components/registration/CompletionCard'
  import EventDetailsCard from '@/components/registration/EventDetailsCard'
  import SupplierPersonalDetailsCard from '@/components/registration/SupplierPersonalDetailsCard'

  export default {
    components: {
      CompletionCard,
      EventDetailsCard,
      SupplierPersonalDetailsCard
    },
    data: () => ({
      supplierPersonalDetailsCardValidationAlert: false,
      form: {
        attendance: true
      },
      step: 1
    }),
    methods: {
      sendAndSowCompletion() {
        this.$emit('send', {
          form: this.form,
          supplier: this.supplier
        })

        this.step = 3
      },
      validateEventDetailsAndContinue() {
        if (!this.$refs.eventDetailsCard.validate()) {
          return
        }

        if (this.form.attendance) {
          this.step += 1
        } else {
          this.sendAndSowCompletion()
        }
      },
      validateSupplierPersonalDetailsAndComplete() {
        if (!this.$refs.supplierPersonalDetailsCard.validate()) {
          this.supplierPersonalDetailsCardValidationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        this.sendAndSowCompletion()
      }
    },
    name: 'SupplierRegistrationStepper',
    props: {
      event: Object,
      supplier: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "addToCalendar": "Zum Kalender hinzufügen",
      "back": "Zurück",
      "completion": "Abschluss",
      "eventDetails": "Veranstaltungsdetails",
      "next": "Weiter",
      "personalDetails": "Personalien",
      "send": "Absenden"
    },
    "en": {
      "addToCalendar": "Add to calendar",
      "back": "Back",
      "completion": "Completion",
      "eventDetails": "Event details",
      "next": "Next",
      "personalDetails": "Personal details",
      "send": "Send"
    }
  }
</i18n>