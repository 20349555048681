<template>
  <div class="accent--text display-1">
    <span v-html="countdown"></span>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      isLive() {
        return moment(this.date).isBefore(this.now)
      },
      countdown() {
        if (this.isLive) {
          return 'Los geht\'s!'
        }

        const difference = moment(this.date).diff(this.now)

        const format = format => moment.utc(difference).format(format)

        return `${format('DD') - 1}&nbsp;${this.$t('days')} ${format('HH')}&nbsp;${this.$t('hours')} ${format('mm')}&nbsp;${this.$t('minutes')} ${format('ss')}&nbsp;${this.$t('seconds')}`
        //return `${format('HH')}&nbsp;${this.$t('hours')} ${format('mm')}&nbsp;${this.$t('minutes')} ${format('ss')}&nbsp;${this.$t('seconds')}`
      }
    },
    data: () => ({
      interval: null,
      now: new Date()
    }),
    destroyed() {
      clearInterval(this.interval)
    },
    mounted() {
      this.interval = setInterval(() => {
        this.now = new Date()
      }, 1000)
    },
    name: 'Countdown',
    props: {
      date: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "days": "Tage",
      "hours": "Std.",
      "minutes": "Min.",
      "seconds" : "Sek."
    },
    "en": {
      "days": "days",
      "hours": "hours",
      "minutes": "min.",
      "seconds" : "sec."
    }
  }
</i18n>