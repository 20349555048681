<template>
  <v-app>
    <v-navigation-drawer app
                         clipped
                         color="black"
                         class="noprint"
                         dark
                         v-bind:expand-on-hover="$vuetify.breakpoint.lgAndUp"
                         v-if="!hideNavigation"
                         v-model="navigationDrawer">
      <router-view name="navigation" />
    </v-navigation-drawer>

    <v-navigation-drawer app
                         clipped
                         class="noprint"
                         right
                         v-bind:width="290"
                         v-if="hasSecondaryNavigation">
      <router-view name="secondaryNavigation" />
    </v-navigation-drawer>

    <v-app-bar app
               clipped-left
               color="black"
               class="noprint"
               dark
               flat
               v-bind:clipped-right="hasSecondaryNavigation">
      <v-app-bar-nav-icon v-if="!hideNavigation && $vuetify.breakpoint.mdAndDown" v-on:click="navigationDrawer = !navigationDrawer" />

      <v-toolbar-title>{{ company.title }}</v-toolbar-title>

      <v-chip class="ml-2" color="accent" v-if="showTimer">{{ $t('ends', { value: endTimer }) }}</v-chip>

      <v-spacer></v-spacer>

      <locale-block v-bind:locales="locales"
                    v-on:set-locale="setLocale" />

      <v-btn v-if="$vuetify.breakpoint.mdAndUp && currentCustomer != null"
             text
             v-bind:to="{ name: 'home' }">
        <v-icon v-bind:left="$vuetify.breakpoint.mdAndUp">mdi-dolly</v-icon>
        <span class="hidden-sm-and-down text-truncate">{{ currentCustomer.id }}</span>
      </v-btn>

      <account-block v-bind:display-name="displayName"
                     v-bind:email="email"
                     v-bind:is-authenticated="isAuthenticated"
                     v-bind:picture="picture"
                     v-if="!hideSignInButton"
                     v-on:sign-in="signIn"
                     v-on:sign-out="signOut">
        <template v-if="hasRole('Administrator')">
          <v-list-item v-bind:to="{ name: 'administration-dashboard' }">
            <v-list-item-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('administration') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
        </template>
      </account-block>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer
              color="primary"
              class="noprint"
              dark
              inset>
      <v-col class="text-center"
             cols="12">
        <p class="mb-1">© 2021-2025 SAGASSER Unternehmensgruppe</p>
        <v-btn small
               text
               href="https://www.sagasser.de/impressum"
               target="_blank">
          {{ $t('legal') }}
        </v-btn>
        <v-btn small
               text
               href="https://www.sagasser.de/datenschutz"
               target="_blank">
          {{ $t('privacyPolicy') }}
        </v-btn>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'

import locales from '@/locales.json'

import AccountBlock from '@/components/common/AccountBlock'
import LocaleBlock from '@/components/common/LocaleBlock'

export default {
  components: {
    AccountBlock,
    LocaleBlock
  },
  computed: {
    showTimer() {
      if (this.tick) {}
      return moment(this.startDate).isBefore(new Date()) && moment(this.endDate).isAfter(new Date())
    },
    startDate() {
      return this.event.startDate
    },
    endDate() {
      return this.event.endDate
    },
    endTimer() {
      if (this.tick) {}
      return moment(this.endDate).locale(this.$i18n.locale).from(moment.utc(this.currentDate))
    },
    hasSecondaryNavigation() {
      const matched = this.$route.matched

      if (!matched[0] || !matched[0].meta) return false

      return matched[0].meta.hasSecondaryNavigation === true
    },
    hideSignInButton() {
      const matched = this.$route.matched

      if (!matched[0] || !matched[0].meta) return false

      return matched[0].meta.hideSignInButton === true && !this.isAuthenticated
    },
    hideNavigation() {
      const matched = this.$route.matched

      if (!matched[0] || !matched[0].meta) return false

      return matched[0].meta.hideNavigation === true
    },
    ...mapGetters({
      company: 'company',
      currentCustomer: 'currentCustomer',
      displayName: 'auth/displayName',
      email: 'auth/email',
      hasRole: 'auth/hasRole',
      isAuthenticated: 'auth/isAuthenticated',
      picture: 'auth/picture',
      isLive: 'isLive',
      event: 'event'
    })
  },
  created() {
    // TODO: Clear this interval
    setInterval(() => {
      this.tick += 1
    }, 1000)
  },
  data: () => ({
    locales,
    navigationDrawer: true,
    tick: 1
  }),
  methods: {
    setLocale({ localeId }) {
      this.$root.$i18n.locale = localeId
    },
    ...mapActions({
      signIn: 'auth/signIn',
      signOut: 'auth/signOut'
    })
  },
  name: 'App'
}
</script>

<style>
  @media print {
   .noprint {
      visibility: hidden;
   }
  }
</style>

<i18n>
  {
    "de": {
      "administration": "Administration",
      "ends": "endet {value}",
      "legal": "Impressum",
      "privacyPolicy": "Datenschutz"
    },
    "en": {
      "administration": "Administration",
      "ends": "ends {value}",
      "legal": "Legal",
      "privacyPolicy": "Privacy policy"
    }
  }
</i18n>