<template>
  <div>
    <v-card outlined>
      <v-card-title class="primary--text">{{ $t('additionalGuests') }}</v-card-title>
      <v-card-text>
        <v-checkbox color="accent"
                    class="mb-4 my-0"
                    hide-details="auto"
                    v-bind:label="$t('iWouldLikeToBringAdditionalGuestsToTheEvent')"
                    v-model="form.hasAdditionalGuests" />
        <v-form ref="form">
          <template v-if="form.hasAdditionalGuests">
            <template class="mb-4"
                      v-for="(additionalGuest, index) in form.additionalGuests">
              <v-row v-bind:key="`${index}-row1`">
                <v-col>
                  <v-text-field hide-details="auto"
                                outlined
                                v-bind:label="$t('givenName')"
                                v-bind:rules="[ rules.required ]"
                                v-model="additionalGuest.givenName" />
                </v-col>
                <v-col>
                  <v-text-field hide-details="auto"
                                outlined
                                v-bind:label="$t('familyName')"
                                v-bind:rules="[ rules.required ]"
                                v-model="additionalGuest.familyName" />
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
              <v-row v-bind:key="`${index}-row2`" align="center">
                <v-col>
                  <v-text-field hide-details="auto"
                                outlined
                                v-bind:label="$t('email')"
                                v-bind:rules="[ rules.required ]"
                                v-model="additionalGuest.email" />
                </v-col>
                <v-col cols="1">
                  <v-btn color="accent"
                         fab
                         elevation="0"
                         small
                         v-on:click="removeAdditionalGuest(index)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-btn color="accent"
                  depressed
                  v-if="form.additionalGuests.length <= 9"
                  v-on:click="addAdditionalGuest">
              {{ $t('addAnotherGuest') }}
            </v-btn>
          </template>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4"
                      v-if="nextStepButton != null || previousStepButton != null">
        <v-btn color="accent"
               outlined
               v-if="previousStepButton != null"
               v-on:click="previousStep">
          {{ previousStepButton }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               depressed
               v-if="nextStepButton != null"
               v-on:click="nextStep">
          {{ nextStepButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    components: {

    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      addAdditionalGuest() {
        this.form.additionalGuests.push({})
      },
      removeAdditionalGuest(index) {
        this.form.additionalGuests.splice(index, 1)
      },
      nextStep() {
        this.$emit('next-step')
      },
      previousStep() {
        this.$emit('previous-step')
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'AdditionalGuestsCard',
    props: {
      form: Object,
      nextStepButton: String,
      previousStepButton: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "addAnotherGuest": "Einen weiteren Gast hinzufügen",
      "additionalGuests": "Weitere Gäste",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "iWouldLikeToBringAdditionalGuestsToTheEvent": "Ich möchte weitere Gäste zur Veranstaltung mitbringen"
    },
    "en": {
      "addAnotherGuest": "Add another guest",
      "additionalGuests": "Additional guests",
      "email": "Email",
      "familyName": "Family name",
      "givenName": "Given name",
      "iWouldLikeToBringAdditionalGuestsToTheEvent": "I would like to bring additional guests to the event"
    }
  }
</i18n>