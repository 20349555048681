<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <slot>{{ message }}</slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="accent"
                 text
                 v-on:click="dismiss">
            {{ dismissButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    methods: {
      dismiss() {
        this.$emit('input', false)
        this.$emit('dismiss')
      },
      async open(resolve) {
        this.$emit('input', true)
      }
    },
    name: 'InformationDialog',
    props: {
      dismissButtonLabel: String,
      message: String,
      title: String,
      value: Boolean
    }
  }
</script>