<template>
  <div>
    <v-text-field class="mx-2"
                  hide-details
                  flat
                  prepend-inner-icon="mdi-magnify"
                  solo
                  v-model="search" />
    <v-divider />
    <v-list class="py-0">
      <template v-for="(item, index) in filteredItems">
        <v-list-item exact 
                     two-line
                     v-bind:key="`${item.id}-list_item`"
                     v-bind:to="{ name: itemEditRouteName, params: { id: item.id } }">
          <v-list-item-avatar v-if="avatar && item.icon">
            <v-img v-bind:src="item.icon" />
          </v-list-item-avatar>
          <v-list-item-avatar class="white--text"
                              color="primary"
                              v-else-if="avatar && item.code">
            <span>{{ item.code }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index !== filteredItems.length - 1"
                   v-bind:key="`${item.id}-divider`" />
      </template>
    </v-list>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredItems() {
        if (this.search == null || this.search.count === 0) {
          return this.items
        }

        const search = this.search.toLowerCase()

        return this.items.filter(i => i.title.toLowerCase().includes(search))
      }
    },
    data: () => ({
      search: null
    }),
    name: 'NavigationList',
    props: {
      avatar: Boolean,
      defaultIcon: String,
      itemEditRouteName: String,
      items: Array
    }
  }
</script>