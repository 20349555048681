<template>
  <div>
    <v-progress-linear color="accent"
                          indeterminate
                          v-bind:active="loading" />
    <div v-if="supplier != null">
      <v-snackbar dark
                  right
                  top
                  v-model="confirmationSnackbar">
        {{ $t('saved') }}
      </v-snackbar>
      <top-header v-bind:title="supplier.companyName | defaultValue($t('untitledSupplier'))">
        <v-btn dark
              exact
              slot="left-button"
              text
              v-bind:to="{ name: 'administration-suppliers' }">
          <v-icon left>mdi-chevron-left</v-icon>
          {{ $t('back') }}
        </v-btn>
        <v-btn dark
              exact
              slot="right-button"
              text
              v-bind:disabled="!hasChanges"
              v-on:click="updateSupplier">
          {{ $t('save') }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </top-header>
      <v-tabs align-with-title
              background-color="primary"
              dark
              v-model="tabs">
        <v-tab href="#common">{{ $t('common') }}</v-tab>
        <v-tab href="#design">{{ $t('design') }}</v-tab>
      </v-tabs>
      <v-alert tile
              type="error"
              v-model="validationAlert">
        {{ $t('pleaseCompleteTheForm') }}
      </v-alert>
      <v-container>
        <v-btn class="mb-4 ml-2"
              color="accent"
              depressed
              v-bind:loading="exportLoading"
              v-on:click="exportCsv">
          <v-icon left>
            mdi-file-table
          </v-icon>
          {{ $t('exportOrders') }}
        </v-btn>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="common">
            <v-row>
              <v-col cols="12"
                    lg="6">
                <supplier-edit-card ref="supplierEditCard"
                                    v-bind:edit-mode="true"
                                    v-bind:supplier="supplier" />
              </v-col>
              <v-col cols="12"
                    lg="6">
                <supplier-invitation-edit-card ref="supplierInvitationEditCard"
                                              v-bind:supplier="supplier" />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="design">
            <design-card v-bind:form="form"
                        v-bind:supplier="supplier" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </div>
</template>

<script>
  import SupplierEditCard from '@/components/administration/SupplierEditCard'
  import SupplierInvitationEditCard from '@/components/administration/SupplierInvitationEditCard'
  import TopHeader from '@/components/common/TopHeader'
  import DesignCard from '@/components/registration/DesignCard'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = to.params.id

      await this.get(id)

      next()
    },
    components: {
      DesignCard,
      SupplierEditCard,
      SupplierInvitationEditCard,
      TopHeader
    },
    async created() {
      const id = this.$route.params.id

      await this.get(id)
    },
    data: () => ({
      confirmationSnackbar: false,
      form: {},
      hasChanges: false,
      id: null,
      supplier: null,
      loading: false,
      tabs: null,
      validationAlert: false,
      exportLoading: false
    }),
    methods: {
      async get(id) {
        this.id = id

        await this.$store.dispatch('loadSuppliers')

        this.supplier = Object.assign({}, this.$store.getters.supplier(id))
      },
      async exportCsv() {
        this.exportLoading = true

        const csv = await this.$store.dispatch('exportOrdersForSupplier', { supplierId: this.id })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.exportLoading = false
      },
      async updateSupplier() {
        if (!this.$refs.supplierEditCard.validate() || !this.$refs.supplierInvitationEditCard.validate()) {
          this.validationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        await this.$store.dispatch('updateSupplier', { supplier: this.supplier })

        this.confirmationSnackbar = true
        this.hasChanges = false
      }
    },
    name: 'Supplier',
    watch: {
      supplier: {
        deep: true,
        handler(newSupplier, oldSupplier) {
          if (newSupplier == null || oldSupplier == null) {
            this.hasChanges = false
          } else if (newSupplier.id !== oldSupplier.id) {
            this.hasChanges = false
          } else {
            this.hasChanges = true
          }
        }
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "common": "Allgemein",
      "design": "Design",
      "exportOrders": "Bestellungen exportieren",
      "pleaseCompleteTheForm": "Bitte vervollständigen Sie Ihre Angaben.",
      "save": "Speichern",
      "saved": "Gespeichert.",
      "untitledSupplier": "Unbenannter Lieferant"
    },
    "en": {
      "back": "Back",
      "common": "Common",
      "design": "Design",
      "exportOrders": "Export orders",
      "pleaseCompleteTheForm": "Please complete the form.",
      "save": "Save",
      "saved": "Saved.",
      "untitledSupplier": "Unitled supplier"
    }
  }
</i18n>