<template>
  <div>
    <top-header v-bind:title="$t('about')" />
    <v-container>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quia eos maiores incidunt expedita rerum velit error perspiciatis inventore unde et tempora temporibus, recusandae at sapiente. Distinctio, adipisci? Ut, quidem.</p>
    </v-container>
  </div>
</template>

<script>
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    name: 'About'
  }
</script>

<i18n>
  {
    "de": {
      "about": "Über…"
    },
    "en": {
      "about": "About…"
    }
  }
</i18n>