<template>
  <div>
    <top-header v-bind:title="$t('help')" />
    <v-container>
      <h1>Kleine Bestellanleitung</h1>
      <h2>Allgemeines</h2>
      <p>Unsere Web-App soll Ihnen eine schnelle und übersichtliche Erfassung Ihrer Bestellmengen ermöglichen. Uns ist wichtig, dass der <strong>Zugriff von allen Endgeräten, egal ob Firmenrechner oder privates-Handy</strong> möglich ist.</p>
      <p>Weiterhin muss die <strong>Bearbeitung</strong>  nicht zwingend in einem Zug erfolgen, vielmehr können Sie <strong>über die ganze Woche</strong> verteilt die Bestellungen erfassen, ergänzen und korrigieren. Wenn möglich, sollte die Bearbeitung bereits <strong>am Wochenanfang</strong> beginnen, dann steht Ihr Außendienst, das Kunden-Service-Team und der Einzelhandels-Innendienst für Rückfragen zur Verfügung und wir haben gemeinsam genügend Zeit zu reagieren und eventuell auftauchende Fragen zu beantworten.</p>
      <p>Bei der Bestellung steht Ihnen jederzeit auf der <strong>linken Bildschirmseite</strong> ein Kontext-Menü zur Verfügung. Sobald Sie mit dem Cursor über diesen Bereich fahren, sehen Sie die Funktionen die Sie benötigen:</p>
      <ul class="mb-4">
        <li class="mb-1"><v-icon color="accent">mdi-home</v-icon> den <strong>Startbereich</strong></li>
        <li class="mb-1"><v-icon color="accent">mdi-star</v-icon> den <strong>Neuheiten</strong>-Bereich</li>
        <li class="mb-1"><v-icon color="accent">mdi-glass-wine</v-icon> den <strong>Lieferanten-Ordersatz</strong>-Bereich</li>
        <li class="mb-1"><v-icon color="accent">mdi-clipboard-list</v-icon> den <strong>Übersichtsbereich</strong></li>
        <li class="mb-1"><v-icon color="accent">mdi-help</v-icon> und den Hilfe-Bereich</li>
      </ul>
      <p>Die einzelnen Funktionsbereiche werden im Folgenden näher beschrieben.</p>
      <p>Wir hoffen, dass Sie schnell und zügig mit unserer Web-App arbeiten können und wünschen Ihnen viel Erfolg bei der Bestellung! Hier die Funktionen im Detail:</p>
      <h2>Neuheiten</h2>
      <p>Im <strong>Startbereich</strong> – noch vor der eigentlichen Anmeldung -  finden Sie <strong>zwei Übersichten</strong> mit deren Hilfe Sie sich kurz und knapp über die <strong>Neuheiten und die Messekonditionen</strong> informieren können.</p>
      <p>Nach der Anmeldung stehen Ihnen zwei Startmöglichkeiten zur Verfügung, entweder über den Knopf: „Neuigkeiten entdecken“  oder über „unsere Lieferanten“.</p>
      <p>Beim Einstieg über „<strong>Neuigkeiten entdecken</strong>“ werden Ihnen nach Lieferant sortiert <strong>nur die neuen Artikel angezeigt</strong> und Sie können über einen ausführlichen Beschreibungstext direkt erkennen, ob die Neuheit für Sie geeignet ist. Sind Sie auf der Suche nach einem bestimmten Lieferanten oder Produkt – <strong>einfach in der Zeile mit der Lupe den gesuchten Begriff eintragen</strong> – schon gefunden.</p>
      <p><strong>Bitte beachten:</strong> bei Lieferanten mit vielen Neuheiten kann mit dem <strong>> - Symbol nach rechts geblättert</strong> werden, um alle Artikel zu sehen.</p>
      <p>Wenn Sie einen Artikel anklicken, gelangen Sie in den Ordersatz des zugehörigen Lieferanten und können die Artikel bestellen. Sie können die Menge über die Tastatur eintragen oder über die Pfeiltasten mehr bzw. weniger bestellen. <strong>Mit Veränderung der Bestellmenge wird das Mengenfeld Orange</strong> – sie sehen also fortlaufend bis wohin Sie schon gekommen sind. <strong>Ein kleiner Trick für Schnellerfasser: Menge über das Tastaturfeld eingeben und mit der TAB (Tabulator)-Taste direkt ins nächste Mengenfeld springen um die nächste Zahl einzugeben.</strong></p>
      <p><strong>Bitte bestätigen Sie Ihre Eingaben über den Knopf rechts oben: „Bestellung aufgeben“.</strong></p>
      <p>Danach sehen Sie die Ordersatzübersicht.</p>
      <p>Sollten Sie <strong>weiter über die „Neuigkeiten entdecken“-Funktion arbeiten</strong> wollen, wandern Sie bitte mit dem Cursor nach links, dann klappt das Funktionsmenue auf und Sie können <strong>über die Neuheiten–Schaltfläche wieder das Neuheiten-Menü auswählen und weiterarbeiten.</strong></p>
      <p><strong>Bitte beachten:</strong> Nachdem Sie eine Bestellung gespeichert haben, wird über dem jeweiligen Produkt ein farbiges Feld mit der Wochenangabe angezeigt. Sie können also jederzeit nachverfolgen für welche Woche Sie bestellt haben.</p>
      <h2>Ordersätze</h2>
      <p>Falls Sie über den <strong>Knopf „unsere Lieferanten“</strong> einsteigen, finden Sie <strong>alle Lieferanten in alphabetischer Reihenfolge</strong>. Auch hier steht Ihnen neben dem Lupen-Symbol eine <strong>Suchfunktion</strong> zur Verfügung.</p>
      <p>Jetzt können Sie den ersten Lieferanten aufrufen, die Bestellwoche für die Sie die Mengen erfassen sehen Sie überhalb des Mengenfeldes und können dann Artikel für Artikel die gewünschten Bestellmengen eintragen – entweder über die Zahlentastatur oder über die Pfeiltasten. Ein kleiner Trick für Schnellerfasser: Menge über das Tastaturfeld eingeben und mit der TAB (Tabulator)-Taste direkt ins nächste Mengenfeld springen, um die nächste Zahl einzugeben.</p>
      <p>Haben Sie eine Woche abgeschlossen, dann bestätigen Sie Ihre Eingaben über den Knopf rechts oben: „Bestellung aufgeben“.</p>
      <p><strong>Sie befinden sich dann wieder in der Lieferanten-Übersicht und sehen, dass rechts jetzt farbig angezeigt wird, für welchen Bestellwoche Sie schon aktiv waren.</strong></p>
      <p><strong>Die Farben bedeuten:</strong></p>
      <ul class="mb-4">
        <li class="mb-1"><v-chip dark color="light-green">KW</v-chip> Bestellungen mit eigenen Mengeneintragungen wurden aufgegeben</li>
        <li class="mb-1"><v-chip dark color="orange">KW</v-chip> Bestellungen mit reinen Vorschlagsmengen wurden aufgegeben</li>
        <li class="mb-1"><v-chip dark color="accent">KW</v-chip> Bestellungen wurden erfasst und nachträglich auf Null gesetzt</li>
        <li class="mb-1">Kein Kennzeichen: Bestellung wurde noch nicht aufgegeben</li>
      </ul>
      <p>Bitte beachten: Wenn Sie einzelne Bestellmengen bereits erfasst (Mengenfeld ist Orange) und schon gespeichert haben (Mengenfeld ist Grün) können sie dies über die Farbgebung erkennen. </p>
      <p><strong>ACHTUNG: bei Zugabeartikeln (bspw. 11 + 1) bitte IMMER die vollständige Menge – also 12 eintragen. Bei der Abrechnung wird eine Einheit gutgeschrieben.</strong></p>
      <p>Wenn Sie fleißig erfasst haben und nicht mehr genau wissen was schon geschafft ist und was nicht oder wenn Sie mit Ihrer Bestellung fertig sind, wählen Sie: </p>
      <h2>Meine Bestellungen</h2>
      <p>Nachdem Sie auf diesen Funktionsbereich geklickt haben werden Ihnen <strong>Ihre Bestellungen, also alle Lieferanten und alle Artikel mit der zugehörigen Bestellwoche und der Bestellmenge in einer durchgehenden Liste angezeigt.</strong>  Auch hier steht Ihnen die bereits bekannte Suchfunktion zur Verfügung.</p>
      <p>Sollten Sie prüfen wollen, welche Lieferanten Sie bereits bestellt haben können Sie auf das Pfeilsymbol neben dem Lieferantennamen klicken, dann klappen die Artikel-Auflistungen zusammen und Sie sehen übersichtlich und kompakt die bestellten Lieferanten. </p>
      <h2>Und vor Abschluss der Messe:</h2>
      <p><strong>Bitte dokumentieren Sie für Ihre Unterlagen Ihre Bestellungen, die wir als verbindlich entgegen genommen haben</strong>, in dem Sie entweder die <strong>Funktion „Drucken“</strong> verwenden. Diese Funktion erzeugt eine Übersichts-Liste, die Sie ausdrucken können oder als .pdf-Druckdatei abspeichern können. </p>
      <p>Alternativ steht Ihnen die Funktion <strong>„Exportieren“</strong> zur Verfügung. Mit dieser Funktion wird eine <strong>.csv Datei erzeugt (siehe unten), die Sie abspeichern oder direkt in Excel öffnen können (Punkt „Öffnen mit“ muss markiert sein).</strong> </p>
      <!-- -->
      <p>Gerade die Excel-Funktionalität sollte für Sie eine gute und weiterverwendbare Dokumentationsbasis sein. </p>
      <!--<h2>Termine</h2>
      <ul class="mb-4">
        <li>Start der Hausmesse: 	Montag, 14.03.2022 um 7.00 Uhr. </li>
        <li>Ende der Hausmesse: 	Sonntag, dem 20.03.2022 um 17.00 Uhr. </li>
        <li>Bearbeitungsaufforderung:	ggf. am Donnerstag, dem 17.03.2022</li>
        <li>Erinnerungsmail:		Sonntag, dem 20.03.2022 Vormittags</li>
        <li>Abschlussmail:			Sonntag, dem 20.03.2022 nach 17 Uhr</li>
      </ul>
      <p>Für unsere eigenen Getränke-Fachmärkte stehen neben dem Kunden-Service-Center die Bereichsleitungen und der Einzelhandels-Innendienst unter den bekannten Nummern zur Verfügung.</p>
      <p>Für unserer Großhandels- und Gastronomie-Kunden stehen neben dem Kunden-Service-Center die zuständigen Außendienst-Mitarbeiter unter den bekannten Nummern zur Verfügung. </p>
      <p><strong>Am Montag, dem 21.03.2022</strong> werden die eingegangenen <strong>Bestellmengen</strong> auf Vollständigkeit und Plausibilität <strong>geprüft</strong>. Hier werden unsere Einzelhandels-BLs und die Außendienst-Mitarbeiter aktiv auf Sie zukommen, um die Bestellmengen zu finalisieren! Bitte halten Sie sich entsprechende Termine frei oder schließen Sie die Bestellung rechtzeitig in der Vorwoche ab. </p>
      <p><strong>Am Dienstag, dem 22.03.2022</strong> werden die <strong>Bestellmengen an den Einkauf / die Disposition</strong> und die Tochtergesellschaften zur Bestellung und Auslieferung verteilt. </p>
      --><h2>Rückfragen </h2>
      <p>Bitte richten Sie alle Rückfragen an unser Kunden-Service-Center unter der Durchwahl:</p>
      <p class="body-1 text-center"><strong>Tel.: +49 9561 6490-50</strong></p>
      <p>oder an Ihren zuständigen Bereichsleiter bzw. Ihren zuständigen Außendienst-Mitarbeiter. </p>
      <p>ACHTUNG: Fragestellungen oder technische Probleme, die nicht sofort gelöst werden können, leiten die internen Abteilungen bitte unter Angabe der Kunden- oder Filialnummer, eines Ansprechpartners und einer Telefonnummer an die </p>
      <p class="body-1 text-center"><strong><a href="mailto:info@messe.sagasser.de">info@messe.sagasser.de</a></strong></p>
      <p>weiter! Hier wird unser Second- und Third-Level an Lösungen und Antworten arbeiten. </p>
      <p>Wir hoffen mit dieser Beschreibung geholfen zu haben und wünschen Ihnen eine erfolgreiche und effiziente Hausmesse!</p>
      <p class="mt-8">Viel Erfolg</p>
      <p>Ihr</p>
      <p>SAGASSER Hausmesse-Team</p>
    </v-container>
  </div>
</template>

<script>
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    name: 'Help'
  }
</script>

<i18n>
  {
    "de": {
      "help": "Hilfe"
    },
    "en": {
      "help": "Help"
    }
  }
</i18n>