export default class PriceService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(price, options) {
    const response = await fetch(`${this.baseUrl}/price`, {
      body: JSON.stringify(price),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUrl}/price/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async clear(options) {
    const response = await fetch(`${this.baseUrl}/price/all`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/price/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/price`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForCustomer(customerId, options) {
    const response = await fetch(`${this.baseUrl}/price/customer/${customerId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForMe(options) {
    const response = await fetch(`${this.baseUrl}/price/me`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async getAllForShortCode(shortCode, options) {
    const response = await fetch(`${this.baseUrl}/price/shortCode/${shortCode}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async update(id, price, options) {
    const response = await fetch(`${this.baseUrl}/price/${id}`, {
      body: JSON.stringify(price),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }


  async import(supplierId, options) {
    const response = await fetch(`${this.baseUrl}/price/import/sql/${supplierId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}