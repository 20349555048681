import Vue from 'vue'
import Router from 'vue-router'

import { createAuthRouterGuard } from 'kayleen-vue-auth0-client'

import store from './store'
import About from './views/About.vue'
import SouvenirPhotos from './views/SouvenirPhotos.vue'
import Help from './views/Help.vue'
import Evaluation from './views/Evaluation.vue'
import AddCustomer from './views/administration/AddCustomer.vue'
import AddSupplier from './views/administration/AddSupplier.vue'
import Attendance from './views/administration/Attendance.vue'
import Import from './views/administration/Import.vue'
import Company from './views/administration/Company.vue'
import Customer from './views/administration/Customer.vue'
import Customers from './views/administration/Customers.vue'
import Dashboard from './views/administration/Dashboard.vue'
import Info from './views/administration/Info.vue'
import OrderAnalytics from './views/administration/OrderAnalytics.vue'
import Supplier from './views/administration/Supplier.vue'
import Suppliers from './views/administration/Suppliers.vue'
import AdministrationNavigation from './views/administration/navigation/AdministrationNavigation.vue'
import CustomerNavigation from './views/administration/navigation/CustomerNavigation.vue'
import SupplierNavigation from './views/administration/navigation/SupplierNavigation.vue'
import Home from './views/Home.vue'
import PrimaryNavigation from './views/navigation/PrimaryNavigation.vue'
import NewProducts from './views/NewProducts.vue'
import Orders from './views/Orders.vue'
import SupplierOrders from './views/SupplierOrders.vue'
import CustomerRegistration from './views/registration/CustomerRegistration.vue'
import SupplierDesign from './views/registration/SupplierDesign.vue'
import SupplierRegistration from './views/registration/SupplierRegistration.vue'
import SignInCallback from './views/SignInCallback.vue'
import SignOutCallback from './views/SignOutCallback.vue'
import SupplierPage from './views/SupplierPage.vue'
import InternalSupplierPage from './views/InternalSupplierPage.vue'
import SupplierPagePrint from './views/SupplierPagePrint.vue'
import SuppliersList from './views/SuppliersList.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      components: {
        default: About,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true
      },
      name: 'about',
      path: '/about'
    },
    // SouvenirPhotos
    {
      components: {
        default: SouvenirPhotos,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        notLive: true
      },
      name: 'souvenir-photos',
      path: '/my-photos/:supplierId'
    },
    {
      components: {
        default: Evaluation,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        notLive: true
      },
      name: 'evaluation',
      path: '/evaluation/:invitationCode'
    },
    {
      components: {
        default: Help,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true
      },
      name: 'help',
      path: '/help'
    },
    {
      components: {
        default: Home,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true
      },
      name: 'home',
      path: '/'
    },
    {
      components: {
        default: NewProducts,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        requireInvitedCustomer: true
      },
      name: 'new-products',
      path: '/new-products'
    },
    {
      components: {
        default: Orders,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        requireInvitedCustomer: true,
        notLive: true
      },
      name: 'orders',
      path: '/orders'
    },
    {
      components: {
        default: SupplierOrders,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        hideNavigation: true,
        notLive: true
      },
      name: 'supplier-orders',
      path: '/orders/:invitationCode'
    },
    {
      components: {
        default: InternalSupplierPage,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        hideNavigation: true
      },
      name: 'internal-supplier',
      path: '/orders/:invitationCode/:shortCode'
    },
    {
      components: {
        default: SupplierPage,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        requireInvitedCustomer: true
      },
      name: 'supplier',
      path: '/suppliers/:id'
    },
    {
      components: {
        default: SupplierPagePrint,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        requireInvitedCustomer: true
      },
      name: 'supplier-print',
      path: '/suppliers/:id/print'
    },
    {
      components: {
        default: SuppliersList,
        navigation: PrimaryNavigation
      },
      meta: {
        hideSignInButton: true,
        requireInvitedCustomer: true
      },
      name: 'suppliers',
      path: '/suppliers'
    },

    {
      components: {
        default: Info,
        navigation: AdministrationNavigation
      },
      name: 'administration-info',
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      path: '/administration/info'
    },
    {
      components: {
        default: Company,
        navigation: AdministrationNavigation
      },
      name: 'administration-company',
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      path: '/company'
    },
    {
      components: {
        default: Customer,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-customer',
      path: '/administration/customers/:id'
    },
    {
      components: {
        default: Customers,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-customers',
      path: '/administration/customers'
    },
    {
      components: {
        default: Dashboard,
        navigation: AdministrationNavigation
      },
      name: 'administration-dashboard',
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      path: '/administration'
    },
    {
      components: {
        default: Attendance,
        navigation: AdministrationNavigation
      },
      name: 'administration-attendance',
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      path: '/administration/attendance'
    },
    {
      components: {
        default: Import,
        navigation: AdministrationNavigation
      },
      name: 'administration-import',
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      path: '/administration/import'
    },
    {
      components: {
        default: AddCustomer,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-customer-add',
      path: '/administration/customers/add'
    },
    {
      components: {
        default: OrderAnalytics,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-order-analytics',
      path: '/administration/orderAnalytics'
    },
    {
      components: {
        default: AddSupplier,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-supplier-add',
      path: '/administration/suppliers/add'
    },
    {
      components: {
        default: Supplier,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-supplier',
      path: '/administration/suppliers/:id'
    },
    {
      components: {
        default: Suppliers,
        navigation: AdministrationNavigation
      },
      meta: {
        auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('Administrator')
      },
      name: 'administration-suppliers',
      path: '/administration/suppliers'
    },
    {
      components: {
        default: CustomerRegistration,
        navigation: PrimaryNavigation
      },
      meta: {
        //hideNavigation: true
      },
      name: 'registration-customer',
      path: '/registration/customer/:invitationCode'
    },
    {
      components: {
        default: SupplierRegistration,
        navigation: PrimaryNavigation
      },
      meta: {
        //hideNavigation: true
      },
      name: 'registration-supplier',
      path: '/registration/supplier/:invitationCode'
    },
    {
      components: {
        default: SupplierDesign,
        navigation: PrimaryNavigation
      },
      meta: {
        //hideNavigation: true
      },
      name: 'registration-supplier-design',
      path: '/registration/supplier/:invitationCode/design'
    },

    {
      components: {
        default: SignInCallback,
        navigation: PrimaryNavigation
      },
      name: 'sign-in-callback',
      path: '/sign-in-callback'
    },
    {
      components: {
        default: SignOutCallback,
        navigation: PrimaryNavigation
      },
      name: 'sign-out-callback',
      path: '/sign-out-callback'
    }
  ]
})

router.beforeEach(createAuthRouterGuard(store))

router.beforeEach(async (to, from, next) => {
  if (store.getters.currentCustomer == null) {
    await store.dispatch('loadCurrentCustomer')
  }

  if (!to.meta.requireInvitedCustomer) {
    next()

    return
  }

  if (store.getters.currentCustomer == null || (!store.getters.isLive && !to.meta.notLive)) {
    next({ name: 'home' })
  }

  next()
})

router.onError(_error => {
  router.push('/')
})

export default router