<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('orders') }}</v-toolbar-title>
        <v-text-field class="mx-4"
                      hide-details
                      flat
                      light
                      prepend-inner-icon="mdi-magnify"
                      rounded
                      solo
                      v-model="search" />
      </v-toolbar>
      <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
      <v-data-table v-bind:group-by="showCustomers ? 'customerId' : 'supplierId'"
                    v-bind:custom-filter="filter"
                    v-bind:headers="hideConditions ? headersWithoutConditions : headers"
                    v-bind:items="orders"
                    v-bind:items-per-page="itemsPerPage"
                    v-bind:no-data-text="$t('thereAreCurrentlyNoOrders')"
                    v-bind:no-results-text="$t('noOrdersWereFound')"
                    v-bind:search="search"
                    v-bind:sort-by="['supplierId', 'productId', 'readableDeliveryPeriod']">
        <template v-slot:item.actions="{ item }" v-if="!hideEdit">
          <v-btn color="accent"
                 v-if="isAdmin"
                 icon
                 small
                 v-on:click="$emit('go-to-supplier', item.supplierId )">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn color="accent"
                 v-if="isLive"
                 icon
                 small
                 v-bind:to="{ name: 'supplier', params: { id: item.supplierId } }">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.price="{ item }">
          {{ formatPrice(item.price) }}
        </template>
        <template v-slot:group.header="{ headers, isOpen, items, toggle }">
          <td v-bind:colspan="headers.length">
            <v-icon left
                    v-on:click="toggle">
              {{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
            <strong v-if="showCustomers">{{ items[0].customerDescription }}</strong>
            <strong v-else>{{ items[0].supplierFriendlyName }}</strong>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      search: null
    }),
    computed: {
      headers() {
        return [
          {
            text: this.$t('period'),
            value: 'readableDeliveryPeriod'
          },
          {
            text: this.$t('product'),
            value: 'productTitle'
          },
          {
            text: this.$t('price'),
            value: 'price'
          },
          {
            text: this.$t('discount'),
            value: 'discount'
          },
          {
            text: this.$t('quantity'),
            value: 'quantity'
          },
          {
            text: this.$t('unit'),
            value: 'packaging'
          },
          {
            text: '',
            value: 'supplierId'
          },
          {
            text: 'Kennzeichen',
            value: 'flag'
          },
          {
            sortable: false,
            text: '',
            value: 'actions'
          }
        ]
      },
      headersWithoutConditions() {
        return [
          {
            text: this.$t('period'),
            value: 'readableDeliveryPeriod'
          },
          {
            text: this.$t('product'),
            value: 'productTitle'
          },
          {
            text: this.$t('quantity'),
            value: 'quantity'
          },
          {
            text: this.$t('unit'),
            value: 'packaging'
          },
          {
            text: 'Kennzeichen',
            value: 'flag'
          },
          {
            text: '',
            value: 'supplierId'
          },
          {
            sortable: false,
            text: '',
            value: 'actions'
          }
        ]
      }
    },
    methods: {
      filter(value, search, item) {
        if (value == null) {
          return null
        }

        if (search == null) {
          return null
        }

        return value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
      },
      formatPrice(price) {
        if (price == null || price <= 0) {
          return 'n. v.'
        }

        return `${price.toLocaleString(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR'
        })}*`
      },
      print() {
        this.itemsPerPage = null
      }
    },
    name: 'OrderSummaryCard',
    props: {
      hideConditions: Boolean,
      hideEdit: Boolean,
      itemsPerPage: Number,
      orders: Array,
      loading: Boolean,
      isLive: Boolean,
      isAdmin: Boolean,
      showCustomers: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "discount": "Kondition",
      "noOrdersWereFound": "Es wurden keine Bestellungen gefunden",
      "orders": "Bestellungen",
      "period": "Zeitraum",
      "price": "Preis*",
      "product": "Artikel",
      "quantity": "Menge",
      "thereAreCurrentlyNoOrders": "Es sind noch keine Bestellungen vorhanden",
      "unit": "Einheit"

    },
    "en": {
      "discount": "Discount",
      "noOrdersWereFound": "No orders were found",
      "orders": "Orders",
      "period": "Period",
      "price": "Price",
      "product": "Product",
      "quantity": "Quantity",
      "thereAreCurrentlyNoOrders": "There are currently no orders",
      "unit": "Unit"
    }
  }
</i18n>