<template>
  <div>
    <information-dialog v-bind:dismissButtonLabel="$t('request')"
                        v-bind:title="$t('requestInvitationCode')"
                        v-model="forgotDialog"
                        v-on:dismiss="sendEmail">
      <p>Bitte geben Sie die E-Mail-Adresse ein, mit welcher Sie sich für die Messe registriert haben. Wir werden Ihnen dann den Einladungscode erneut zukommen lassen. Bitte werfen Sie auch einen Blick in den Spam-Ordner Ihres E-Mail-Postfaches.</p>
      <v-text-field class="mb-4"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-model="email" />
    </information-dialog>

    <top-header>
      <img class="logo" src="/images/logo.png">
    </top-header>
  
    <v-container class="container pa-1">
      <starting-soon-card v-bind:event="event"
                          v-on:signInCustomer="signInCustomer"
                          v-on:signOutCustomer="signOutCustomer"
                          v-bind:error="error"
                          v-bind:loading="loading"
                          v-on:forgot="forgotDialog = true" />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import StartingSoonCard from '@/components/StartingSoonCard'
  import InformationDialog from '@/components/common/InformationDialog'

  export default {
    components: {
      StartingSoonCard,
      TopHeader,
      InformationDialog
    },
    computed: {
      ...mapGetters([ 'event' ])
    },
    created() {
      if (this.$route.query.invitationCode != null) {
        if (this.$route.query.invitationCode.startsWith('CUS')) {
          this.signInCustomer(this.$route.query.invitationCode)
        } else {
          this.$router.push({ name: 'registration-supplier-design', params: { invitationCode: this.$route.query.invitationCode } })
        }
      }
    },
    data: () => ({
      error: false,
      loading: false,
      forgotDialog: false,
      email: null
    }),
    methods: {
      async sendEmail() {
        await this.$store.dispatch('resendInvitationCode', { email: this.email })

        console.log('sent email to ' + this.email)
      },
      async signInCustomer(invitationCode) {
        this.loading = true

        try {
          await this.$store.dispatch('loadCustomerByInvitationCode', { invitationCode: invitationCode })
        } catch {
          this.loading = false
          this.error = true
        }

        const customer = this.$store.getters.customerByInvitationCode(invitationCode)

        this.loading = false

        if (customer == null) {
          this.error = true
          return
        }

        if (customer.state !== 'accepted') {
          this.error = true
          return
        }

        this.error = false

        await this.$store.dispatch('setCurrentCustomer', { customer })
      },
      async signOutCustomer() {
        await this.$store.dispatch('deleteCurrentCustomer')
      }
    },
    name: 'Home'
  }
</script>

<style scoped>
  .container {
    max-width: 720px;
  }

  .logo {
    display: block;
    margin: 0 0 0 auto;

    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 80px;
  }
</style>

<i18n>
  {
    "de": {
      "startingSoon": "Es ist bald soweit…",
      "requestInvitationCode": "Einladungscode anfordern",
      "request": "Anfordern",
      "email": "E-Mail-Adresse"
    },
    "en": {
      "startingSoon": "Starting soon…",
      "requestInvitationCode": "Request invitation code",
      "request": "Request",
      "email": "Email"
    }
  }
</i18n>