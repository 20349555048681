export default class OrderService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(order, options) {
    const response = await fetch(`${this.baseUrl}/order`, {
      body: JSON.stringify(order),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async createForMe(order, options) {
    const response = await fetch(`${this.baseUrl}/order/me`, {
      body: JSON.stringify(order),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json',
        'Invitation-Code': options.invitationCode
      },
      method: 'POST'
    })

    return await response.json()
  }

  async createForShortCode(shortCode, order, options) {
    const response = await fetch(`${this.baseUrl}/order/shortCode/${shortCode}`, {
      body: JSON.stringify(order),
      headers: {
        'Content-Type': 'application/json',
        'Invitation-Code': options.invitationCode
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(customerId, deliveryPeriod, productId, options) {
    const response = await fetch(`${this.baseUrl}/order/${customerId}/${productId}/${deliveryPeriod}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async deleteForMe(deliveryPeriod, productId, options) {
    const response = await fetch(`${this.baseUrl}/order/me/${productId}/${deliveryPeriod}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(customerId, deliveryPeriod, productId, options) {
    try {
      const response = await fetch(`${this.baseUrl}/order/${customerId}/${productId}/${deliveryPeriod}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async findForMe(deliveryPeriod, productId, options) {
    try {
      const response = await fetch(`${this.baseUrl}/order/me/${productId}/${deliveryPeriod}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Invitation-Code': options.invitationCode
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async exportAll(options) {
    const response = await fetch(`${this.baseUrl}/order/export`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.text()
  }

  async exportAllForCustomer(customerId, options) {
    const response = await fetch(`${this.baseUrl}/order/customer/${customerId}/export`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.text()
  }

  async exportAllForSupplier(supplierId, options) {
    const response = await fetch(`${this.baseUrl}/order/supplier/${supplierId}/export`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.text()
  }

  async exportAllForSupplierInvitation(options) {
    const response = await fetch(`${this.baseUrl}/order/supplier/me/export`, {
      headers: {
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.text()
  }

  async exportAllForMe(options) {
    const response = await fetch(`${this.baseUrl}/order/me/export`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.text()
  }



  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/order`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForCustomer(customerId, options) {
    const response = await fetch(`${this.baseUrl}/order/customer/${customerId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForMe(options) {
    const response = await fetch(`${this.baseUrl}/order/me`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async getAllForSupplierInvitation(options) {
    const response = await fetch(`${this.baseUrl}/order/supplier/me`, {
      headers: {
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async update(customerId, deliveryPeriod, productId, order, options) {
    const response = await fetch(`${this.baseUrl}/order/${customerId}/${productId}/${deliveryPeriod}`, {
      body: JSON.stringify(order),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async updateForMe(deliveryPeriod, productId, order, options) {
    const response = await fetch(`${this.baseUrl}/order/me/${productId}/${deliveryPeriod}`, {
      body: JSON.stringify(order),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json',
        'Invitation-Code': options.invitationCode
      },
      method: 'PUT'
    })

    return response.ok
  }
}