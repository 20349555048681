<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <p>{{ $t('infoText') }}</p>
        <v-row align="center">
          <v-col>
            <v-combobox hide-details
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        v-bind:items="customerItems"
                        v-model="search"
                        v-on:input="input" />
          </v-col>
          <v-col cols="auto">
            <v-btn color="accent"
                   depressed
                   x-large
                   v-on:click="confirm"
                   v-bind:disabled="selectedCustomer == null || selectedCustomer.wasAttending"
                   v-if="selectedCustomer != null">
              <v-icon left>mdi-check</v-icon>
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color="accent"
                   depressed
                   x-large
                   v-else
                   v-on:click="input">
              <v-icon left>mdi-magnify</v-icon>
              {{ $t('search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <template v-if="selectedCustomer != null">
        <v-divider />
        <v-card-text>
          <v-alert type="warning"
                   v-if="!selectedCustomer.wasAttending">
            {{ $t('theCustomerIsNotConfirmedYet') }}
          </v-alert>
          <v-alert type="success"
                   v-else>
            {{ $t('theCustomersAttendanceWasSuccessfullyConfirmed') }}
          </v-alert>
          <p><strong>{{ $t('customerNumber') }}:</strong> {{ selectedCustomer.id }}</p>
          <p><strong>{{ $t('companyName') }}:</strong> {{ selectedCustomer.companyName }}</p>
          <p><strong>{{ $t('name') }}:</strong> {{ selectedCustomer.givenName }} {{ selectedCustomer.familyName }}</p>
          <p class="mb-0"><strong>{{  $t('guests') }}:</strong></p>
          <p class="mb-0" v-if="selectedCustomer.firstGuestFamilyName != null">
            {{ selectedCustomer.firstGuestGivenName }} {{ selectedCustomer.firstGuestFamilyName }}
          </p>
          <p class="mb-0" v-if="selectedCustomer.secondGuestFamilyName != null">
            {{ selectedCustomer.secondGuestGivenName }} {{ selectedCustomer.secondGuestFamilyName }}
          </p>
          <p class="mb-0" v-for="i in 8" v-bind:key="i" v-if="selectedCustomer['guestFamilyName' + (i + 2)] != null">
            {{ selectedCustomer['guestGivenName' + (i + 2)] }} {{ selectedCustomer['guestFamilyName' + (i + 2)] }}
          </p>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      customerItems() {
        if (this.customers == null) {
          return []
        }

        return this.customers.filter(c => c.state === 'accepted').map(customer => {
          return {
            value: customer.id,
            text: `${customer.id} ${customer.givenName} ${customer.familyName} (${customer.companyName})`
          }
        })
      }
    },
    data: () => ({
      search: null,
      selectedCustomer: null
    }),
    methods: {
      confirm() {
        if (this.selectedCustomer == null) {
          return
        }

        this.$emit('confirm', this.selectedCustomer)
      },
      input(value) {

        if (typeof value === 'object') {
          this.selectedCustomer = this.customers.find(customer => customer.id === value.value)
        } else {
          const customer = this.customers.find(c => c.invitationCode === this.search)

          if (customer != null) {
            this.selectedCustomer = customer
          } else {
            this.selectedCustomer = null
          }
        }
      }
    },
    name: 'AttendanceCard',
    props: {
      customers: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "guests": "Gäste",
      "companyName": "Firmenname",
      "confirm": "Bestätigen",
      "customerNumber": "Kundennummer",
      "name": "Name",
      "theCustomersAttendanceWasSuccessfullyConfirmed": "Die Anwesenheit des Kunden wurde erfolgreich bestätigt.",
      "theCustomerIsNotConfirmedYet": "Der Kunde ist noch nicht bestätigt.",
      "search": "Suchen",
      "infoText": "Bitte geben Sie den Einladungscode des Kunden oder dessen Namen ein."
    },
    "en": {
      "guests": "Guests",
      "companyName": "Company name",
      "confirm": "Confirm",
      "customerNumber": "Customer number",
      "name": "Name",
      "theCustomersAttendanceWasSuccessfullyConfirmed": "The customer's attendance was successfully confirmed.",
      "theCustomerIsNotConfirmedYet": "The customer is not confirmed yet.",
      "search": "Search",
      "infoText": "Please enter the customer's invitation code or name."
    }
  }
  </i18n>