<template>
  <div>
    <div class="header primary">
      <v-container class="fill-height">
        <v-row>
          <v-col cols="auto"
                 v-if="!!this.$slots['left-button']">
            <slot name="left-button" />
          </v-col>
          <v-spacer />
          <v-col cols="auto"
                 v-if="!!this.$slots['right-button']">
            <slot name="right-button" />
          </v-col>
          <v-col cols="auto"
                 v-if="!!this.$slots['menu']">
            <slot name="menu" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <slot>
              <h1 class="display-1 mx-4 white--text">{{ title }}</h1>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    props: {
      title: String
    }
  }
</script>

<style scoped>
.header {
  height: 168px;
}
</style>