<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat
                 v-bind:extended="extendedToolbar"
                 v-if="!hideTitle">
        <slot name="toolbar">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-text-field class="mx-4"
                        hide-details
                        flat
                        light
                        prepend-inner-icon="mdi-magnify"
                        rounded
                        solo
                        v-if="!hideSearch"
                        v-model="search" />
          <v-menu offset-y
                  v-bind:close-on-content-click="false"
                  v-bind:disabled="itemAddRouteName != null"
                  v-if="showRemoveButton"
                  v-model="newItemMenu">
            <template v-slot:activator="{ on }">
              <v-btn icon
                     v-bind:to="itemAddRouteName != null ? { name: itemAddRouteName } : null"
                     v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card flat
                    width="480">
              <v-form ref="newItemForm">
                <template v-for="field in newItemFields">
                  <v-text-field flat
                                hide-details="auto"
                                v-bind:placeholder="field.title"
                                solo
                                v-bind:key="`${field.name}-text_field`"
                                v-bind:rules="field.isRequired ? [ rules.required ] : []"
                                v-model="newItem[field.name]" />
                  <v-divider v-bind:key="`${field.name}-divider`" />
                </template>
                <v-btn block
                       class="black--text"
                       color="primary"
                       depressed
                       tile
                       v-on:click="addItem">
                  {{ $t('add') }}
                </v-btn>
              </v-form>
            </v-card>
          </v-menu>
        </slot>
        <template v-if="extendedToolbar"
                  v-slot:extension>
          <slot name="toolbar-extension">
            <v-tabs v-if="filter != null"
                    v-model="activeFilterValue">
              <v-tab v-bind:key="value.name"
                     v-for="value in filter.values">
                <v-icon left
                        v-if="value.icon != null">
                  {{ value.icon }}
                </v-icon>
                {{ value.title }}
                <v-chip class="ml-2"
                        outlined
                        small>
                  {{ itemCountForFilterValue(value.name) }}
                </v-chip>
              </v-tab>
            </v-tabs>
          </slot>
        </template>
      </v-toolbar>
      <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
      <v-list class="py-0"
              v-bind:two-line="twoLine"
              v-if="filteredItems.length > 0">
        <v-data-iterator v-bind:footer-props="{ disablePagination: showAll, disableItemsPerPage: showAll, itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                         v-bind:items="filteredItems"
                         v-bind:items-per-page="showAll ? -1 : 30"
                         v-bind:disable-pagination="showAll"
                         v-bind:page.sync="page">

          <template v-slot:default="props">
            <template v-for="(item, index) in props.items">
              <v-list-item v-bind:id="item.id"
                          v-bind:to="itemRouteName != null ? { name: itemRouteName, params: { id: item.id } } : null"
                          v-bind:key="`${item.id}-list_item`"
                          v-bind:class="item.grey ? [ 'grey lighten-3' ] : []">
                <v-list-item-avatar v-if="avatar && item.icon">
                  <v-img v-bind:src="item.icon" />
                </v-list-item-avatar>
                <v-list-item-avatar class="white--text"
                                    color="primary"
                                    v-else-if="avatar && item.code">
                  <span>{{ item.code }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title | defaultValue($t('untitled')) }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
                </v-list-item-content>
                <slot name="item-append"
                      v-bind:item="item" />
                <v-list-item-action v-if="itemEditRouteName != null">
                  <v-btn icon
                        v-bind:to="{ name: itemEditRouteName, params: { id: item.id } }">
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-if="showRemoveButton">
                  <v-menu left
                          nudge-left="52">
                    <template v-slot:activator="{ on, value }">
                      <v-btn icon
                            v-bind:disabled="value"
                            v-if="!item.undeletable"
                            v-on="on">
                        <v-icon color="deep-orange">mdi-minus-circle</v-icon>
                      </v-btn>
                    </template>
                    <v-card flat>
                      <v-btn block
                            color="deep-orange"
                            dark
                            depressed
                            tile
                            v-on:click="removeItem(item.id)">
                        {{ $t('confirmRemove') }}
                      </v-btn>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-bind:key="`${item.id}-divider`"
                       v-if="index < props.items.length - 1" />
            </template>
            <v-divider />
          </template>
        </v-data-iterator>
      </v-list>
      <v-card-text v-else-if="!loading">
        {{ $t('noItemsWereFound') }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredItems() {
        let items = this.items

        if (this.filter != null && this.activeFilterValue != null) {
          const activeFilterValue = this.filter.values[this.activeFilterValue]

          if (!activeFilterValue.showAll) {

            if (activeFilterValue.name === 'on-site') {
              items = items.filter(i => i.wasAttending)
            } else {
              items = items.filter(i => i.state === activeFilterValue.name)
            }


          }
        }

        if (this.search != null && this.search.count !== 0) {
          const search = this.search.toLowerCase()

          items = items.filter(i => i.title.toLowerCase().includes(search))
        }

        return items
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.required')
        }
      }
    },
    data: () => ({
      activeFilterValue: null,
      newItem: {},
      newItemMenu: false,
      search: null,
      page: null
    }),
    methods: {
      addItem() {
        if (!this.$refs.newItemForm.validate()) {
          return
        }

        this.newItemMenu = false

        this.newItem.icon = '/images/icons/Keine_Angaben.png'

        this.$emit('add-item', this.newItem)

        this.newItem = {}
        this.$refs.newItemForm.reset()
      },
      itemCountForFilterValue(name) {
        const filterValue = this.filter.values.find(v => v.name === name)

        if (filterValue == null || filterValue.showAll) {
          return this.items.length
        }

        if (name === 'on-site') {
          return this.items.filter(i => i.wasAttending).length
        }

        return this.items.filter(i => i.state === name).length
      },
      removeItem(id) {
        this.$emit('remove-item', id)
      }
    },
    name: 'ListCard',
    props: {
      avatar: Boolean,
      extendedToolbar: Boolean,
      filter: Object,
      hideSearch: Boolean,
      itemAddRouteName: String,
      itemEditRouteName: String,
      itemRouteName: String,
      items: Array,
      loading: Boolean,
      newItemFields: Array,
      showAddButton: {
        default: false,
        type: Boolean
      },
      showRemoveButton: {
        default: false,
        type: Boolean
      },
      title: String,
      twoLine: Boolean,
      hideTitle: Boolean,
      showAll: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "confirmRemove": "Löschen bestätigen",
      "noItemsWereFound": "Es sind keine Einträge vorhanden.",
      "untitled": "Unbenannt"
    },
    "en": {
      "add": "Add",
      "confirmRemove": "Confirm remove",
      "noItemsWereFound": "No items were found.",
      "untitled": "Untitled"
    }
  }
</i18n>