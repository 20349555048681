<template>
  <div v-if="theSupplier != null">
    <!--<top-header v-bind:title="$t('print')" />-->
    <v-container>
      <v-btn block
             color="accent mb-4"
             class="no-print"
             tile
             target="_blank"
             v-on:click="close" >
        <v-icon left>
          mdi-close
        </v-icon>
        {{ $t('close') }}
      </v-btn>

      <h1>{{ theSupplier.supplierGroup | defaultValue($t('supplier')) }}</h1>
      <p class="body-1" v-html="description"></p>
      <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ supplier.deliveryPeriod0Tag | defaultValue($t('period1')) }}</th>
            <th scope="col">{{ supplier.deliveryPeriod1Tag | defaultValue($t('period2')) }}</th>
            <th scope="col">{{ $t('description') }}</th>
            <th scope="col">{{ $t('price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product of products" v-bind:key="product.id">
            <td>{{ product.orderQuantity[0] }}</td>
            <td>{{ product.orderQuantity[1] }}</td>
            <td>
              <strong>{{ product.orderUnit != null && product.orderUnit.length !== 0 ? product.orderUnit : '' }}</strong>
              <strong v-if="!!product.label" v-bind:class="product.new ? 'accent--text' : null"> {{ product.label }}:</strong>
              {{ product.title }} ({{ product.id }})<br>
              <span class="grey--text text--darken-1"> {{ product.packagingText != null && product.packagingText.length !== 0 ? `(${product.packagingText})` : '' }}</span>
            </td>
            <td>
              <strong>{{ formatPrice(product.price) }}*</strong><br>
              <span v-if="getDiscount(product) != null" class="accent--text">{{ getDiscount(product, 0) }}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <strong>{{ theSupplier.supplierGroup | defaultValue($t('supplier')) }} ({{ theSupplier.id }}) – Ordersatz der SAGASSER-Hausmesse 2025</strong><br>
              Ausdruck erstellt am {{ new Date() | formatDate('LLL') }}<br>
            </td>
          </tr>
        </tfoot>
      </table>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    computed: {
      description() {
        if (this.theSupplier == null) {
          return ''
        }

        if (this.theSupplier.description == null) {
          return ''
        }

        return this.theSupplier.description.replace(/(?:\r\n|\r|\n)/g, '<br />')
      },
      theSupplier() {
        return this.supplier(this.id)
      },
      ...mapGetters([
        //'currentCustomer',
        'priceForProduct',
        'productsForSupplier',
        'supplier'
      ])
    },
    async created() {
      const id = this.$route.params.id

      await this.get(id)

      window.print()
    },
    data: () => ({
      id: null,
      loading: false,
      products: []
    }),
    methods: {
      close() {
        window.close()
      },
      async get(id) {
        this.id = id

        this.loading = true

        if (this.products.length == 0) {
          await this.$store.dispatch('loadSuppliersForMe')
          await this.$store.dispatch('loadPricesForMe')
          await this.$store.dispatch('loadProductsForMeForSupplier', { supplierId: id })
        }

        this.products = []

        this.products = [...this.productsForSupplier(this.id)].map(p => {
          let orderQuantity = p.quantity
          let otherOrderQuantity = p.quantity

          const order = p.orders.find(o => o.deliveryPeriod === 0)
          const otherOrder = p.orders.find(o => o.deliveryPeriod === 1)

          if (order != null) {
            orderQuantity = order.quantity
          }

          if (otherOrder != null) {
            otherOrderQuantity = otherOrder.quantity
          }

          return {
            ...p,
            price: this.priceForProduct(p.id),
            quantity: [ p.quantity, p.quantity ],
            orderQuantity: [ orderQuantity, otherOrderQuantity ]
          }
        })

        this.loading = false
      },
      formatPrice(price) {
        if (price == null || price <= 0) {
          return 'n. v.'
        }

        return price.toLocaleString(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR'
        })
      },
      getDiscount(product, deliveryPeriod) {
        return product.discount
      }
    },
    name: 'SupplierPagePrint'
  }
</script>

<style scoped>

  @media print {

    h1 {
      font-size: 14pt;
    }

    table, p.body-1 {
      font-size: 9pt !important;
    }

    .no-print {
      display: none;
    }
  }

  table {
    text-align: left;
    width: 100%;
  }

  th, td {
    padding: 0.1rem;
  }

  table, th, td {
    border: 1px solid #000;
    border-collapse: collapse;
  }
</style>

<i18n>
  {
    "de": {
      "supplier": "Lieferant",
      "period1": "Zeitraum 1",
      "period2": "Zeitraum 2",
      "description": "Beschreibung",
      "price": "Preis",
      "close": "Druckansicht schließen"
    },
    "en": {
      "supplier": "Supplier",
      "period1": "Period 1",
      "period2": "Period 2",
      "description": "Description",
      "price": "Price",
      "close": "Close"
    }
  }
</i18n>