<template>
  <v-list dark
          nav>

    <v-list-item exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('home') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--<v-list-item exact
                 v-bind:to="{ name: 'registration-supplier', params: { invitationCode: 'VRMI3A1H8C4CEICON94M' } }">
      <v-list-item-icon>
        <v-icon>mdi-truck</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Anm. Lieferant (Demo)</v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->

    <!--<v-list-item exact
                 v-bind:to="{ name: 'registration-supplier-design', params: { invitationCode: 'VRMI3A1H8C4CEICON94M' } }">
      <v-list-item-icon>
        <v-icon>mdi-palette</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Lieferant Design (Demo)</v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->

    <!--<v-list-item v-bind:to="{ name: 'registration-customer', params: { invitationCode: '00OF5CEQS71Y657GZNB9' } }">
      <v-list-item-icon>
        <v-icon>mdi-dolly</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Anm. Kunde (Demo)</v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->

    <v-list-item v-bind:to="{ name: 'new-products' }"
                 v-if="currentCustomer != null && isLive">
      <v-list-item-icon>
        <v-icon color="accent">mdi-star</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('newArrivals') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'suppliers' }"
                 v-if="currentCustomer != null && isLive">
      <v-list-item-icon>
        <v-icon color="accent">mdi-glass-wine</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('products') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'orders' }"
                 v-if="currentCustomer != null">
      <v-list-item-icon>
        <v-icon color="accent">mdi-clipboard-list</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('myOrders') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'help' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-help</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('help') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters([
        'currentCustomer',
        'isLive'
      ])
    },
    name: 'PrimaryNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "help": "Hilfe",
      "home": "Startseite",
      "legal": "Impressum",
      "privacyPolicy": "Datenschutzerklärung",
      "newArrivals": "Neuheiten",
      "products": "Produkte",
      "myOrders": "Meine Bestellungen"
    },
    "en": {
      "help": "Help",
      "home": "Home",
      "legal": "Legal",
      "privacyPolicy": "Privacy policy",
      "newArrivals": "New arrivals",
      "products": "Products",
      "myOrders": "My orders"
    }
  }
</i18n>