import Vue from 'vue'
import Vuex from 'vuex'

import { createAuthStoreModule } from 'kayleen-vue-auth0-client'

import AttachmentService from './api/attachment-service'
import CustomerService from './api/customer-service'
import EvaluaionService from './api/evaluation-service'
import OrderService from './api/order-service'
import PriceService from './api/price-service'
import QuantityService from './api/quantity-service'
import ProductService from './api/product-service'
import SupplierService from './api/supplier-service'
import TextSnippetService from './api/text-snippet-service'

import auth0Settings from './auth0-settings'
import settings from './settings'

const attachmentService = new AttachmentService(settings.apiUri)
const customerService = new CustomerService(settings.apiUri)
const evaluationService = new EvaluaionService(settings.apiUri)
const orderService = new OrderService(settings.apiUri)
const priceService = new PriceService(settings.apiUri)
const quantityService = new QuantityService(settings.apiUri)
const productService = new ProductService(settings.apiUri)
const supplierService = new SupplierService(settings.apiUri)
const textSnippetService = new TextSnippetService(settings.apiUri)

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    // evaluation
    async loadEvaluation({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const evaluation = await evaluationService.findForMe({ accessToken, invitationCode })

      commit('setEvaluation', { evaluation })
    },
    async createOrUpdateEvaluation({ commit, getters, rootGetters }, { evaluation }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      await evaluationService.createOrUpdateForMe(evaluation, { accessToken, invitationCode })
    },

    // textSnippets
    async loadInfoTextSnippet({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const textSnippet = await textSnippetService.find('administration', { accessToken })

      commit('setInfoTextSnippet', { textSnippet })
    },
    async updateInfoTextSnippet({ commit, rootGetters }, { textSnippet }) {
      const accessToken = rootGetters['auth/accessToken']

      await textSnippetService.update('administration', textSnippet, { accessToken })
    },



    // attachment
    async createAttachment({ rootGetters }, { data }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attachmentService.create(data, { accessToken })

      return result
    },

    // currentCustomer
    async deleteCurrentCustomer({ commit }) {
      window.localStorage.removeItem('currentCustomer')

      commit('deleteCurrentCustomer')
    },
    async loadCurrentCustomer({ commit }) {
      let customer = window.localStorage.getItem('currentCustomer')

      if (customer == null) {
        return
      }

      commit('setCurrentCustomer', { customer: JSON.parse(customer) })
    },
    async setCurrentCustomer({ commit }, { customer}) {
      let customerString = JSON.stringify(customer)
      window.localStorage.setItem('currentCustomer', customerString)

      commit('setCurrentCustomer', { customer })
    },

    // customer
    async createCustomer({ commit, rootGetters }, { customer }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await customerService.create(customer, { accessToken })

      customer.id = result.id

      commit('createCustomer', { customer })
    },
    async deleteCustomer({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await customerService.delete(id, { accessToken })

      if (result) {
        commit('deleteCustomer', { id })
      }
    },
    async inviteAllCustomers({ dispatch, getters }) {
      getters.customers.filter(c => c.state === 'open').forEach(c => {
        dispatch('inviteCustomer', { id: c.id })
      })
    },
    async inviteCustomer({ dispatch, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await customerService.invite(id, { accessToken })

      if (result) {
        dispatch('setCustomerState', {
          id,
          state: 'invited'
        })
      }
    },
    async loadCustomerByInvitationCode({ commit, getters, rootGetters }, { invitationCode }) {
      const accessToken = rootGetters['auth/accessToken']

      const customer = await customerService.findByInvitationCode(invitationCode, { accessToken })

      if (getters.customer(customer.id)) {
        commit('updateCustomer', { customer })
      } else {
        commit('createCustomer', { customer })
      }
    },
    async loadCustomerByShortCode({ commit, getters, rootGetters }, { shortCode }) {
      const accessToken = rootGetters['auth/accessToken']

      const customer = await customerService.findByShortCode(shortCode, { accessToken })

      if (getters.customer(customer.id)) {
        commit('updateCustomer', { customer })
      } else {
        commit('createCustomer', { customer })
      }

      return customer
    },
    async loadCustomers({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      if (getters.customers.length !== 0) {
        return
      }


      const customers = await customerService.getAll({ accessToken })

      commit('setCustomers', { customers })
    },
    async setCustomerState({ dispatch, getters }, { id, state }) {
      const customer = { ...getters.customer(id) }

      if (customer == null) {
        return
      }

      customer.state = state

      dispatch('updateCustomer', { customer })
    },
    async sendEmailTemplateToAllCustomers({ dispatch, getters }, { filter, templateName }) {
      getters.customers.filter(filter).forEach(c => {
        console.log(`sending ${templateName} to ${c.id}`)
        dispatch('sendEmailTemplateToCustomer', { id: c.id, templateName })
      })
    },
    async sendEmailTemplateToCustomer({ rootGetters }, { id, templateName }) {
      console.log(`sending ${templateName} to ${id}`)


      const accessToken = rootGetters['auth/accessToken']

      await customerService.sendEmailTemplate(id, templateName, { accessToken })
    },
    async resendInvitationCode({ rootGetters }, { email }) {
      const accessToken = rootGetters['auth/accessToken']

      await customerService.resendInvitationCode(email, { accessToken })
    },
    async updateCustomer({ commit, rootGetters }, { customer }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await customerService.updateByInvitationCode(customer.invitationCode, customer, { accessToken })

      if (result) {
        commit('updateCustomer', { customer })
      }
    },

    async setAttendingCustomer({ commit, rootGetters }, { customer }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await customerService.setAttending(customer.invitationCode, { accessToken })

      if (result) {
        customer.wasAttending = true
        commit('updateCustomer', { customer })
      }
    },

    // order
    async createOrderForMe({ commit, getters, rootGetters }, { order }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const result = await orderService.createForMe(order, { accessToken, invitationCode })

      commit('createOrder', { order })
    },
    async createOrderForShortCode({ commit, getters, rootGetters }, { order, invitationCode, shortCode }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await orderService.createForShortCode(shortCode, order, { invitationCode })

      commit('createOrder', { order })
    },
    async createOrdersForMe({ commit, dispatch }, { orders }) {
      for (const o of orders) {
        await dispatch('createOrderForMe', { order: o })
      }
    },
    async createOrdersForShortCode({ commit, dispatch }, { orders, invitationCode, shortCode }) {
      for (const o of orders) {
        await dispatch('createOrderForShortCode', { order: o, invitationCode, shortCode })
      }

      /*orders.forEach(o => {
        dispatch('createOrderForShortCode', { order: o, invitationCode, shortCode })
      })*/
    },
    // TODO: delete
    async exportOrders({ getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const orders = await orderService.exportAll({ accessToken })

      return orders
    },
    async exportOrdersForCustomer({ getters, rootGetters }, { customerId }) {
      const accessToken = rootGetters['auth/accessToken']

      const orders = await orderService.exportAllForCustomer(customerId, { accessToken })

      return orders
    },
    async exportOrdersForSupplier({ getters, rootGetters }, { supplierId }) {
      const accessToken = rootGetters['auth/accessToken']

      const orders = await orderService.exportAllForSupplier(supplierId, { accessToken })

      return orders
    },
    async exportOrdersForSupplierInvitation({ getters, rootGetters }, { invitationCode }) {

      const orders = await orderService.exportAllForSupplierInvitation({ invitationCode })

      return orders
    },
    async exportOrdersForMe({ getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const orders = await orderService.exportAllForMe({ accessToken, invitationCode })

      return orders
    },
    async loadOrdersForCustomer({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const orders = await orderService.getAllForCustomer(id, { accessToken })

      commit('setOrders', { orders })
    },
    async loadOrdersForMe({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const orders = await orderService.getAllForMe({ accessToken, invitationCode })

      commit('setOrders', { orders })
    },
    async loadOrdersForSupplierInvitation({ commit, getters, rootGetters }, { invitationCode }) {
      /*const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode*/

      const orders = await orderService.getAllForSupplierInvitation({ invitationCode })

      commit('setOrders', { orders })
    },
    // TODO: update

    // quantity
    async importQuantities({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      await quantityService.import({ accessToken })
    },
    async clearQuantities({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      await quantityService.clear({ accessToken })
    },

    // price
    async importPrices({ commit, rootGetters }, { supplierId }) {
      const accessToken = rootGetters['auth/accessToken']

      await priceService.import(supplierId, { accessToken })
    },
    async clearPrices({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      await priceService.clear({ accessToken })
    },
    async loadPricesForCustomer({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const prices = await priceService.getAllForCustomer(id, { accessToken })

      commit('setPrices', { prices })
    },
    async loadPricesForMe({ commit, getters, rootGetters, state }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      if (state.prices.length != 0) {
        return
      }

      const prices = await priceService.getAllForMe({ accessToken, invitationCode })

      commit('setPrices', { prices })
    },
    async loadPricesForShortCode({ commit, getters, rootGetters, state }, { shortCode, invitationCode }) {
      const prices = await priceService.getAllForShortCode(shortCode, { invitationCode })

      commit('setPrices', { prices })
    },

    // product
    // TODO: create
    // TODO: delete
    async importProducts({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      await productService.import({ accessToken })
    },
    async loadProductsForMe({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const products = await productService.getAllForMe({ accessToken, invitationCode })

      commit('setProducts', { products })
    },
    async loadProductsForMeNew({ commit, getters, rootGetters, state }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      if (state.suppiersProductNewInCache != null) {
        commit('setProducts', { products: state.suppiersProductNewInCache })

        return
      }

      const products = await productService.getAllForMeNew({ accessToken, invitationCode })

      state.suppiersProductNewInCache = products

      commit('setProducts', { products })
    },

    async loadProductsForShortCodeForSupplier({ commit, getters, rootGetters, state }, { supplierId, invitationCode, shortCode }) {
      const products = await productService.getAllForShortCodeForSupplier(shortCode, supplierId, { invitationCode })

      commit('setProducts', { products })
    },

    async loadProductsForMeForSupplier({ commit, getters, rootGetters, state }, { supplierId }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      /*let existingList = state.suppliersProductsInCache.find(s => s.id === supplierId)

      if (existingList != null) {
        commit('setProducts', { products: existingList.products })

        return
      }*/

      const products = await productService.getAllForMeForSupplier(supplierId, { accessToken, invitationCode })

      /*state.suppliersProductsInCache.push({
        id: supplierId,
        products
      })*/

      commit('setProducts', { products })
    },
    // TODO: update

    // supplier
    async createSupplier({ commit, rootGetters }, { supplier }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await supplierService.create(supplier, { accessToken })

      supplier.id = result.id

      commit('createSupplier', { supplier })
    },
    async deleteSupplier({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await supplierService.delete(id, { accessToken })

      if (result) {
        commit('deleteSupplier', { id })
      }
    },
    async inviteAllSuppliers({ dispatch, getters }) {
      getters.suppliers.filter(s => s.state === 'open').forEach(s => {
        dispatch('inviteSupplier', { id: s.id })
      })
    },
    async inviteSupplier({ dispatch, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await supplierService.invite(id, { accessToken })

      if (result) {
        dispatch('setSupplierState', {
          id,
          state: 'invited'
        })
      }
    },
    async loadSupplierByInvitationCode({ commit, getters, rootGetters }, { invitationCode }) {
      const accessToken = rootGetters['auth/accessToken']

      const supplier = await supplierService.findByInvitationCode(invitationCode, { accessToken })

      if (getters.supplier(supplier.id)) {
        commit('updateSupplier', { supplier })
      } else {
        commit('createSupplier', { supplier })
      }
    },
    async loadSuppliers({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const suppliers = await supplierService.getAll({ accessToken })

      commit('setSuppliers', { suppliers })
    },
    async loadSuppliersForMe({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']
      const invitationCode = getters.currentCustomerInvitationCode

      const suppliers = await supplierService.getAllForMe({ accessToken, invitationCode })

      commit('setSuppliers', { suppliers })
    },
    async sendEmailTemplateToAllSuppliers({ dispatch, getters }, { filter, templateName }) {
      console.log(getters.suppliers.filter(filter).length)

      getters.suppliers.filter(filter).forEach(s => {
        console.log(s.id)
        dispatch('sendEmailTemplateToSupplier', { id: s.id, templateName })
      })
    },
    async sendEmailTemplateToSupplier({ rootGetters }, { id, templateName }) {
      console.log(id)

      const accessToken = rootGetters['auth/accessToken']

      await supplierService.sendEmailTemplate(id, templateName, { accessToken })
    },
    async setSupplierState({ dispatch, getters }, { id, state }) {
      const supplier = { ...getters.supplier(id) }

      if (supplier == null) {
        return
      }

      supplier.state = state

      dispatch('updateSupplier', { supplier })
    },
    async updateSupplier({ commit, rootGetters }, { supplier }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await supplierService.updateByInvitationCode(supplier.invitationCode, supplier, { accessToken })

      if (result) {
        commit('updateSupplier', { supplier })
      }
    }
  },
  getters: {
    evaluation: state => state.evaluation,
    infoTextSnippet: state => state.infoTextSnippet,
    isLive: (state, getters, rootState, rootGetters) => {
      if (rootGetters['auth/hasRole']('Administrator')) {
        return true
      }

      const now = new Date()

      return now >= Date.parse(state.event.startDate) && now <= Date.parse(state.event.endDate)

    },
    company: state => state.company,
    currentCustomer: state => state.currentCustomer,
    currentCustomerInvitationCode: state => {
      if (state.currentCustomer == null) {
        return null
      }

      return state.currentCustomer.invitationCode
    },
    customer: state => id => state.customers.find(c => c.id === id),
    customerByInvitationCode: state => invitationCode => state.customers.find(c => c.invitationCode === invitationCode),
    //customerId: state => state.customerId, // TODO: Change
    customerOrderType: state => state.customerOrderType, // TODO: Change
    customers: state => state.customers,
    event: state => state.event,
    orders: state => state.orders,
    priceForProduct: state => productId => {
      if (state.currentCustomer == null) {
        return 0
      }

      const price = state.prices.find(p => p.productId === productId && p.customerId === state.currentCustomer.id)

      if (price == null) {
        return 0
      }

      return price.value
    },
    priceForProductAndCustomer: state => (productId, customerId) => {
      const price = state.prices.find(p => p.productId === productId && p.customerId === customerId)

      if (price == null) {
        return 0
      }

      return price.value
    },
    product: state => id => state.products.find(p => p.id === id),
    products: state => state.products,
    productsForSupplier: state => supplierId => state.products.filter(p => p.supplierId === supplierId),
    productTitle: state => id => {
      const product = state.products.find(p => p.id === id)

      if (product == null) {
        return null
      }

      return product.title
    },
    supplier: state => id => state.suppliers.find(s => s.id === id),
    supplierByInvitationCode: state => invitationCode => state.suppliers.find(s => s.invitationCode === invitationCode),
    suppliers: state => state.suppliers,
    suppliersForOrderType: state => orderType => state.suppliers, // TODO: Implement functionality
    supplierCompanyName: state => id => {
      const supplier = state.suppliers.find(s => s.id === id)

      if (supplier == null) {
        return null
      }

      return supplier.companyName
    }
  },
  modules: {
    auth: createAuthStoreModule(auth0Settings)
  },
  mutations: {
    // evaluation
    setEvaluation(state, { evaluation }) {
      state.evaluation = evaluation
    },

    // currentCustomer
    deleteCurrentCustomer(state) {
      state.currentCustomer = null
    },
    setCurrentCustomer(state, { customer }) {
      state.products = []
      state.prices = []
      state.currentCustomer = customer
    },

    // customer
    createCustomer(state, { customer }) {
      state.customers.push(customer)
    },
    deleteCustomer(state, { id }) {
      const index = state.customers.findIndex(c => c.id === id)

      if (index !== -1) {
        state.customers.splice(index, 1)
      }
    },
    setCustomers(state, { customers }) {
      state.customers = customers
    },
    updateCustomer(state, { customer }) {
      const index = state.customers.findIndex(c => c.id === customer.id)

      Vue.set(state.customers, index, customer)
    },

    // order
    createOrder(state, { order }) {
      state.orders.push(order)
    },
    deleteOrder(state, { customerId, deliveryPeriod, productId }) {
      const index = state.suppliers.findIndex(s => s.customerId === customerId && s.deliveryPeriod === deliveryPeriod && s.productId === productId)

      if (index !== -1) {
        state.suppliers.splice(index, 1)
      }
    },
    setOrders(state, { orders }) {
      state.orders = orders
    },
    updateOrder(state, { order }) {
      const index = state.orders.findIndex(o => o.customerId === order.customerId && o.deliveryPeriod === order.deliveryPeriod && o.productId === order.productId)

      Vue.set(state.orders, index, order)
    },

    // price
    setPrices(state, { prices }) {
      state.prices = prices
    },

    // product
    // TODO: create
    // TODO: delete
    setProducts(state, { products }) {
      state.products = products
    },
    // TODO: update

    // supplier
    createSupplier(state, { supplier }) {
      state.suppliers.push(supplier)
    },
    deleteSupplier(state, { id }) {
      const index = state.suppliers.findIndex(s => s.id === id)

      if (index !== -1) {
        state.suppliers.splice(index, 1)
      }
    },
    setSuppliers(state, { suppliers }) {
      state.suppliers = suppliers
    },
    updateSupplier(state, { supplier }) {
      const index = state.suppliers.findIndex(s => s.id === supplier.id)

      Vue.set(state.suppliers, index, supplier)
    },

    // textSnippets
    setInfoTextSnippet(state, { textSnippet }) {
      state.infoTextSnippet = textSnippet
    },

    deleteSupplierInCache(state, { id }) {
      console.log(id)
      const index = state.suppliersProductsInCache.findIndex(s => s.id === id)

      if (index !== -1) {
        state.suppliersProductsInCache.splice(index, 1)
      }
    },
  },
  state: {
    company: {
      title: 'SAGASSER Hausmesse 2025'
    },
    currentCustomer: null,
    //currentCustomerInvitationCode: null, // TODO: Load
    customers: [],
    event: {
      title: 'Hausmesse 2025',
      startDate: '2025-03-17T10:00:00',
      endDate: '2025-03-23T23:59:00',
      siteStartDate: '2025-03-23T10:00',
      siteEndDate: '2025-03-23T17:00'
    },
    orders: [],
    prices: [],
    products: [],
    suppliers: [],
    suppliersProductsInCache: [],
    suppiersProductNewInCache: null,
    infoTextSnippet: null,
    evaluation: null
  }
})