<template>
  <div>
    <top-header v-bind:title="$t('import')" />

    <v-container>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-progress-linear color="accent"
                        v-bind:active="loading"
                        v-bind:value="loadingPercent" />
            <v-card-text>
              <p class="mb-1">{{ currentActionText }}</p>
              <p v-if="loading">Verbleibende Gesamtzeit: <strong v-if="remainingTime != null">{{  remainingTimeFormatted }}</strong><strong v-else>Berechne…</strong></p>
              <br>
              <p v-if="doPrices">Dieser Vorgang kann <strong>bis zu 2 Stunden</strong> dauern. Die Mengenvorgaben/Preise werden während des Imports möglicherweise nicht in der App angezeigt. Es wird daher empfohlen, ihn nicht im laufenden Messebetrieb durchzuführen.</p>
              <p class="accent--text"><strong>Bitte lassen Sie dieses Fenster während des Importvorgangs geöffnet!</strong></p>
            </v-card-text>
            <v-card-text>
              <v-checkbox v-model="doQuantites"  label="Mengen laden" hide-details></v-checkbox>
              <v-checkbox v-model="doPrices"  label="Preise laden" hide-details></v-checkbox>
              <v-checkbox class="mt-8" v-model="checkbox"  label="Ich habe die Hinweise gelesen und verstanden." hide-details></v-checkbox>
            </v-card-text>
            <v-card-text class="accent--text" v-if="errorLog != ''">
              <p class="mb-0" v-html="errorLog"></p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="accent"
                     depressed
                     v-on:click="start"
                     v-bind:disabled="!checkbox || loading">
                Datenimport starten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  //import AttendanceCard from '@/components/administration/AttendanceCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      //AttendanceCard,
      TopHeader
    },
    computed: {
      remainingTimeFormatted() {
        const minutes = Math.floor(this.remainingTime / 60)
        const seconds = Math.floor(this.remainingTime % 60)

        return `${minutes} Minuten und ${seconds} Sekunden`
      },

      ...mapGetters({
        suppliers: 'suppliers',
      })
    },
    async created() {
      //await this.loadCustomers()
    },
    data: () => ({
      currentActionText: 'Bitte starten Sie den Datenimport.',
      loading: false,
      loadingPercent: 0,
      remainingTime: null,
      errorLog: '',
      checkbox: false,
      doQuantites: false,
      doPrices: false
    }),
    methods: {
      async start() {
        this.loading = true

        let index = 1
        let avgSecondsPerSupplier = 50

        this.currentActionText = 'Lieferanten werden geladen…'
        await this.loadSuppliers()
        this.loadingPercent = 2

        this.remainingTime = 10

        if (this.doQuantites) {
          this.remainingTime += 30
        }

        if (this.doPrices) {
          this.remainingTime += this.suppliers.length * avgSecondsPerSupplier
        }

        this.currentActionText = 'Artikel-Basisdaten werden importiert…'
        await this.importProducts()
        this.loadingPercent = 4

        this.remainingTime -= 10

        if (this.doQuantites) {
          this.currentActionText = 'Bestehende Mengen werden gelöscht…'
          await this.clearQuantities()
          this.loadingPercent = 6

          this.currentActionText = 'Mengenvorgaben werden geladen…'
          await this.importQuantities()
          this.loadingPercent = 8

          this.remainingTime -= 30
        }

        if (this.doPrices) {
          this.currentActionText = 'Bestehende Preise werden gelöscht…'
          await this.clearPrices()
          this.loadingPercent = 10

          for (const supplier of this.suppliers) {
            const start = Date.now()

            this.currentActionText = `Preise für ${supplier.companyName} (${supplier.id}) werden importiert… (${index}/${this.suppliers.length})`

            try {
              await this.importPrices({ supplierId: supplier.id })
            } catch (e) {
              this.errorLog += `Fehler beim Import der Preise für <strong>${supplier.companyName} (${supplier.id})</strong>. (Kundennr./Artikelnr.-Kombination möglicherweise nicht eindeutig.)<br>`
            }

            const end = Date.now()

            this.loadingPercent = 10 + (90 / this.suppliers.length) * index
            avgSecondsPerSupplier = ((end - start) / 1000 + avgSecondsPerSupplier) / 2

            this.remainingTime = (this.suppliers.length - index) * avgSecondsPerSupplier

            index++
          }
        }

        this.loadingPercent = 100

        this.currentActionText = 'Datenimport erfolgreich abgeschlossen.'

        this.loading = false
        this.remainingTime = null
      },

      ...mapActions({
        loadSuppliers: 'loadSuppliers',
        importProducts: 'importProducts',
        importPrices: 'importPrices',
        clearPrices: 'clearPrices',
        importQuantities: 'importQuantities',
        clearQuantities: 'clearQuantities'
      })
    },
    name: 'Import'
  }
</script>

<i18n>
{
  "de": {
    "import": "Datenimport"
  },
  "en": {
    "import": "Import"
  }
}
</i18n>