<template>
  <div v-if="supplier != null">
    <v-snackbar dark
                right
                top
                color="light-green"
                v-model="confirmationSnackbar">
      {{ $t('saved') }}
    </v-snackbar>
    <information-dialog v-bind:dismissButtonLabel="$t('ok')"
                        v-bind:title="$t('customizeYourDesign')"
                        v-model="informationDialog">
      <p>Im Folgenden haben Sie die Möglichkeit Ihren Ordersatz zu individualisieren. Hierzu können Sie ein Logo, ein Hintergrundbild und einen Text bereitstellen. Bitte verwenden Sie nur Text- und Bildelemente von Produkten, die in ihrem Messeordner freigegeben sind. Wir behalten uns vor, abweichende Darstellungen zu entfernen. Der im Beispiel gezeigte Ordersatz dient nur zur Illustration! Um Ihnen eine möglichst gute Präsentation zu garantieren, haben wir einige Empfehlungen für Sie:</p>
      <p><strong>Logo</strong>: Stellen Sie Ihr Logo als PNG-Datei mit transparentem Hintergrund und einer Höhe von 320 Pixeln bereit. Die maximale Dateigröße sollte 100 kb nicht überschreiten.</p>
      <p><strong>Hintergrundbild</strong>: Stellen Sie Ihr Hintergrundbild als JPEG-Datei mit einer Mindestgröße von 1200 x 300 Pixeln bereit. Das Hintergrundbild sollte keine Schrift, Logos o. Ä. enthalten, da es je nach Bildschirmgröße möglicherweise abgeschnitten werden kann. Die maximale Dateigröße sollte 300 kb nicht überschreiten.</p>
      <p><strong>Text</strong>: Für den Text empfehlen wir eine Länge von 50–100 Worten.</p>
    </information-dialog>
    <top-header v-bind:title="$t('design')">
      <div slot="right-button">
        <v-btn dark
               text
               v-on:click="showHelp">
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
        <v-btn dark
               text
               v-on:click="updateSupplier">
          {{ $t('save') }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </div>
    </top-header>
    <v-container class="container pa-1">
      <v-row>
        <v-col>
          <design-card v-bind:form="form"
                       v-bind:supplier="supplier" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import InformationDialog from '@/components/common/InformationDialog'
  import TopHeader from '@/components/common/TopHeader'
  import DesignCard from '@/components/registration/DesignCard'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationCode = to.params.invitationCode

      await this.get(invitationCode)

      next()
    },
    components: {
      DesignCard,
      InformationDialog,
      TopHeader
    },
    async created() {
      const invitationCode = this.$route.params.invitationCode

      await this.get(invitationCode)

      this.informationDialog = true
    },
    data: () => ({
      form: {},
      informationDialog: false,
      invitationCode: null,
      supplier: null,
      confirmationSnackbar: false
    }),
    methods: {
      async get(invitationCode) {
        this.invitationCode = invitationCode

        await this.$store.dispatch('loadSupplierByInvitationCode', { invitationCode })

        const supplier = this.$store.getters.supplierByInvitationCode(invitationCode)

        if (supplier == null) {
          this.$router.push({ name: 'home' })
        }

        if (supplier.state !== 'accepted') {
          this.$router.push({ name: 'registration-supplier', params: { invitationCode } })
        }

        this.supplier = Object.assign({}, supplier)
      },
      async showHelp() {
        this.informationDialog = true
      },
      async updateSupplier() {
        await this.$store.dispatch('updateSupplier', { supplier: this.supplier })

        this.confirmationSnackbar = true

       // this.$router.push({ name: 'home' })
      }
    },
    name: 'SupplierDesign'
  }
</script>

<style scoped>

</style>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "customizeYourDesign": "Passen Sie Ihr Design an",
      "design": "Design",
      "ok": "Ok",
      "save": "Speichern",
      "saved": "Vielen Dank! Ihr Design wurde gespeichert."
    },
    "en": {
      "back": "Back",
      "customizeYourDesign": "Customize your design",
      "design": "Design",
      "ok": "Ok",
      "save": "Save",
      "saved": "Thank you! Your design has been saved."
    }
  }
</i18n>