<template>
  <div>
    <list-card avatar
               hide-search
               hide-title
               show-all
               item-route-name="supplier"
               two-line
               v-bind:items="items"
               v-bind:loading="loading"
               v-bind:title="$t('suppliers')">
      <template v-slot:item-append="{ item }">
        <v-chip dark
                v-bind:color="getBadge0Color(item)"
                v-if="item.hasDeliveryPeriod0Orders">
          {{ item.deliveryPeriod0Tag | defaultValue($t('period1')) }}
        </v-chip>
        <v-chip class="ml-1"
                dark
                v-bind:color="getBadge1Color(item)"
                v-if="item.hasDeliveryPeriod1Orders">
          {{ item.deliveryPeriod1Tag | defaultValue($t('period2')) }}
        </v-chip>
      </template>
    </list-card>
  </div>
</template>

<script>
  import ListCard from '@/components/common/ListCard'

  export default {
    components: {
      ListCard
    },
    computed: {
      items() {
        return [...this.suppliers].map(s => ({
          ...s,
          title: s.supplierGroup ?? s.companyName,
          grey: s.id == this.lastSupplierId
        }))
      }
    },
    methods: {
      getBadge1Color(item) {
        if (item.hasDeliveryPeriod1OrdersNonStandard) {
          return 'light-green'
        }

        if (item.hasDeliveryPeriod1OrdersNonNull) {
          return 'orange'
        }

        if (item.hasDeliveryPeriod1Orders) {
          return 'accent'
        }

        return null
      },
      getBadge0Color(item) {
        if (item.hasDeliveryPeriod0OrdersNonStandard) {
          return 'light-green'
        }

        if (item.hasDeliveryPeriod0OrdersNonNull) {
          return 'orange'
        }

        if (item.hasDeliveryPeriod0Orders) {
          return 'accent'
        }
      }
    },
    name: 'SupplierPresentationList',
    props: {
      suppliers: Array,
      lastSupplierId: String,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "ordered": "Bestellt",
      "period1": "Zeitraum 1",
      "period2": "Zeitraum 2",
      "suppliers": "Lieferanten"
    },
    "en": {
      "ordered": "Ordered",
      "period1": "Period 1",
      "period2": "Period 2",
      "suppliers": "Suppliers"
    }
  }
</i18n>