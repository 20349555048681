<template>
  <div>
    <top-header v-bind:title="$t('addSupplier')">
      <v-btn dark
             exact
             slot="left-button"
             text
             v-bind:to="{ name: 'administration-suppliers' }">
        <v-icon left>mdi-chevron-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn dark
             exact
             slot="right-button"
             text
             v-on:click="createSupplier">
        {{ $t('add') }}
        <v-icon right>mdi-check</v-icon>
      </v-btn>
    </top-header>
    <v-alert tile
             type="error"
             v-model="validationAlert">
      {{ $t('pleaseCompleteTheForm') }}
    </v-alert>
    <v-container>
      <v-row>
        <v-col>
          <supplier-edit-card ref="supplierEditForm"
                              v-bind:supplier="supplier" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import SupplierEditCard from '@/components/administration/SupplierEditCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      SupplierEditCard,
      TopHeader
    },
    data: () => ({
      supplier: {
        state: 'open'
      },
      validationAlert: false
    }),
    methods: {
      async createSupplier() {
        if (!this.$refs.supplierEditForm.validate()) {
          this.validationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        await this.$store.dispatch('createSupplier', { supplier: this.supplier })

        this.$router.push({ name: 'administration-supplier', params: { id: this.supplier.id } })
      }
    },
    name: 'AddSupplier'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "addSupplier": "Lieferant hinzufügen",
      "back": "Zurück",
      "pleaseCompleteTheForm": "Bitte vervollständigen Sie Ihre Angaben."
    },
    "en": {
      "add": "Add",
      "addSupplier": "Add supplier",
      "back": "Back",
      "pleaseCompleteTheForm": "Please complete the form."
    }
  }
</i18n>