<template>
  <div>
    <top-header v-bind:title="$t('dashboard')" />
    <v-container>
      <div class="mt-4 text-center">
        <v-progress-circular color="accent"
                             indeterminate
                             v-bind:size="50"
                             v-if="loading" />
      </div>
      <v-row v-if="!loading">
        <v-col>
          <dashboard-card v-bind:subtitle="$t('customersThatAcceptedTheInvitation')"
                          v-bind:title="$t('participatingCustomers')"
                          v-bind:to="{ name: 'administration-customers' }"
                          v-bind:totalValue="customerCountLabel"
                          v-bind:value="acceptedCustomerCountLabel" />

          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('customersThatHavePlacedOrders')"
                          v-bind:title="$t('customersWithOrders')"
                          v-bind:to="{ name: 'administration-order-analytics' }"
                          v-bind:totalValue="acceptedCustomerCountLabel"
                          v-bind:value="customerWithOrdersCountLabel" />

          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('importantInformation')"
                          v-bind:title="$t('information')"
                          v-bind:to="{ name: 'administration-info' }">
            {{ infoTextSnippet.text | truncate(100) }} (<span class="accent--text">{{ $t('readMore') }}</span>)
          </dashboard-card>

          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('attendanceSubtitle')"
                          v-bind:title="$t('attendance')"
                          v-bind:totalValue="acceptedCustomerCountLabel"
                          v-bind:value="customerOnSiteCountLabel"
                          v-bind:to="{ name: 'administration-attendance' }">
          </dashboard-card>
        </v-col>
        <v-col>
          <dashboard-card v-bind:subtitle="$t('acceptedSuppliers')"
                          v-bind:title="$t('acceptedSuppliers')"
                          v-bind:to="{ name: 'administration-suppliers' }"
                          v-bind:totalValue="supplierCountLabel"
                          v-bind:value="acceptedSuppliersCountLabel" />

          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('suppliersThatHaveSetUpTheirDesign')"
                          v-bind:title="$t('suppliersWithDesign')"
                          v-bind:to="{ name: 'administration-suppliers' }"
                          v-bind:totalValue="supplierCountLabel"
                          v-bind:value="supplierWithDesignCountLabel" />

          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('branchesThatHavePlacedOrders')"
                          v-bind:title="$t('branchesWithOrders')"
                          v-bind:to="{ name: 'administration-order-analytics' }"
                          v-bind:totalValue="branchesCountLabel"
                          v-bind:value="branchesWithOrdersCountLabel" />


          <dashboard-card class="mt-8"
                          v-bind:subtitle="$t('importSubtitle')"
                          v-bind:title="$t('import')"
                          v-bind:to="{ name: 'administration-import' }">
          </dashboard-card>

          <!--<dashboard-card class="mt-8"
                          v-bind:subtitle="$t('estimatedTotalSpendPerCustomer')"
                          v-bind:title="$t('averageTotalPerCustomer')"
                          v-bind:to="{ name: 'administration-customers' }"
                          v-bind:value="estimatedTotalPerCustomer" />-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DashboardCard from '@/components/common/DashboardCard'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      DashboardCard,
      TopHeader
    },
    computed: {
      customerOnSiteCountLabel() {
        const count = this.customers.filter(c => c.wasAttending).length

        return `${count}`
      },
      acceptedCustomerCountLabel() {
        const count = this.customers.filter(c => c.state === 'accepted').length

        return `${count}`
      },
      branchesCountLabel() {
        return `${this.customers.filter(c => c.branch).length}`
      },
      branchesWithOrdersCountLabel() {
        const count = this.customers.filter(c => c.branch && c.orderCount > 0).length

        return `${count}`
      },
      customerCountLabel() {
        return `${this.customers.length}`
      },
      customerWithOrdersCountLabel() {
        const count = this.customers.filter(c => c.orderCount > 0).length

        return `${count}`
      },
      estimatedTotalPerCustomer() {
        return "500 €"
      },
      supplierCountLabel() {
        return `${this.suppliers.length}`
      },
      supplierWithDesignCountLabel() {
        const count = this.suppliers.filter(c => c.backgroundId != null || c.logoId != null || c.description != null).length

        return `${count}`
      },
      acceptedSuppliersCountLabel() {
        const count = this.suppliers.filter(c => c.state === 'accepted').length

        return `${count}`
      },
      ...mapGetters([
        'customers',
        'suppliers',
        'infoTextSnippet'
      ])
    },
    async created() {
      this.loading = true
      await this.$store.dispatch('loadCustomers')
      await this.$store.dispatch('loadSuppliers')
      await this.$store.dispatch('loadInfoTextSnippet')
      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    name: 'Dashboard'
  }
</script>

<i18n>
  {
    "de": {
      "attendance": "Einlass",
      "attendanceSubtitle": "Einlasskontrolle",
      "acceptedSuppliers": "Akzeptierte Lieferanten",
      "information": "Informationen",
      "importantInformation": "Wichtige Informationen für Administratoren",
      "averageTotalPerCustomer": "Ø Gesamtsumme pro Kunde",
      "branchesThatHavePlacedOrders": "Filialen, die Bestellungen aufgegeben haben",
      "branchesWithOrders": "Filialen mit Bestellungen",
      "customersThatAcceptedTheInvitation": "Kunden, die die Einladung angenommen haben",
      "customersThatHavePlacedOrders": "Kunden, die Bestellungen aufgegeben haben",
      "customersWithOrders": "Kunden mit Bestellungen",
      "dashboard": "Dashboard",
      "estimatedTotalSpendPerCustomer": "Erwartete Gesamtsumme pro Kunde im Durchschnitt",
      "participatingCustomers": "Teilnehmende Kunden",
      "suppliersThatHaveSetUpTheirDesign": "Lieferanten, die ihr Design erstellt haben",
      "suppliersWithDesign": "Lieferanten mit Design",
      "readMore": "Mehr lesen…",
      "import": "Datenimport",
      "importSubtitle": "Stammdaten aus Datenbank importieren"
    },
    "en": {
      "attendance": "Attendance",
      "attendanceSubtitle": "Attendance control",
      "acceptedSuppliers": "Accepted suppliers",
      "information": "Information",
      "importantInformation": "Inportant information for administrators",
      "averageTotalPerCustomer": "Ø Total per customer",
      "branchesThatHavePlacedOrders": "Branches that have placed orders",
      "branchesWithOrders": "Branches with orders",
      "customersThatAcceptedTheInvitation": "Customers, that accepted the invitation",
      "customersThatHavePlacedOrders": "Customers that have placed orders",
      "customersWithOrders": "Customers with orders",
      "dashboard": "Dashboard",
      "estimatedTotalSpendPerCustomer": "Estimated total spend per customer",
      "participatingCustomers": "Participating customers",
      "suppliersThatHaveSetUpTheirDesign": "Suppliers, that have set up their design",
      "suppliersWithDesign": "Suppliers with design",
      "readMore": "Read more…",
      "import": "Import",
      "importSubtitle": "Import master data from database"
    }
  }
</i18n>