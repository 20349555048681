export default class ProductService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(product, options) {
    const response = await fetch(`${this.baseUrl}/product`, {
      body: JSON.stringify(product),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUrl}/product/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/product/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/product`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForCustomer(customerId, options) {
    const response = await fetch(`${this.baseUrl}/product/customer/${customerId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForMe(options) {
    const response = await fetch(`${this.baseUrl}/product/me`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async getAllForMeNew(options) {
    const response = await fetch(`${this.baseUrl}/product/me/new`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async getAllForMeForSupplier(supplierId, options) {
    const response = await fetch(`${this.baseUrl}/product/me/supplier/${supplierId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async getAllForShortCodeForSupplier(shortCode, supplierId, options) {
    const response = await fetch(`${this.baseUrl}/product/shortCode/${shortCode}/supplier/${supplierId}`, {
      headers: {
        'Invitation-Code': options.invitationCode
      }
    })

    return await response.json()
  }

  async update(id, product, options) {
    const response = await fetch(`${this.baseUrl}/product/${id}`, {
      body: JSON.stringify(product),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }


  async import(options) {
    const response = await fetch(`${this.baseUrl}/product/import/sql`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}