<template>
  <div>
    <v-card hover
            rounded
            v-bind:to="to" >
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="accent--text headline">
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <slot>
          <v-row align="center">
            <v-col>
              <span class="accent--text display-3">{{ value }}</span><span class="display-1" v-if="totalValue != null">/{{ totalValue}}</span>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'DashboardCard',
    props: {
      subtitle: String,
      title: String,
      to: Object,
      totalValue: String,
      value: String
    }
  }
</script>