<template>
  <div>
    <v-card outlined>
      <v-toolbar absolute
                 class="ml-2 mt-2"
                 dense
                 floating>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text
                   v-bind="attrs"
                   v-on="on">
              <v-icon left>mdi-pencil</v-icon>
              {{ $t('edit') }}
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item v-if="!hasBackground"
                          v-on:click="chooseBackground">
              <v-list-item-title>{{ $t('addBackground') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else
                          v-on:click="removeBackground">
              <v-list-item-title>{{ $t('removeBackground') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hasLogo" v-on:click="chooseLogo">
              <v-list-item-title>{{ $t('addLogo') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else
                          v-on:click="removeLogo">
              <v-list-item-title>{{ $t('removeLogo') }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-action>
                <v-checkbox hide-details="auto"
                            v-model="supplier.darkBackground" />
              </v-list-item-action>
              <v-list-item-title>{{ $t('darkBackground') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>


      <top-image-header v-bind:image-src="supplier.backgroundId != null ? backgroundUrl : '/images/headertemp.jpg'"
                        v-bind:gradient="supplier.darkBackground && supplier.backgroundId == null ? 'to left, rgba(17, 62, 124, 0.95), rgba(17, 62, 124, 0.9)' : 'to left, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)'">
        <v-btn v-bind:dark="supplier.darkBackground"
              exact
              slot="left-button"
              text>
          <v-icon left>mdi-chevron-left</v-icon>
          {{ $t('back') }}
        </v-btn>
        <v-btn v-bind:dark="!supplier.darkBackground"
               v-bind:color="supplier.darkBackground ? 'white' : 'primary'"
               exact
               slot="right-button">
          {{ $t('confirmOrder') }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
        <div v-if="supplier.logoId != null">
          <img class="logo"
               v-bind:src="logoUrl">
        </div>
        <h1 v-else v-bind:class="[ 'display-1',  'mx-4',  supplier.darkBackground ? 'white--text' : 'primary--text' ]">{{ supplier.supplierGroup | defaultValue($t('supplier')) }}</h1>
      </top-image-header>

      <v-card-text class="pa-2">
        <v-textarea flat
                    hide-details="auto"
                    rows="6"
                    solo
                    v-bind:label="$t('pleaseWriteAShortIntroduction')"
                    v-model="supplier.description" />

        <product-sheet-card ref="productSheetCard"
                            v-bind:loading="false"
                            v-bind:products="sampleProducts"
                            v-bind:supplier="supplier" />
      </v-card-text>
    </v-card>

    <input accept="image/png, image/jpeg"
           class="hidden"
           ref="backgroundPicker"
           type="file"
           v-on:change="setBackground">
    <input accept="image/png, image/jpeg"
           class="hidden"
           ref="logoPicker"
           type="file"
           v-on:change="setLogo">
  </div>
</template>

<script>
  import settings from '../../settings'

  import TopImageHeader from '@/components/common/TopImageHeader'
  import ProductSheetCard from '@/components/ProductSheetCard'

  export default {
    components: {
      TopImageHeader,
      ProductSheetCard
    },
    computed: {
      hasBackground() {
        return this.supplier.backgroundId != null
      },
      hasLogo() {
        return this.supplier.logoId != null
      },
      backgroundUrl() {
        return `${settings.apiUri}/attachment/${this.supplier.backgroundId}/content`
      },
      logoUrl() {
        return `${settings.apiUri}/attachment/${this.supplier.logoId}/content`
      },
      sampleProducts() {
        return [
          {
            id: "123456",
            title: "Bierkasten 20x0,33",
            packaging: "Kasten",
            packagingSize: "20 Flaschen",
            discounts: [ { deliveryPeriod: 0, productId: "123456", value: "-1,50 €" }, { deliveryPeriod: 1, productId: "123456", value: "-1,50 €" } ],
            orders: [],
            newText1: "Beispiel-Artikel – dient rein zur Illustration und wird mit Ihren Artikeln ersetzt!",
            price: 9.99,
            quantity: [ 0, 0 ],
            orderQuantity: [ 0, 0 ]
          },
          {
            id: "123457",
            title: "Bierfass 10 l",
            packaging: "Fass",
            packagingSize: "10 l",
            discounts: [ { deliveryPeriod: 0, productId: "123457", value: "-1,50 €" }, { deliveryPeriod: 1, productId: "123457", value: "-1,50 €" } ],
            orders: [],
            newText1: "Beispiel-Artikel – dient rein zur Illustration und wird mit Ihren Artikeln ersetzt!",
            price: 9.99,
            quantity: [ 0, 0 ],
            orderQuantity: [ 0, 0 ]
          }
        ]
      }
    },
    data: () => ({
      //darkBackground: true,
      //backgroundId: null,
      //logoId: null
    }),
    methods: {
      chooseBackground() {
        this.$refs.backgroundPicker.click()
      },
      chooseLogo() {
        this.$refs.logoPicker.click()
      },
      async setBackground(e) {
        this.supplier.backgroundId = await this.uploadAttachment(e)
      },
      async setLogo(e) {
        this.supplier.logoId = await this.uploadAttachment(e)
      },
      removeBackground() {
        this.supplier.backgroundId = null
      },
      removeLogo() {
        this.supplier.logoId = null
      },
      async uploadAttachment(e) {
        const file = e.target.files[0]

        if (file == null) {
          return
        }

        const data = new FormData()
        data.append('file', file)

        const upload = await this.$store.dispatch('createAttachment', { data })

        return upload.id
      }
    },
    name: 'DesignCard',
    props: {
      form: Object,
      supplier: Object
    }
  }
</script>

<style scoped>
  .hidden {
    display: none;
  }

  .logo {
    max-height: 120px;
  }
</style>

<i18n>
  {
    "de": {
      "aboutUs": "Über uns",
      "addBackground": "Hintergrundbild hinzufügen…",
      "addLogo": "Logo hinzufügen…",
      "darkBackground": "Dunkler Hintergrund",
      "edit": "Bearbeiten",
      "pleaseWriteAShortIntroduction": "Bitte schreiben Sie eine kurze Einleitung",
      "removeBackground": "Hintergrundbild entfernen",
      "removeLogo": "Logo entfernen",
      "back": "Zurück",
      "confirmOrder": "Bestellung aufgeben",
      "supplier": "Lieferant"
    },
    "en": {
      "aboutUs": "About us",
      "addBackground": "Add background…",
      "addLogo": "Add logo…",
      "darkBackground": "Dark background",
      "edit": "Edit",
      "pleaseWriteAShortIntroduction": "Please write a short introduction",
      "removeBackground": "Remove background",
      "removeLogo": "Remove logo",
      "back": "Back",
      "confirmOrder": "Confirm order",
      "supplier": "Supplier"
    }
  }
</i18n>