<template>
  <v-list nav
          dark>
    <v-list-item class="accent"
                 exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('back') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 v-bind:to="{ name: 'administration-dashboard' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dashboard') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--<v-list-item exact
                 v-bind:to="{ name: 'administration-company' }">
      <v-list-item-icon>
        <v-icon>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('company') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->

    <v-list-item v-bind:to="{ name: 'administration-suppliers' }">
      <v-list-item-icon>
        <v-icon>mdi-truck</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('suppliers') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-customers' }">
      <v-list-item-icon>
        <v-icon>mdi-dolly</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('customers') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-order-analytics' }">
      <v-list-item-icon>
        <v-icon>mdi-chart-line</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('orderAnalytics') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-attendance' }">
      <v-list-item-icon>
        <v-icon>mdi-ticket-confirmation</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('attendance') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration-import' }">
      <v-list-item-icon>
        <v-icon>mdi-database</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('import') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>
  export default {
    name: 'AdministrationNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "company": "Unternehmen",
      "customers": "Kunden",
      "dashboard": "Dashboard",
      "orderAnalytics": "Bestellanalyse",
      "suppliers": "Lieferanten",
      "attendance": "Einlass",
      "import": "Datenimport"
    },
    "en": {
      "back": "Back",
      "company": "Company",
      "customers": "Customers",
      "dashboard": "Dashboard",
      "orderAnalytics": "Order analytics",
      "suppliers": "Suppliers",
      "attendance": "Attendance",
      "import": "Import"
    }
  }
</i18n>