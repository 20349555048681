<template>
  <div v-if="theSupplier != null">
    <confirm-dialog ref="confirmOrderDialog"
                    v-bind:cancelButtonLabel="$t('cancel')"
                    v-bind:confirmButtonLabel="$t('confirmOrder')"
                    v-bind:message="$t('areYouSureYouWantToPlaceTheOrderYouCanEditOrCancelThisOrderUntilTheEndOfTheSalesFair')"
                    v-bind:title="$t('confirmOrder')"
                    v-model="confirmOrderDialog" />

    <confirm-dialog ref="discardOrderDialog"
                    v-bind:cancelButtonLabel="$t('cancel')"
                    v-bind:confirmButtonLabel="$t('discardOrder')"
                    v-bind:message="$t('ifYouLeaveThisPageThisOrderWillNotBeSavedAreYouSureYouWantToDiscardYourChanges')"
                    v-bind:title="$t('discardOrder')"
                    v-model="discardOrderDialog" />

    <top-image-header v-bind:image-src="theSupplier.backgroundId != null ? backgroundUrl : '/images/headertemp.jpg'"
                      v-bind:gradient="theSupplier.darkBackground && theSupplier.backgroundId == null ? 'to left, rgba(17, 62, 124, 0.95), rgba(17, 62, 124, 0.9)' : 'to left, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)'">
      <v-btn v-bind:dark="theSupplier.darkBackground"
             exact
             slot="left-button"
             text
             v-bind:to="{ name: 'supplier-orders', params: { invitationCode} }">
        <v-icon left>mdi-chevron-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <v-btn v-bind:dark="!theSupplier.darkBackground"
             v-bind:color="theSupplier.darkBackground ? 'white' : 'primary'"
             v-if="!isMobile"
             exact
             slot="right-button"
             v-on:click="confirmOrder">
        {{ $t('confirmOrder') }}
        <v-icon right>mdi-check</v-icon>
      </v-btn>
      <div v-if="theSupplier.logoId != null">
        <img class="logo"
               v-bind:src="logoUrl">
      </div>
      <h1 v-else v-bind:class="[ 'display-1',  'mx-4', theSupplier.darkBackground ? 'white--text' : 'primary--text' ]">{{ theSupplier.supplierGroup | defaultValue($t('supplier')) }}</h1>
    </top-image-header>

    <v-alert tile
             type="success" icon="mdi-account">
      <strong>{{ $t('customer') }}</strong>: {{ customer.companyName }} ({{ customer.id }}) – {{ customer.givenName }} {{ customer.familyName }}<br>{{ customer.streetAddress }}, {{ customer.postalCode }} {{  customer.city }}
    </v-alert>

    <v-alert tile
             type="error"
             v-model="validationAlert">
      {{ $t('yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue') }}
    </v-alert>

    <v-alert tile
             type="warning"
             v-bind:value="alreadyPlaced && !validationAlert">
      {{ $t('youHaveAlreadyPlacedAnOrderWithThisSupplierForThisWeekNumberIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten') }}
    </v-alert>

    <v-container class="pa-1">
      <v-row v-if="description != null && description.length > 0">
        <v-col>
          <p class="body-1 mx-4" v-html="description"></p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <v-btn block
                  color="accent"
                  depressed
                  v-bind:disabled="!hasChanges"
                  v-on:click="discardOrder">
            <v-icon left>
              mdi-refresh
            </v-icon>
            {{ $t('discardChanges') }}
          </v-btn>
        </v-col>
      </v-row>
      <product-sheet-card ref="productSheetCard"
                          v-bind:loading="loading"
                          v-bind:products="filteredProducts"
                          v-bind:supplier="theSupplier" />
      <p class="mt-4 mx-4 grey--text text--darken-2 text-body-2">* {{ $t('allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted') }}</p>
    </v-container>
    <v-btn
      class="mb-16"
      fab
      dark
      fixed bottom right
      color="accent"
      v-on:click="confirmOrder"
      v-if="isMobile"
    >
      <v-icon dark>
        mdi-check
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import settings from '../settings'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import TopImageHeader from '@/components/common/TopImageHeader'
  import ProductSheetCard from '@/components/ProductSheetCard'

  export default {
    async beforeRouteLeave (to, from, next) {
      if (this.canLeave || !this.hasChanges) {
        next()

        return
      }

      await this.$refs.discardOrderDialog.open(next)
    },
    components: {
      ConfirmDialog,
      ProductSheetCard,
      TopImageHeader
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown
      },
      description() {
        if (this.theSupplier == null) {
          return ''
        }

        if (this.theSupplier.description == null) {
          return ''
        }

        return this.theSupplier.description.replace(/(?:\r\n|\r|\n)/g, '<br />')
      },
      backgroundUrl() {
        return `${settings.apiUri}/attachment/${this.theSupplier.backgroundId}/content`
      },
      logoUrl() {
        return `${settings.apiUri}/attachment/${this.theSupplier.logoId}/content`
      },
      alreadyPlaced() {
        // TODO: This should be improved in furhter version since this way it is fixed to two delivery periods

        return this.theSupplier.hasDeliveryPeriod0Orders || this.theSupplier.hasDeliveryPeriod1Orders
      },
      hasChanges() {
        return this.products.some(p => {
          const order = p.orders.find(o => o.deliveryPeriod === 0)
          const order2 = p.orders.find(o => o.deliveryPeriod === 1)
          let hasChanges = false

          if (order != null && p.orderQuantity[0] != order.quantity) {
            hasChanges == true
          }

          if (order != null && p.orderQuantity[1] != order2.quantity) {
            hasChanges == true
          }

          if (p.orderQuantity[0] != p.quantity[0]) {
            hasChanges = true
          }

          if (p.orderQuantity[1] != p.quantity[1]) {
            hasChanges = true
          }

          return hasChanges
        })
      },
      filteredProducts() {
        return this.products
      },
      theSupplier() {
        return this.supplier(this.id)
      },
      ...mapGetters([
        'currentCustomer',
        'priceForProductAndCustomer',
        'productsForSupplier',
        'supplier',
        'supplierByInvitationCode'
      ])
    },
    async created() {
      this.invitationCode = this.$route.params.invitationCode

      if (this.invitationCode == null || this.invitationCode.length == 0) {
        console.log('ERROR: No invitation code provided')

        return
      }

      this.shortCode = this.$route.params.shortCode

      if (this.shortCode == null || this.shortCode.length == 0) {
        console.log('ERROR: No short code provided')

        return
      }

      this.get(this.invitationCode)
    },
    data: () => ({
      customer: null,
      canLeave: false,
      confirmOrderDialog: false,
      discardOrderDialog: false,
      id: null,
      loading: false,
      orderDialog: false,
      products: [],
      validationAlert: false,
      shortCode: null,
      invitationCode: null
    }),
    methods: {
      priceForProduct(id) {
        return this.priceForProductAndCustomer(id, this.customer.id)
      },
      async discardOrder() {
        await this.get(this.id)
      },
      async confirmOrder() {
        if (!this.$refs.productSheetCard.validate()) {
          this.validationAlert = true
          this.$vuetify.goTo(0)

          return
        }

        await this.$refs.confirmOrderDialog.open(async r => {
          if (!r) {
            return
          }

          const orders = this.products.map(p => ({
            deliveryPeriod: 0,
            productId: p.id,
            quantity: p.orderQuantity[0]
          }))

          const orders2 = this.products.map(p => ({
            deliveryPeriod: 1,
            productId: p.id,
            quantity: p.orderQuantity[1]
          }))

          await this.createOrdersForShortCode({ orders: [ ...orders, ...orders2 ], invitationCode: this.invitationCode, shortCode: this.shortCode })

          this.canLeave = true
          this.$router.push({ name: 'supplier-orders', params: { invitationCode: this.invitationCode} })
        })
      },
      async get(invitationCode) {
        this.loading = true

        this.customer = await this.$store.dispatch('loadCustomerByShortCode', { shortCode: this.shortCode })

        if (this.customer == null) {
          console.log('ERROR: Customer not found')
          this.$router.push({ name: 'supplier-orders', params: { invitationCode: this.invitationCode} })
          return
        }

        await this.$store.dispatch('loadSupplierByInvitationCode', { invitationCode })

        const supplier = this.supplierByInvitationCode(invitationCode)

        if (supplier == null) {
          console.log('ERROR: Supplier not found')
          return
        }

        this.id = supplier.id





        if (this.products.length == 0) {
          //await this.$store.dispatch('loadSuppliersForMe')
          await this.$store.dispatch('loadPricesForShortCode', { shortCode: this.shortCode, invitationCode: this.invitationCode })
          await this.$store.dispatch('loadProductsForShortCodeForSupplier', { shortCode: this.shortCode, supplierId: this.id, invitationCode: this.invitationCode })
        }

        this.products = []

        this.products = [...this.productsForSupplier(this.id)].map(p => {
          let orderQuantity = p.quantity
          let otherOrderQuantity = p.quantity

          const order = p.orders.find(o => o.deliveryPeriod === 0)
          const otherOrder = p.orders.find(o => o.deliveryPeriod === 1)

          if (order != null) {
            orderQuantity = order.quantity
          }

          if (otherOrder != null) {
            otherOrderQuantity = otherOrder.quantity
          }

          return {
            ...p,
            price: this.priceForProduct(p.id),
            quantity: [ p.quantity, p.quantity ],
            orderQuantity: [ orderQuantity, otherOrderQuantity ]
          }
        })

        this.loading = false
      },
      ...mapActions([ 'createOrdersForShortCode' ])
    },
    name: 'InternalSupplierPage'
  }
</script>

<style scoped>
  .logo {
    max-height: 120px;
    max-width: 100%;
  }
</style>

<i18n>
  {
    "de": {
      "youHaveMadeChangesToTheOrderAlreadyPleasePlaceTheOrderOrDiscardTheChangesToChangeTheDeliveryPeriod": "Sie haben Änderungen am Ordersatz vorgenommen. Bitte geben Sie ihre Bestellung auf oder verwerfen Sie die Änderungen, um den Lieferzeitraum zu wechseln.",
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "Alle Preise entsprechen der kundenindividuellen Preisliste ohne Konditionen. Irrtümer und Änderungen vorbehalten.",
      "areYouSureYouWantToPlaceTheOrderYouCanEditOrCancelThisOrderUntilTheEndOfTheSalesFair": "Sind Sie sicher, dass Sie die Bestellung verbindlich aufgeben möchten? Bis zum Ende der Messe können Sie diese Bestellung weiterhin bearbeiten oder stornieren.",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "confirmOrder": "Bestellung aufgeben",
      "copyFromOtherPeriod": "Von anderem Zeitraum übernehmen",
      "discardOrder": "Bestellung verwerfen",
      "discardChanges": "Änderungen verwerfen",
      "ifYouLeaveThisPageThisOrderWillNotBeSavedAreYouSureYouWantToDiscardYourChanges": "Wenn Sie diese Seite verlassen, wird diese Bestellung nicht gespeichert. Sind Sie sicher, dass Sie Ihre Änderungen verwerfen möchten?",
      "supplier": "Lieferant",
      "youHaveAlreadyPlacedAnOrderWithThisSupplierForThisWeekNumberIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten": "Sie haben bereits eine Bestellung bei diesem Lieferanten für diese Kalenderwoche aufgeben. Wenn Sie die Bestellung erneut aufgeben, wird Ihre bestehende Bestellung überschrieben.",
      "yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue": "Ihre Bestellung enthält fehlerhafte Angaben. Bitte prüfen und korrigieren Sie die rot markierten Felder um fortzufahren.",
      "print": "Druckansicht",
      "customer": "Kunde"
    },
    "en": {
      "youHaveMadeChangesToTheOrderAlreadyPleasePlaceTheOrderOrDiscardTheChangesToChangeTheDeliveryPeriod": "You have made changes to the order already. Please place the order or discard the changes to change the delivery period.",
      "allPricesCorrespondToTheRespectiveCustomerSpecificPriceListErrorsAndOmissionsExcepted": "All prices correspond to the customer-specific price list. Errors and omissions excepted.",
      "areYouSureYouWantToPlaceTheOrderYouCanEditOrCancelThisOrderUntilTheEndOfTheSalesFair": "Are you sure you want to place the order? You can edit or cancel this order until the end of the sales fair.",
      "back": "Back",
      "cancel": "Cancel",
      "confirmOrder": "Confirm order",
      "copyFromOtherPeriod": "Copy from other period",
      "discardOrder": "Discard order",
      "discardChanges": "Discard changes",
      "ifYouLeaveThisPageThisOrderWillNotBeSavedAreYouSureYouWantToDiscardYourChanges": "If you leave this page, this order will not be saved. Are you sure you want to discard your changes?",
      "supplier": "Supplier",
      "youHaveAlreadyPlacedAnOrderWithThisSupplierForThisWeekNumberIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten": "You have already placed an order with this supplier for this week number. If you place the order again, your existing order will be overwritten.",
      "yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue": "Your form contains errors. Please check and correct the marked fields in order to continue.",
      "print": "Print",
      "customer": "Customer"
    }
  }
</i18n>