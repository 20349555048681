<template>
  <div>
    <v-card outlined>
      <v-alert tile
               type="error"
               v-bind:value="validationAlert">
        {{ $t('pleaseCompleteTheForm') }}
      </v-alert>
      <v-card-title class="primary--text">{{ customerName }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mb-4">
            <v-col>
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('companyName')"
                            v-model="customer.companyName" />
            </v-col>
          </v-row>

          <h2 class="subtitle-1">{{ $t('contact') }}</h2>

          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('givenName')"
                            v-bind:rules="[ rules.required ]"
                            v-model="customer.givenName" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('familyName')"
                            v-bind:rules="[ rules.required ]"
                            v-model="customer.familyName" />
            </v-col>
          </v-row>

          <h2 class="subtitle-1">{{ $t('address') }}</h2>

          <v-row class="mb-1">
            <v-col>
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('streetAddress')"
                            v-bind:rules="[ rules.required ]"
                            v-model="customer.streetAddress" />
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('postalCode')"
                            v-bind:rules="[ rules.required ]"
                            v-model="customer.postalCode" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('city')"
                            v-bind:rules="[ rules.required ]"
                            v-model="customer.city" />
            </v-col>
          </v-row>
          <h2 class="mb-2 subtitle-1">{{ $t('contactDetails') }}</h2>

          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="customer.email" />
        </v-form>


      </v-card-text>
      <v-card-actions class="pa-4"
                      v-if="nextStepButton != null || previousStepButton != null">
        <v-btn color="accent"
               outlined
               v-if="previousStepButton != null"
               v-on:click="previousStep">
          {{ previousStepButton }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               depressed
               v-if="nextStepButton != null"
               v-on:click="nextStep">
          {{ nextStepButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      customerName() {
        if (this.customer.companyName != null && this.customer.companyName.length !== 0) {
          return this.customer.companyName
        }

        if (this.customer.givenName != null && this.customer.givenName.length !== 0 && this.customer.familyName != null && this.customer.familyName.length !== 0) {
          return `${this.customer.givenName} ${this.customer.familyName}`
        }

        return this.$t('unknown')
      },
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      nextStep() {
        this.$emit('next-step')
      },
      previousStep() {
        this.$emit('previous-step')
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CustomerPersonalDetailsCard',
    props: {
      customer: Object,
      nextStepButton: String,
      previousStepButton: String,
      validationAlert: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "address": "Adresse",
      "city": "Stadt",
      "contact": "Ansprechpartner",
      "contactDetails": "Kontakt",
      "companyName": "Firmenname",
      "customer": "Kunde",
      "email": "E-Mail-Adresse",
      "givenName": "Vorname",
      "familyName": "Nachname",
      "pleaseCompleteTheForm": "Bitte vervollständigen Sie Ihre Angaben.",
      "postalCode": "Postleitzahl",
      "streetAddress": "Straße und Hausnummer",
      "unknown": "Unbekannt"
    },
    "en": {
      "address": "Address",
      "city": "City",
      "contact": "Contact",
      "contactDetails": "Contact details",
      "companyName": "Company name",
      "customer": "Customer",
      "email": "Email",
      "givenName": "Given name",
      "familyName": "Family name",
      "pleaseCompleteTheForm": "Please complete the form.",
      "postalCode": "Postal code",
      "streetAddress": "Street address",
      "unknown": "Unknown"
    }
  }
</i18n>