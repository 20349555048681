<template>
  <div v-if="supplier != null">
    <top-header>
      <img class="logo" src="/images/logo.png">
    </top-header>
    <v-container class="container pa-0">
      <supplier-registration-stepper v-bind:supplier="supplier"
                                     v-bind:event="event"
                                     v-on:send="updateSupplier" />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'
  import SupplierRegistrationStepper from '@/components/registration/SupplierRegistrationStepper'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationCode = to.params.invitationCode

      await this.get(invitationCode)

      next()
    },
    components: {
      SupplierRegistrationStepper,
      TopHeader
    },
    computed: {
      ...mapGetters({
        event: 'event'
      })
    },
    async created() {
      const invitationCode = this.$route.params.invitationCode

      await this.get(invitationCode)
    },
    data: () => ({
      invitationCode: null,
      supplier: null
    }),
    methods: {
      async get(invitationCode) {
        this.invitationCode = invitationCode

        await this.$store.dispatch('loadSupplierByInvitationCode', { invitationCode })

        const supplier = this.$store.getters.supplierByInvitationCode(invitationCode)

        if (supplier == null || supplier.state === 'accepted' || supplier.state === 'declined') {
          this.$router.push({ name: 'home' })
        }

        this.supplier = Object.assign({}, supplier)
      },
      async updateSupplier({ supplier, form }) {
        supplier.state = form.attendance ? 'accepted' : 'declined'

        await this.$store.dispatch('updateSupplier', { supplier })

        /*if (form.attendance) {
          await this.$store.dispatch('sendSupplierMarketingInformation', { id: supplier.id })
        }*/
      }
    },
    name: 'SupplierRegistration'
  }
</script>

<style scoped>
  .container {
    max-width: 720px;
  }

  .logo {
    display: block;
    margin: 0 0 0 auto;
    max-height: 80px;
  }
</style>