<template>
  <div>
    <top-header v-bind:title="$t('evaluation')" />
    <v-container>
      <v-card outlined>
        <v-card-title>
          Ihre Meinung ist gefragt!
        </v-card-title>
        <v-card-text>
          <p>Vielen Dank, dass Sie sich die Zeit nehmen, bei unserer Zufriedenheits-Umfrage teilzunehmen. Im Folgenden haben wir einige kurze Fragen zusammengestellt, mit denen Sie verschiedene Aspekte unserer ersten „hybriden“ Hausmesse bewerten können.</p>
          <p>Die Fragen orientieren sich hierbei an der klassischen Sternebewertung – fünf Sterne drücken hierbei eine besonders hohe, ein Stern eine besonders niedrige Wertung aus. </p>
          <p>Am Ende haben Sie noch die Möglichkeit ein persönliches Feedback oder Verbesserungsvorschläge zu geben – gerade bei der Vergabe von Ein- oder Zwei-Stern-Bewertungen würden wir uns über detailliertere Hinweise freuen, damit wir die nächste Hausmesse noch besser vorbereiten können.</p>
          <h3>Benachrichtigungen</h3>
          <p>Waren die E-Mail-Benachrichtigungen vor, während und nach der Messe für Sie verständlich und in Ihren Augen sinnvoll?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.notificationsStars1" />

          <p>Haben wir die E-Mails an die richtigen Ansprechpartner in Ihrem Hause gesendet? Bitte ggf. zusätzliche E-Mail-Adressen im Freitextfeld am Ende ergänzen.</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.notificationsStars2" />

          <h3>Web-App</h3>
          <p>Gefällt Ihnen die Bestell-App? (Oberfläche, Benutzerführung, Ladezeiten etc.)</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.appGeneralStars" />

          <p>Waren die Bestellung- bzw. Änderung während der Messe reibungslos möglich?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.appOnSiteStars" />

          <p>Waren die Bestellmengen für Sie nachvollziehbar und die angebotenen Möglichkeiten zum Druck bzw. Export für Sie ausreichend?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.appExportStars" />

          <h3>Hausmesse</h3>
          <p>Waren Sie mit der Anreise, den Parkmöglichkeiten und dem Registrierungsprozess am Empfang zufrieden?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.onSiteOnboardingStars" />

          <p>Haben Ihnen der Messeablauf, die verteilten Informationen und die Unterstützung durch Logistik, IT und das Empfangsteam gefallen?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.onSiteExperienceStars" />

          <p>Wie beurteilen Sie die SAGASSER Hausmesse 2025?</p>
          <v-rating class="mb-10"
                    color="accent"
                    v-model="evaluation.onSiteGeneralStars" />

          <v-textarea label="Feedback"
                      hint="Möchten Sie uns noch ein individuelles Feedback zu Ihren Erfahrungen geben?"
                      v-model="evaluation.feedback" outlined />

          <v-textarea class="mb-10"
                      label="E-Mail-Adressen"
                      hint="Haben Sie abweichende Ansprechpartner/E-Mail-Adressen, welche wir für künfigte Benachrichtigungen berücksichtigen sollen?"
                      v-model="evaluation.email" outlined />
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn color="accent"
                 v-on:click="save">
            Absenden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      TopHeader
    },
    async created() {
      if (this.$route.params.invitationCode != null) {
        await this.signInCustomer(this.$route.params.invitationCode)
        await this.$store.dispatch('loadEvaluation')

        if (this.$store.getters.evaluation != null && this.$store.getters.evaluation.customerId != null) {
          this.evaluation = this.$store.getters.evaluation
        }
      }
    },
    data: () => ({
      evaluation: {
        customerId: null
      },
      customer: null
    }),
    methods: {
      async save() {
        await this.$store.dispatch('createOrUpdateEvaluation', { evaluation: this.evaluation })

        this.$router.push({ name: 'home' })
      },
      async signInCustomer(invitationCode) {
        try {
          await this.$store.dispatch('loadCustomerByInvitationCode', { invitationCode: invitationCode })
        } catch {
          this.$router.push({ name: 'home' })
          return
        }

        const customer = this.$store.getters.customerByInvitationCode(invitationCode)

        if (customer == null) {
          this.$router.push({ name: 'home' })

          return
        }

        await this.$store.dispatch('setCurrentCustomer', { customer })

        this.evaluation.customerId = customer.id
      }
    },
    name: 'Evaluation'
  }
</script>

<i18n>
  {
    "de": {
      "evaluation": "Zufriedenheits-Umfrage"
    },
    "en": {
      "evaluation": "Evaluation"
    }
  }
</i18n>