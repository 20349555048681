<template>
  <div>
    <v-card outlined>
      <v-alert tile
               type="error"
               v-bind:value="validationAlert">
        {{ $t('pleaseCompleteTheForm') }}
      </v-alert>
      <v-card-title class="primary--text">{{ supplierName }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mb-4">
            <v-col>
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('companyName')"
                            v-model="supplier.companyName" />
            </v-col>
          </v-row>

          <h2 class="subtitle-1">{{ $t('contact') }}</h2>

          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('givenName')"
                            v-bind:rules="[ rules.required ]"
                            v-model="supplier.givenName" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('familyName')"
                            v-bind:rules="[ rules.required ]"
                            v-model="supplier.familyName" />
            </v-col>
          </v-row>

          <h2 class="subtitle-1">{{ $t('address') }}</h2>

          <v-row class="mb-1">
            <v-col>
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('streetAddress')"
                            v-bind:rules="[ rules.required ]"
                            v-model="supplier.streetAddress" />
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('postalCode')"
                            v-bind:rules="[ rules.required ]"
                            v-model="supplier.postalCode" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field hide-details="auto"
                            outlined
                            v-bind:label="$t('city')"
                            v-bind:rules="[ rules.required ]"
                            v-model="supplier.city" />
            </v-col>
          </v-row>
          <h2 class="mb-2 subtitle-1">{{ $t('contactDetails') }}</h2>

          <v-text-field class="mb-8"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.email" />

          <h2 class="mb-2 subtitle-1">{{ $t('registrationDetails') }}</h2>

          <v-text-field class="mb-6"
                        hide-details="auto"
                        type="number"
                        outlined
                        v-bind:label="$t('countBoothStaff')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.countBoothStaff" />

          <v-text-field class="mb-6"
                        hide-details="auto"
                        type="number"
                        outlined
                        v-bind:label="$t('countTables')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.countTables" />

          <p class="mb-6">{{ $t('pleaseNoteThatAllEquipmentForTheDecorationOfTheBoothOrTheTablesMustBeBroughtByTheSupplier') }}</p>

          <v-text-field class="mb-6"
                        hide-details="auto"
                        type="number"
                        outlined
                        v-bind:label="$t('countRegularOutlets')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.countRegularOutlets" />

          <v-text-field class="mb-6"
                        hide-details="auto"
                        type="number"
                        outlined
                        v-bind:label="$t('countSpecialOutlets')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.countSpecialOutlets" />

          <v-text-field class="mb-6"
                        hide-details="auto"
                        type="number"
                        outlined
                        v-bind:label="$t('countDinnerAttendees')"
                        v-bind:rules="[ rules.required ]"
                        v-model="supplier.countDinnerAttendees" />

          <v-textarea class="mb-6"
                      hide-details="auto"
                      outlined
                      v-bind:label="$t('comments')"
                      v-model="supplier.comments" />
        </v-form>


      </v-card-text>
      <v-card-actions class="pa-4"
                      v-if="nextStepButton != null || previousStepButton != null">
        <v-btn color="accent"
               outlined
               v-if="previousStepButton != null"
               v-on:click="previousStep">
          {{ previousStepButton }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               depressed
               v-if="nextStepButton != null"
               v-on:click="nextStep">
          {{ nextStepButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      supplierName() {
        if (this.supplier.companyName != null && this.supplier.companyName.length !== 0) {
          return this.supplier.companyName
        }

        if (this.supplier.givenName != null && this.supplier.givenName.length !== 0 && this.supplier.familyName != null && this.supplier.familyName.length !== 0) {
          return `${this.supplier.givenName} ${this.supplier.familyName}`
        }

        return this.$t('unknown')
      },
      rules() {
        return {
          required: v => !!v || this.$t('form.required')
        }
      }
    },
    methods: {
      nextStep() {
        this.$emit('next-step')
      },
      previousStep() {
        this.$emit('previous-step')
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'SupplierPersonalDetailsCard',
    props: {
      supplier: Object,
      nextStepButton: String,
      previousStepButton: String,
      validationAlert: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "address": "Adresse",
      "city": "Stadt",
      "contact": "Ansprechpartner",
      "contactDetails": "Kontakt",
      "companyName": "Firmenname",
      "email": "E-Mail-Adresse",
      "givenName": "Vorname",
      "familyName": "Nachname",
      "pleaseCompleteTheForm": "Bitte vervollständigen Sie Ihre Angaben.",
      "postalCode": "Postleitzahl",
      "supplier": "Lieferant",
      "streetAddress": "Adresse",
      "unknown": "Unbekannt",
      "registrationDetails": "Anmeldeinformationen",
      "countBoothStaff": "Anzahl der Standmitarbeiter",
      "countTables": "Menge Biertischgarnituren",
      "countRegularOutlets": "Menge an Steckdosen Haushaltsstrom",
      "countSpecialOutlets": "Menge an Steckdosen Starkstrom",
      "countDinnerAttendees": "Teilnahme am gemeinsamen Abendessen/Personenanzahl",
      "comments": "Weitere Anmerkungen",
      "pleaseNoteThatAllEquipmentForTheDecorationOfTheBoothOrTheTablesMustBeBroughtByTheSupplier": "Bitte beachten Sie, dass sämtliches Equipment für die Dekoration des Messestandes bzw. der Biertische vom Aussteller selbst mitgebracht werden muss."
    },
    "en": {
      "address": "Address",
      "city": "City",
      "contact": "Contact",
      "contactDetails": "Contact details",
      "companyName": "Company name",
      "email": "Email",
      "givenName": "Given name",
      "familyName": "Family name",
      "pleaseCompleteTheForm": "Please complete the form.",
      "postalCode": "Postal code",
      "supplier": "Supplier",
      "streetAddress": "Street address",
      "unknown": "Unknown",
      "registrationDetails": "Registration details",
      "countBoothStaff": "Count booth staff",
      "countTables": "Count tables",
      "countRegularOutlets": "Count regular outlets",
      "countSpecialOutlets": "Count special outlets",
      "countDinnerAttendees": "Count dinner attendees",
      "comments": "Comments",
      "pleaseNoteThatAllEquipmentForTheDecorationOfTheBoothOrTheTablesMustBeBroughtByTheSupplier": "Please note that all equipment for the decoration of the booth or the tables must be brought by the supplier."
    }
  }
</i18n>