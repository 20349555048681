var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('customers')))]),_c('v-text-field',{staticClass:"mx-4",attrs:{"hide-details":"","flat":"","light":"","prepend-inner-icon":"mdi-magnify","rounded":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"custom-filter":_vm.filter,"headers":_vm.headers,"items":_vm.customers,"items-per-page":15,"no-data-text":_vm.$t('thereAreCurrentlyNoOrders'),"no-results-text":_vm.$t('noOrdersWereFound'),"search":_vm.search,"sort-by":['companyName']},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","icon":"","small":"","to":{ name: 'administration-customer', params: { id: item.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.orderCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderCount)+"/"+_vm._s(item.maxOrder)+" "),_c('strong',[_vm._v("("+_vm._s(_vm.percent(item.orderCount, item.maxOrder))+")")])]}},{key:"item.orderCount2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderCount2)+"/"+_vm._s(item.maxOrder)+" "),_c('strong',[_vm._v("("+_vm._s(_vm.percent(item.orderCount2, item.maxOrder))+")")])]}},{key:"item.newOrderCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newOrderCount)+"/"+_vm._s(item.newMaxOrder)+" "),_c('strong',[_vm._v("("+_vm._s(_vm.percent(item.newOrderCount, item.newMaxOrder))+")")])]}},{key:"item.newOrderCount2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newOrderCount2)+"/"+_vm._s(item.newMaxOrder)+" "),_c('strong',[_vm._v("("+_vm._s(_vm.percent(item.newOrderCount2, item.newMaxOrder))+")")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }