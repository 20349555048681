<template>
  <div>
    <top-image-header image-src="/images/header8.jpg">
      <h1 class="display-1 mx-4 white--text">{{ $t('newProducts') }}</h1>
      <v-text-field class="mx-4 mt-4"
                    hide-details
                    flat
                    light
                    prepend-inner-icon="mdi-magnify"
                    rounded
                    solo
                    v-model="search" />
    </top-image-header>
    <v-progress-linear color="accent"
                         indeterminate
                         v-bind:active="loading" />
    <v-container class="container pa-1 pt-6">
      <template v-for="group in groups">
        <img class="logo mb-4 mx-3"
               v-bind:key="`${group.supplierId}-img`"
               v-bind:alt="group.supplier.supplierGroup"
               v-bind:src="logoUrl(group.supplier.logoId)"
               v-if="group.supplier.logoId != null">

        <h1 class="display-1 mb-4 primary--text mx-3" v-else
            v-bind:key="`${group.supplierId}-h1`">
          {{ group.supplier.supplierGroup }}
          <v-chip color="light-green" dark
                  v-if="group.supplier.hasDeliveryPeriod0Orders">
            {{ group.supplier.deliveryPeriod0Tag | defaultValue($t('period1')) }}
          </v-chip>
          <v-chip class="ml-1"
                  color="light-green" dark
                  v-if="group.supplier.hasDeliveryPeriod1Orders">
            {{ group.supplier.deliveryPeriod1Tag | defaultValue($t('period2')) }}
          </v-chip>
        </h1>

        <product-slider class="mb-12"
                        v-bind:key="`${group.supplierId}-product_slider`"
                        v-bind:products="group.products" />
      </template>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import settings from '../settings'

  import TopImageHeader from '@/components/common/TopImageHeader'
  import ProductSlider from '@/components/ProductSlider'

  export default {
    components: {
      TopImageHeader,
      ProductSlider
    },
    computed: {
      filteredProducts() {
        if (this.search != null && this.search.count !== 0) {
          const search = this.search.toLowerCase()

          return this.products.filter(p => p.title.toLowerCase().includes(search) || p.supplierCompanyName.toLowerCase().includes(search))
        }

        return this.products
      },
      groups() {
        return this.sortedProducts.reduce((result, current) => {
          const index = result.findIndex(p => p.supplierId === current.supplierId)

          if (index !== -1) {
            result[index].products.push({ ...current })
          } else {
            result.push({
              supplierId: current.supplierId,
              supplier: this.supplier(current.supplierId),
              products: [{ ...current }]
            })
          }

          return result
        }, []).sort((a, b) => a.supplier.supplierGroup.localeCompare(b.supplier.supplierGroup))
      },
      sortedProducts() {
        return this.filteredProducts.filter(p => p.new && (p.label === 'NEU' || p.label === 'UMSTELLUNG')).map(p => ({
          ...p,
          productPrice: this.formatPrice(9.99)
        }))
      },
      ...mapGetters([
        'customerId',
        'products',
        'supplier'
      ])
    },
    async created() {
      this.loading = true

      await this.$store.dispatch('loadSuppliersForMe')
      await this.$store.dispatch('loadProductsForMeNew')

      this.loading = false
    },
    data: () => ({
      loading: false,
      search: null
    }),
    methods: {
      logoUrl(id) {
        return `${settings.apiUri}/attachment/${id}/content`
      },
      formatPrice(price) {
        if (price == null || price <= 0) {
          return 'n. v.'
        }

        return price.toLocaleString(this.$i18n.locale, {
          style: 'currency',
          currency: 'EUR'
        })
      }
    },
    name: 'NewProducts'
  }
</script>

<style scoped>
  .logo {
    max-height: 160px;
    max-width: 220px;
    display: block;
  }
</style>

<i18n>
  {
    "de": {
      "newProducts": "Neuheiten",
      "period1": "Zeitraum 1",
      "period2": "Zeitraum 2"
    },
    "en": {
      "newProducts": "New products",
      "period1": "Period 1",
      "period2": "Period 2"
    }
  }
</i18n>