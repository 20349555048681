<template>
  <div>
    <navigation-list avatar
                     item-edit-route-name="administration-supplier"
                     v-bind:items="items" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import NavigationList from '@/components/common/NavigationList'

  export default {
    components: {
      NavigationList
    },
    computed: {
      ...mapGetters([ 'suppliers' ]),
      items() {
        return [...this.suppliers].map(s => ({
          ...s,
          code: this.getCode(s.companyName),
          title: s.companyName,
          subtitle: s.streetAddress == null ? '' : `${s.streetAddress}, ${s.postalCode} ${s.city}`
        }))
      }
    },
    methods: {
      getCode(companyName) {
        if (companyName == null) {
          return null
        }

        return companyName.match(/\b(\w)/g).join('').substr(0, 2)
      }
    },
    name: 'SupplierNavigation'
  }
</script>